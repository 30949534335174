import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningManager {
    uniqueUserId: string;
    email: string;
    name: string;
    phones: string[];
}
export interface IFeature {
    name: string;
    value: string;
}

export interface IReservation {
    startDate: string;
    endDate: string
}

export interface IRentalUnit {
    id: string;
    name: string;
    civicNumber?: string;
    street: string;
    city: string;
    province: string;
    manager?: ICleaningManager;
    features: IFeature[];
    notes: string;
    sourceName?: string;
    idInSource?: string;
    aggregateTimeStamp: string;
    isManagedExternally?: boolean;
}

export interface IDeactivateRentalUnitDTO {

    deactivationDate: string;

}


const rentalUnitAdapter = {

    getRentalUnitById: (apiClient: IApiClient, propertyMgmtId: string, rentalUnitId: string) => apiClient.get<IRentalUnit>('/RentalUnits/' + propertyMgmtId + '/' + rentalUnitId),

    updateRentalUnit: (apiClient: IApiClient, propertyMgmtId: string, rentalUnitId: string, updateRentalUnitDTO: IRentalUnit, expectConcurrencyError: boolean, expectedLogicErrorCodes: string[] | undefined) => apiClient.put('/RentalUnits/' + propertyMgmtId + '/' + rentalUnitId, updateRentalUnitDTO, expectConcurrencyError, expectedLogicErrorCodes),

    deactivateRentalUnit: (apiClient: IApiClient, propertyMgmtId: string, aggregateTimeStamp: string, rentalUnitId: string, updateRenatalUnit: IDeactivateRentalUnitDTO) => apiClient.post('/RentalUnits/' + propertyMgmtId + '/' + rentalUnitId + '/deactivate?aggregateTimeStamp=' + aggregateTimeStamp, updateRenatalUnit)


};

export default rentalUnitAdapter;
