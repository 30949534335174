import { useContext } from 'react';

import LocalUserProfileContext, { ILocalUserProfileContext } from '../LocalUserProfileContext';

export const createLocaleFormaterWithContext = (localUserProfileContext: ILocalUserProfileContext) => {

    const language = localUserProfileContext.language;

    return {
        formatDate: (value: Date, options?: Intl.DateTimeFormatOptions) => value.toLocaleString(language, options)
    };
};

const useLocaleFormater = () => {

    const localUserProfileContext = useContext(LocalUserProfileContext);

    return createLocaleFormaterWithContext(localUserProfileContext);
}

export default useLocaleFormater 