import { createContext } from 'react';

export interface ISafeHistoryContext {
    firstViewLocationKey?: string,
    firstViewIsChildView?: boolean,
    currentBlockedLocationKey?: string,
    currentUnblockCbk?: ()=> void
};

const SafeHistoryContext = createContext<ISafeHistoryContext>({});

export default SafeHistoryContext;