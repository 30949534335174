import IApiClient from '../../../utilities/IApiClient';

export interface ISpecialty {
  id: string;
  name: string;
  description: string;
}

const specialtyAdapter = {
  getSpecialty: (apiClient: IApiClient, propertyMgmtId: string, specialtyId: string) => apiClient.get<ISpecialty>('/Specialties/' + propertyMgmtId + '/' + specialtyId),
};

export default specialtyAdapter;
