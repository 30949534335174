import { ReactNode, useState, useMemo, useCallback, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router';

import IAppContext from '../IAppContext';
import AppLayoutContext, { IAppLayoutContext } from './AppLayoutContext';
import CustomAppBar from './CustomAppBar';
import CustomBottomNavigation from './CustomBottomNavigation';
import logManager from '../../utilities/logManager';
import CustomSwipeableDrawer from './CustomSwipeableDrawer';
import SafeHistoryContext, { ISafeHistoryContext } from '../../utilities/SafeHistoryContext';
import useTranslator from '../../utilities/hooks/useTranslator';
import { buildSafeHistoryFormContext } from '../../utilities/hooks/useSafeHistory';

//--- Tries to globally force the orientation of the device ---
// --- The orientation should also be set in the PWA manifest ---
if (!!window.screen && !!window.screen.orientation && !!window.screen.orientation.lock)
  window.screen.orientation.lock("portrait").catch(() => logManager.logInfo('Screen orientation could not be locked with API.'));
//-------------------------------------------------------------

interface IAppLayoutProps {
  appContext: IAppContext,
  appTitle: string,
  additionalUserActions: { iconName: string, label: string, action: () => void }[],
  bottomNavLinks: {
    label: string
    iconName: string,
    url: string
  }[],
  drawerNavLinks?: {
    label: string
    iconName: string,
    url: string
  }[],
  children?: ReactNode
}

interface ISafeHistoryContextStateData extends ISafeHistoryContext {
  firstViewLocationKeyIsSet: boolean
};

const SessionStorageSafeHistoryContextDataKey = 'SafeHistoryContextData';

const getSafeHistoryContextDataFromSession: () => { firstViewLocationKeyIsSet: boolean, firstViewLocationKey?: string, firstViewIsChildView?: boolean } | undefined = () => {

  let safeHisto = sessionStorage.getItem(SessionStorageSafeHistoryContextDataKey);

  if (safeHisto)
    return JSON.parse(safeHisto);

  return undefined;
};

const saveSafeHistoryContextDataInSession: (ctx: { firstViewLocationKeyIsSet: boolean, firstViewLocationKey?: string, firstViewIsChildView?: boolean }) => void = (ctx) =>
  sessionStorage.setItem(SessionStorageSafeHistoryContextDataKey, JSON.stringify(ctx));

const AppLayoutDict = {
  blockLocationPromp: { fr: 'Voulez-vous vraiment quitter la page? Des données pourraient être perdues.', en: 'Do you really want to quit this page? Data may be lost.' }
};

const AppLayout: (props: IAppLayoutProps) => JSX.Element = (props) => {

  const [currentViewInfoContextData, setCurrentViewInfoContextData] = useState<{ currentViewTitle?: string, currentViewIsChildView?: boolean, loading?: boolean, currentViewBackOverride?: () => void, tabs?: { scrollable: boolean, labels: string[], selectedTabIndex: number, changeSelectedTabHandler: (tabIndex: number) => void, hidden: boolean } }>({});

  const [safeHistoryContextData, setSafeHistoryContextData] = useState<ISafeHistoryContextStateData>({ firstViewLocationKeyIsSet: false });

  const [drawerIsOpened, setDrawerIsOpened] = useState<boolean>(false);

  const translate = useTranslator();

  const localizedBlockingPromp = translate(AppLayoutDict.blockLocationPromp);

  const histo = useHistory(); //This solution is based on History's object mutability (No rerender on location change)

  const setSafeHistoryContextDataFromViewInfo = useCallback((isChildView?: boolean, childViewBackOverride?: () => void) => {

    let safeHistoCtx: ISafeHistoryContextStateData | undefined = undefined;

    if (!safeHistoryContextData.firstViewLocationKeyIsSet) //first view 
    {
      safeHistoCtx = getSafeHistoryContextDataFromSession();

      if (!safeHistoCtx) {
        safeHistoCtx = { firstViewLocationKeyIsSet: true, firstViewLocationKey: histo.location.key, firstViewIsChildView: isChildView };
        saveSafeHistoryContextDataInSession(safeHistoCtx);
      }
    }

    if (childViewBackOverride && (safeHistoryContextData.currentBlockedLocationKey !== histo.location.key || !safeHistoryContextData.currentUnblockCbk)) {
      safeHistoCtx = safeHistoCtx ?? safeHistoryContextData;

      if (safeHistoCtx.currentUnblockCbk) safeHistoCtx.currentUnblockCbk();

      safeHistoCtx.currentBlockedLocationKey = histo.location.key;
      safeHistoCtx.currentUnblockCbk = histo.block(localizedBlockingPromp);
    }
    else if (!childViewBackOverride && safeHistoryContextData.currentUnblockCbk) {
      safeHistoCtx = safeHistoCtx ?? safeHistoryContextData;

      safeHistoCtx.currentUnblockCbk!();

      safeHistoCtx.currentBlockedLocationKey = undefined;
      safeHistoCtx.currentUnblockCbk = undefined;
    }

    if (safeHistoCtx)
      setSafeHistoryContextData(safeHistoCtx);

  }, [safeHistoryContextData, setSafeHistoryContextData, histo, localizedBlockingPromp]);

  const setCurrentViewInfo = useCallback((viewTitle?: string, isChildView?: boolean, loading?: boolean, childViewBackOverride?: () => void, tabs?: { scrollable: boolean, labels: string[], selectedTabIndex: number, changeSelectedTabHandler: (tabIndex: number) => void, hidden: boolean }) => {

    if (currentViewInfoContextData.currentViewTitle !== viewTitle
      || currentViewInfoContextData.currentViewIsChildView !== isChildView
      || currentViewInfoContextData.loading !== loading
      || currentViewInfoContextData.currentViewBackOverride !== (isChildView ? childViewBackOverride : undefined)
      || (currentViewInfoContextData.tabs !== tabs &&
        (!currentViewInfoContextData.tabs !== !tabs
          || currentViewInfoContextData.tabs?.scrollable !== tabs?.scrollable
          || currentViewInfoContextData.tabs?.selectedTabIndex !== tabs?.selectedTabIndex
          || currentViewInfoContextData.tabs?.changeSelectedTabHandler !== tabs?.changeSelectedTabHandler
          || currentViewInfoContextData.tabs?.hidden !== tabs?.hidden
          || currentViewInfoContextData.tabs?.labels?.length !== tabs?.labels?.length
          || (currentViewInfoContextData.tabs && currentViewInfoContextData.tabs.labels.join() !== tabs?.labels.join()))))
      setCurrentViewInfoContextData({ currentViewTitle: viewTitle, currentViewIsChildView: isChildView, loading: loading, currentViewBackOverride: (isChildView ? childViewBackOverride : undefined), tabs: tabs });

    setSafeHistoryContextDataFromViewInfo(isChildView, childViewBackOverride);

  }, [currentViewInfoContextData, setCurrentViewInfoContextData, setSafeHistoryContextDataFromViewInfo]);

  const memoCurrentViewInfoContextValue = useMemo<IAppLayoutContext>(() => ({
    invalidAppContext: !props.appContext.isValid,
    currentViewTitle: currentViewInfoContextData.currentViewTitle,
    currentViewIsChildView: currentViewInfoContextData.currentViewIsChildView,
    loading: currentViewInfoContextData.loading,
    currentViewBackOverride: currentViewInfoContextData.currentViewBackOverride,
    tabs: currentViewInfoContextData.tabs,
    setCurrentViewInfo: setCurrentViewInfo
  }), [props.appContext.isValid, currentViewInfoContextData, setCurrentViewInfo]);

  const theme = useTheme();
  const tooBigViewport = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => { //Permanent scroll on bigger viewports (hidden is checked because of MUI scroll lock on popups)
    if (tooBigViewport && window.document.body.style.overflowY !== 'hidden')
      window.document.body.style.overflowY = 'scroll';
    else if (window.document.body.style.overflowY === 'scroll')
      window.document.body.style.removeProperty('overflow-y');
  }, [tooBigViewport]);

  const menuButtonCbk = props.drawerNavLinks && props.drawerNavLinks.length > 0 ? () => setDrawerIsOpened(true) : undefined;

  const safeHisto = buildSafeHistoryFormContext(safeHistoryContextData, histo);

  if (!props.appContext.isValid)
    return (
      <Container maxWidth='sm' disableGutters={true}>
        {tooBigViewport && <Box position='fixed' height='100%' width='100%' maxWidth='sm' top={0} zIndex={-1} boxShadow='0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' sx={{ pointerEvents: 'none' }}></Box>}
        <AppLayoutContext.Provider value={memoCurrentViewInfoContextValue}>{props.children}</AppLayoutContext.Provider>
      </Container>);

  return (
    <Container maxWidth='sm' disableGutters={true} sx={{ pb: !currentViewInfoContextData.currentViewIsChildView ? 7 : 0 }}>
      {tooBigViewport && <Box position='fixed' height='100%' width='100%' maxWidth='sm' zIndex={-1} boxShadow='0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' sx={{ pointerEvents: 'none' }}></Box>}
      <CustomAppBar title={currentViewInfoContextData.currentViewTitle ?? props.appTitle}
        backButton={currentViewInfoContextData.currentViewIsChildView ? { backButtonAction: currentViewInfoContextData.currentViewBackOverride ?? safeHisto.goBack, backButtonIconNameOverride: safeHisto.IsCurrentViewFirstViewAndChildView() ? 'home' : undefined } : undefined}
        additionalUserActions={props.additionalUserActions.map(value => ({ iconName: value.iconName, action: value.action, label: value.label }))}
        tabs={currentViewInfoContextData.tabs}
        selectedTab={currentViewInfoContextData.tabs ? (currentViewInfoContextData.tabs.selectedTabIndex) : undefined}
        setSelectedTabCbk={currentViewInfoContextData.tabs ? currentViewInfoContextData.tabs.changeSelectedTabHandler : undefined}
        tabsAreHidden={currentViewInfoContextData.tabs ? currentViewInfoContextData.tabs.hidden : undefined}
        loading={currentViewInfoContextData.loading}
        tooBigViewport={tooBigViewport}
        menuButtonCbk={menuButtonCbk} />
      <SafeHistoryContext.Provider value={safeHistoryContextData}>
        <AppLayoutContext.Provider value={memoCurrentViewInfoContextValue}>
          {props.children}
        </AppLayoutContext.Provider>
      </SafeHistoryContext.Provider>
      {!currentViewInfoContextData.currentViewIsChildView && <CustomBottomNavigation bottomNavLinks={props.bottomNavLinks.map(value => ({ iconName: value.iconName, url: value.url, label: value.label }))} tooBigViewport={tooBigViewport} />}
      {props.drawerNavLinks && props.drawerNavLinks.length > 0 && <CustomSwipeableDrawer appTitle={props.appTitle} drawerNavLinks={props.drawerNavLinks} historyPush={safeHisto.push} drawerIsOpened={drawerIsOpened} setDrawerIsOpened={setDrawerIsOpened} />}
    </Container >
  );
}

export default AppLayout;