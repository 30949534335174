import { useContext } from 'react';

import MultilingualText from '../MultilingualText'
import LocalUserProfileContext, { ILocalUserProfileContext } from '../LocalUserProfileContext';
import logManager from '../logManager';

const reverseReplaceAll = (value: string, searchValue: string, replaceValue: string) => {

    let index = value.lastIndexOf(searchValue);

    while (index !== -1) {
        value = value.slice(0, index) + replaceValue + value.slice(index + searchValue.length);
        index = value.lastIndexOf(searchValue);
    }

    return value;
}

export const createTranslatorWithContext = (localUserProfileContext: ILocalUserProfileContext) => {

    const language = localUserProfileContext.language;

    return (text: MultilingualText, replacers?: string[]) => {

        if (!replacers || replacers.length === 0)
            return text[language];

        let replacedValue = text[language];

        const hasEscapeCars = replacedValue.indexOf('{{') !== -1 || replacedValue.indexOf('}}') !== -1;

        if (hasEscapeCars) {
            replacedValue = replacedValue.replaceAll('{{', '($%(!_(');
            replacedValue = reverseReplaceAll(replacedValue, '}}', ')$%)!_)');
        }

        for (let i = 0; i < replacers.length; i++)
            replacedValue = replacedValue.replaceAll('{' + i + '}', replacers[i]);

        if (replacedValue.indexOf('{') !== -1 || replacedValue.indexOf('}') !== -1)
            logManager.logError(`Unfilled parameter value by replacer or invalid use of { or } in parameterized text. Use {{ or }} to place { or } in final text.
            Error text : ${text[language]}
            Passed replacers count : ${replacers.length}`);

        if (hasEscapeCars)
            replacedValue = replacedValue.replaceAll('($%(!_(', '{').replaceAll(')$%)!_)', '}');

        return replacedValue;
    };
};

const useTranslator: () => (text: MultilingualText, replacers?: string[]) => string = () => {

    const localUserProfileContext = useContext(LocalUserProfileContext);

    return createTranslatorWithContext(localUserProfileContext);
}

export default useTranslator 