import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#fc6612',
      light: '#ff9947',
      dark: '#c13600',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#12a6fc',
      light: '#6bd7ff',
      dark: '#0078c8',
      //contrastText: 'rgba(0,0,0,.87)'
      contrastText: '#ffffff'
    },
    error: {
      main: '#fc1231'
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56, //Mobile App only, Toolbars should keep their 56px min-height on all devices
      '@media (min-width:0px) and (orientation: landscape)': { //Strongly coupled with default breakpoint values
        minHeight: 56, //Mobile App only, Toolbars should keep their 56px min-height on all devices
      },
      '@media (min-width:600px)': { //Strongly coupled with default breakpoint values
        minHeight: 56, //Mobile App only, Toolbars should keep their 56px min-height on all devices
      },
    }
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 48, //Mobile App only, Menu items should keep their 48px min-height on all devices
          '@media (min-width:600px)': { //Strongly coupled with default breakpoint values
            minHeight: 48 //Mobile App only, Menu items should keep their 48px min-height on all devices
          }
        }
      }
    }
  }
});

export default theme;