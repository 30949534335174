import { useContext, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import NextWeekChart from './components/NextWeekChart';
import NextMonthChart from './components/NextMonthChart';
import LoadingState from '../../components/LoadingState';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useLocaleFormater from '../../utilities/hooks/useLocaleFormater';
import { parseApiDate } from '../../utilities/apiDates';
import globalServicesAdapter, { ICleaningWorkload } from './Adapters/globalServicesAdapter';
import cleaningWorkloadDict from './cleaningWorkloadDict';

const CleaningWorkload = () => {

    const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

    const apiClient = useApiClient();

    const formater = useLocaleFormater();

    const translate = useTranslator();

    const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
    const [cleaningWorkload, setCleaningWorkload] = useState<ICleaningWorkload | undefined>(undefined);


    const getFormattedDate = (dateToFormat: string) => formater.formatDate(parseApiDate(dateToFormat), { year: 'numeric', month: 'numeric', day: 'numeric' });

    const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
        setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

        globalServicesAdapter
            .getCleaningWorkload(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
            .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
                setCleaningWorkload(value);
                setViewLoadingState(LoadingState.None);
            }))
            .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
    };

    const unexpectedLoadingErrorReload = () => getAllViewData(shouldSetStatesBeCancelledFn);

    const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

    return (
        <ViewLayout contentBoxPadding={2} viewTitle={translate(cleaningWorkloadDict.cleaningWorkload)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} >
            <Typography variant="subtitle2" >{translate(cleaningWorkloadDict.tomorrow)}</Typography>
            {cleaningWorkload?.tomorrow.date ? (<Typography variant="h6" color="primary.dark" data-cy="TomorrowDate" >{formater.formatDate(parseApiDate(cleaningWorkload.tomorrow.date), { weekday: 'long' })}, {getFormattedDate(cleaningWorkload?.tomorrow.date)}</Typography>) : null}
            <Typography variant="h6" color="primary.dark" data-cy="NbUnitsToCleanTomorrow">{cleaningWorkload?.tomorrow.totalNumberOfUnitsToClean === 0 ? translate(cleaningWorkloadDict.noUnitToClean) : cleaningWorkload?.tomorrow.totalNumberOfUnitsToClean === 1 ? translate(cleaningWorkloadDict.unitToClean) : cleaningWorkload?.tomorrow.totalNumberOfUnitsToClean + ' ' + translate(cleaningWorkloadDict.unitsToClean)}</Typography>
            <Typography variant="subtitle2" mt={4} >{translate(cleaningWorkloadDict.nextWeek)}</Typography>
            {cleaningWorkload?.nextWeek ?
                (
                    <Box display='flex' gap={1}>
                        <Typography variant="h6" data-cy="NextWeekStartDateDate"> {getFormattedDate(cleaningWorkload.nextWeek.startDate)}</Typography>
                        <Typography variant="h6"> {translate(cleaningWorkloadDict.to)}</Typography>
                        <Typography variant="h6" data-cy="NextWeekEndDateDate"> {getFormattedDate(cleaningWorkload.nextWeek.endDate)}</Typography>
                    </Box>
                )
                : null}
            <Typography variant="h6" data-cy="NbUnitsToCleanNextWeek">{cleaningWorkload?.nextWeek.totalNumberOfUnitsToClean === 0 ? translate(cleaningWorkloadDict.noUnitToClean) : cleaningWorkload?.nextWeek.totalNumberOfUnitsToClean === 1 ? translate(cleaningWorkloadDict.unitToClean) : cleaningWorkload?.nextWeek.totalNumberOfUnitsToClean + ' ' + translate(cleaningWorkloadDict.unitsToClean)}</Typography>
            <NextWeekChart noOfUnitsToClean={cleaningWorkload?.nextWeek.numberOfUnitsToClean} data-cy="NextWeekChart" />
            <Typography variant="subtitle2" mt={4} >{translate(cleaningWorkloadDict.nextMonth)}</Typography>
            {cleaningWorkload?.nextMonth ?
                (
                    <Box display='flex' gap={1}>
                        <Typography variant="h6" data-cy="NextMonthStartDateDate"> {getFormattedDate(cleaningWorkload.nextMonth.startDate)}</Typography>
                        <Typography variant="h6"> {translate(cleaningWorkloadDict.to)}</Typography>
                        <Typography variant="h6" data-cy="NextMonthEndDateDate"> {getFormattedDate(cleaningWorkload.nextMonth.endDate)}</Typography>
                    </Box>
                )
                : null}
            <Typography variant="h6" data-cy="NbUnitsToCleanNextWeek">{cleaningWorkload?.nextMonth.totalNumberOfUnitsToClean === 0 ? translate(cleaningWorkloadDict.noUnitToClean) : cleaningWorkload?.nextMonth.totalNumberOfUnitsToClean === 1 ? translate(cleaningWorkloadDict.unitToClean) : cleaningWorkload?.nextMonth.totalNumberOfUnitsToClean + ' ' + translate(cleaningWorkloadDict.unitsToClean)}</Typography>
            <NextMonthChart noOfUnitsToClean={cleaningWorkload?.nextMonth.numberOfUnitsToClean} data-cy="NextMonthChart" />
        </ViewLayout >
    )
}

export default CleaningWorkload;