import { useContext, useState, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import { isApiConcurrencyError } from '../../utilities/IApiConcurrencyError';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';

import useApiClient from '../../utilities/hooks/useApiClient';
import useTranslator from '../../utilities/hooks/useTranslator';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';

import CancelDialog from './components/CancelDialog';
import specialtyAdapter, { ISpecialty } from './adapters/specialtyAdapter';

type SpecialtyUpdateParams = { specialtyid: string }

const specialtyUpdateDict = {
  title: { en: 'Update specialty', fr: 'Modifier une spécialité' },
  name: { en: 'Name', fr: 'Nom' },
  description: { en: 'Description', fr: 'Description' },
  required: { en: '*Required', fr: '*Requis' },
  update: { en: 'UPDATE', fr: 'MODIFIER' },
  cancel: { en: 'CANCEL', fr: 'ANNULER' },
  updationConcurrencyError: { en: "Specialty has been modified by another user. These changes cannot be applied. Please cancel your changes.", 
                              fr: "La spécialité a été modifié par un autre utilisateur. Les modifications ne pourront être appliquées. Veuillez annuler vos modifications." },
  updationInternalError: { en: "Internal error while trying to update specialty. Please retry.", 
                           fr: "Erreur interne lors de la sauvegarde de la spécialité. Veuillez réessayer." }
};

const SpecialtyUpdate = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const apiClient = useApiClient();
  const history = useSafeHistory()
  const translate = useTranslator();
  
  const {specialtyid} = useParams<SpecialtyUpdateParams>();
  const [specialty, setSpecialty] = useState<ISpecialty | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
  const [cancelDialogOpenInfo, setCancelDialogOpenInfo] = useState<{ open: boolean } | undefined>(undefined);
  const openCancelDialogCbk = useCallback(() => setCancelDialogOpenInfo({ open: true }), [setCancelDialogOpenInfo]); 
  
  const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);
  const safeSetUserMessageContent = (userMessageContent: string) => !shouldSetStatesBeCancelledFn() && setUserMessage(createMessageWithContent(userMessageContent));

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    specialtyAdapter
      .getSpecialty(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, specialtyid)
      .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => { 
                        setSpecialty(value);
                        setName(value.name); 
                        setDescription(value.description); 
                        setViewLoadingState(LoadingState.None);}))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const updateSpecialty = () => {
    specialty!.name = name!;
    specialty!.description = description!;

    setViewLoadingState(LoadingState.ModalWithVisibleContent);

    specialtyAdapter
    .updateSpecialty(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, specialtyid, specialty!, true)
      .then(() => history.goBack())
      .catch((error: any) => {
        if (isApiConcurrencyError(error))
          safeSetUserMessageContent(translate(specialtyUpdateDict.updationConcurrencyError));
        else
          safeSetUserMessageContent(translate(specialtyUpdateDict.updationInternalError));
      })
      .finally(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.None));
  }

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  return  (
    <ViewLayout viewTitle={translate(specialtyUpdateDict.title)} loadingState={viewLoadingState} isChildView={true} childViewBackOverride={openCancelDialogCbk} userMessageInfo={{ userMessage: userMessage, setUserMessage: setUserMessage }}>
      {specialty && <>
        <Box display="flex" sx={{flexDirection: 'column', m:2}}>
          <TextField fullWidth sx={{mb:2}} value={name} onChange={(event) => setName(event.target.value)} helperText={ !name ? translate(specialtyUpdateDict.required) : ' '} label={translate(specialtyUpdateDict.name)} data-cy="NameInput" />
          <TextField fullWidth multiline rows={4} value={description} onChange={(event) => setDescription(event.target.value)} label={translate(specialtyUpdateDict.description)} data-cy="DescriptionInput" />
        </Box>
        <Paper elevation={2} sx={{ backgroundColor: '#FAFAFA', width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', zIndex: '2' }}>
            <Box display="flex" justifyContent="flex-end" sx={{m:1}}>
              <Button sx={{mr:1}} onClick={openCancelDialogCbk} size="medium" variant="contained" data-cy="CancelAction">
                {translate(specialtyUpdateDict.cancel)}
              </Button>
              <Button disabled={!name} size="medium" onClick={updateSpecialty} variant="contained" data-cy="UpdateAction">
                {translate(specialtyUpdateDict.update)}
              </Button>
            </Box>
        </Paper>
        </>
      }
      <CancelDialog openInfo={cancelDialogOpenInfo} setOpenInfo={setCancelDialogOpenInfo} />
    </ViewLayout>
  );
};

export default SpecialtyUpdate;