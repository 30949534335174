import IApiClient from '../../../utilities/IApiClient';

export interface IEmployee {
  id: string;
  aggregateTimeStamp: string,
  employeeNumber: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  phones: string[];
  notes: string;
  isActive: boolean;
  creationDate: string;
  deactivationDate:string;
  reactivationDate: string;
  specialtyIds : string[];
  isManager: boolean;
}

export interface IReactivateEmployeeDTO {
  reactivationDate: string;
}

const employeeAdapter = {
  getEmployee: (apiClient: IApiClient, propertyMgmtId: string, employeeId: string) => apiClient.get<IEmployee>('/Employees/' + propertyMgmtId + '/' + employeeId),

  reactivateEmployee: (apiClient:IApiClient, propertyMgmtId: string, aggregateTimeStamp: string, employeeId: string, updateEmployee: IReactivateEmployeeDTO, expectConcurrencyError: boolean) => apiClient.post('/Employees/' + propertyMgmtId + '/' + employeeId + '/reactivate?aggregateTimeStamp=' + aggregateTimeStamp, updateEmployee, expectConcurrencyError)
};

export default employeeAdapter;
