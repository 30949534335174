import { useContext, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import AppLayoutContext from '../AppLayout/AppLayoutContext';
import LoadingState from '../LoadingState';
import ViewFab from '../internals/ViewFab';
import UserMessagePane from '../internals/UserMessagePane';
import IUserMessage from '../IUserMessage';
import logManager from '../../utilities/logManager';
import UnexpectedErrorContent from '../internals/UnexpectedErrorContent';

interface ITabbedViewLayoutProps {
    viewTitle?: string,
    isChildView?: boolean,
    loadingState?: LoadingState,
    fab?: { iconName: string, fabAction: () => void, disabled?: boolean },
    userMessageInfo?: { userMessage: IUserMessage | undefined, setUserMessage: (message: IUserMessage | undefined) => void },
    tabs: { scrollable: boolean, tabsDetails: { label: string, pane: JSX.Element }[] },
    defaultSelectedTabIndex?: number,
    liftedSelectStateMode?: {
        selectedTabIndex: number,
        onSelectedTabIndexChange: (selectedTab: number) => void
    },
    unexpectedLoadingErrorReload?: () => void,
    contentBoxPadding?: number | string,
    contentBoxPaddingTop?: number | string,
    contentBoxPaddingRight?: number | string,
    contentBoxPaddingBottom?: number | string,
    contentBoxPaddingLeft?: number | string,
    childViewBackOverride?: () => void
}

const BuildTabsInfo = (tabs: { scrollable: boolean, tabsDetails: { label: string, pane: JSX.Element }[] }, selectedTabIndex: number, onSelectedTabIndexChange: (tabIndex: number) => void, hidden: boolean) => {

    let labels: string[] = [];

    for (let i = 0; i < tabs.tabsDetails.length; i++)
        labels.push(tabs.tabsDetails[i].label);

    return { scrollable: tabs.scrollable, labels: labels, selectedTabIndex: selectedTabIndex, changeSelectedTabHandler: onSelectedTabIndexChange, hidden: hidden };
};

const TabbedViewLayout = (props: ITabbedViewLayoutProps) => {

    const appLayoutCtx = useContext(AppLayoutContext);

    const isLoading = !!props.loadingState && props.loadingState !== LoadingState.None && props.loadingState !== LoadingState.UnexpectedLoadingError;

    const [internalSelectedTab, SetInternalSelectedTab] = useState(props.defaultSelectedTabIndex ?? 0);

    const [isContentVisibleWithCurrentViewInfo, setIsContentVisibleWithCurrentViewInfo] = useState(false);

    if (props.defaultSelectedTabIndex && props.liftedSelectStateMode)
        logManager.logError('Props defaultSelectedTabIndex and liftedSelectStateMode cannot both be set for the same TabbedViewLayout. Property defaultSelectedTabIndex will be ignored.');

    const selectedTabIndex = props.liftedSelectStateMode ? props.liftedSelectStateMode.selectedTabIndex : internalSelectedTab;

    const onSelectedTabIndexChange = props.liftedSelectStateMode ? props.liftedSelectStateMode.onSelectedTabIndexChange : SetInternalSelectedTab;

    const invisibleContentAndHiddenTabs = props.loadingState === LoadingState.NotModalWithInvisibleContent || props.loadingState === LoadingState.ModalWithInvisibleContent || props.loadingState === LoadingState.UnexpectedLoadingError;

    const builtTabs = useMemo(() => BuildTabsInfo(props.tabs, selectedTabIndex, onSelectedTabIndexChange, invisibleContentAndHiddenTabs), [props.tabs, selectedTabIndex, onSelectedTabIndexChange, invisibleContentAndHiddenTabs]);

    if (!props.isChildView && props.childViewBackOverride)
        logManager.logError('Prop childViewBackOverride can only be set when isChildView is set to true.');

    useEffect(() => {
        appLayoutCtx.setCurrentViewInfo(props.viewTitle, props.isChildView, isLoading, props.childViewBackOverride, builtTabs);
        setIsContentVisibleWithCurrentViewInfo(!invisibleContentAndHiddenTabs); //Delayed content visibility (content is shown after the viewInfo is set)
    },
        [appLayoutCtx, appLayoutCtx.setCurrentViewInfo, props.viewTitle, props.isChildView, isLoading, props.childViewBackOverride, builtTabs, invisibleContentAndHiddenTabs]
    );

    const shouldScrollViewToTop = !props.liftedSelectStateMode && !invisibleContentAndHiddenTabs;

    useEffect(() => {
        if (shouldScrollViewToTop)
            window.scrollTo(0, 0);
    }, [internalSelectedTab, shouldScrollViewToTop]);

    if (appLayoutCtx.invalidAppContext) {

        logManager.logError('TabbedViewLayout rendered with invalid context.');
        return <UnexpectedErrorContent />;
    }

    const selectedTabPane = props.tabs.tabsDetails[selectedTabIndex].pane;

    return (
        <Box>
            {<Modal open={props.loadingState === LoadingState.ModalWithVisibleContent || props.loadingState === LoadingState.ModalWithInvisibleContent} aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,.32)', zIndex: (theme) => theme.zIndex.drawer + 1, width: '100%' } }}
                disableEscapeKeyDown={true}
                closeAfterTransition={true}><Fade in={props.loadingState === LoadingState.ModalWithVisibleContent || props.loadingState === LoadingState.ModalWithInvisibleContent}><Box></Box></Fade></Modal>}
            {props.tabs.tabsDetails.map((value, index) => <Box display={(value.pane !== selectedTabPane || invisibleContentAndHiddenTabs) ? 'none' : undefined}
                visibility={!invisibleContentAndHiddenTabs && !isContentVisibleWithCurrentViewInfo ? 'hidden' : undefined}
                key={index}
                mt={6}
                p={props.contentBoxPadding ? props.contentBoxPadding : undefined}
                pt={props.contentBoxPaddingTop ? props.contentBoxPaddingTop : undefined}
                pr={props.contentBoxPaddingRight ? props.contentBoxPaddingRight : undefined}
                pb={props.contentBoxPaddingBottom ? props.contentBoxPaddingBottom : undefined}
                pl={props.contentBoxPaddingLeft ? props.contentBoxPaddingLeft : undefined}
                data-cy={'TabContent_' + index.toString()}>
                {value.pane}
            </Box>)/* mt (MarginTop) is set here so the content is always rendered at the right position. Even if the tabs are located in the AppBar. */}
            {props.loadingState === LoadingState.UnexpectedLoadingError && <UnexpectedErrorContent unexpectedErrorReload={props.unexpectedLoadingErrorReload} />}
            {props.userMessageInfo && <UserMessagePane userMessage={props.userMessageInfo.userMessage} setUserMessage={props.userMessageInfo.setUserMessage} isInChildView={!!props.isChildView} isInViewWithFab={!!props.fab} />}
            {(isContentVisibleWithCurrentViewInfo && props.fab) &&
                <ViewFab iconName={props.fab.iconName} fabAction={props.fab.fabAction} isInChildView={!!props.isChildView} disabled={props.fab.disabled} />}
        </Box>
    );
};

export default TabbedViewLayout;