import { memo } from 'react';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link, useLocation } from 'react-router-dom';

interface ICustomBottomNavigationProps {
    bottomNavLinks: {
        label: string
        iconName: string,
        url: string
    }[],
    tooBigViewport: boolean
}

const CustomBottomNavigation = (props: ICustomBottomNavigationProps) => {

    const location = useLocation();

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, width: '100%', maxWidth: 'sm', zIndex: (theme) => theme.zIndex.appBar }} elevation={3}>
            <BottomNavigation
                className={!props.tooBigViewport ? 'mui-fixed' : undefined}
                sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', '& .MuiBottomNavigationAction-root': { color: 'rgba(255,255,255,.6)' }, '&	.MuiBottomNavigationAction-root.Mui-selected': { color: 'primary.contrastText' } }}
                showLabels
                value={location.pathname}
                data-cy='BottomNavigation'
            >
                {props.bottomNavLinks.map((bottomNavLink) => <BottomNavigationAction value={bottomNavLink.url} label={bottomNavLink.label} icon={<Icon>{bottomNavLink.iconName}</Icon>} component={Link} to={bottomNavLink.url} key={bottomNavLink.url} />)}
            </BottomNavigation>
        </Paper>
    )
}

export default memo(CustomBottomNavigation, (prevProps, nextProps) => {

    if (prevProps.tooBigViewport !== nextProps.tooBigViewport || prevProps.bottomNavLinks.length !== nextProps.bottomNavLinks.length)
        return false;

    for (let i = 0; i < prevProps.bottomNavLinks.length; i++)
        if (prevProps.bottomNavLinks[i].iconName !== nextProps.bottomNavLinks[i].iconName
            || prevProps.bottomNavLinks[i].label !== nextProps.bottomNavLinks[i].label
            || prevProps.bottomNavLinks[i].url !== nextProps.bottomNavLinks[i].url)
            return false;

    return true;
});