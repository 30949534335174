import { useState, useContext, useRef, useEffect, useCallback } from 'react';

import TabbedViewLayout from '../../components/TabbedViewLayout/TabbedViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import { stringifyApiDate } from '../../utilities/apiDates';

import cleaningJobsForEmployeeDict from './cleaningJobsForEmployeeDict';
import reservationsAdapter, { IReservation } from '../CleaningJobsForEmployee/adapters/reservationsAdapter';
import employeesAdapter, { IEmployee } from './adapters/employeesAdapter';
import propertyMgmtOrgsAdapter, { IPropertyMgmtOrg } from './adapters/propertyMgmtOrgsAdapter';
import ToConfirmTabContent from './components/ToConfirmTabContent';
import ToDoTabContent from './components/ToDoTabContent';
import InProgressTabContent from './components/InProgressTabContent';
import DoneTabContent from './components/DoneTabContent';
import { unstable_batchedUpdates } from 'react-dom';

const CleaningJobsForEmployee = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const translate = useTranslator();

  const apiClient = useApiClient();

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

  const [selectedTab, setSelectedTab] = useState(0);
  const [viewFullDataLoadTimeStamp, setViewFullDataLoadTimeStamp] = useState<number | undefined>(undefined);

  const tabsScrollInfo = useRef([0, 0, 0, 0]);
  const changeTab = useCallback(
    (tabIndex) => {
      tabsScrollInfo.current[selectedTab] = window.scrollY;
      setSelectedTab(tabIndex);
    },
    [selectedTab, setSelectedTab]
  );

  useEffect(() => {
    window.scrollTo(0, tabsScrollInfo.current[selectedTab]);
  }, [selectedTab, viewFullDataLoadTimeStamp]);

  const [reservationsListContent, setReservationsListContent] = useState<IReservation[] | undefined>(undefined);
  const [employeesListContent, setEmployeesListContent] = useState<IEmployee[] | undefined>(undefined);
  const [propertyMgmtOrg, setPropertyMgmtOrg] = useState<IPropertyMgmtOrg | undefined>(undefined);

  const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    const currentDate = new Date();
    const date = stringifyApiDate(currentDate);
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    //Concurrent get calls
    Promise.all([
      employeesAdapter.getAllEmployees(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string),
      reservationsAdapter.getReservations(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, date,
      propertyMgmtContext.uniqueUserId as string), propertyMgmtOrgsAdapter.getPropertyMgmtOrg(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
    ]).then(
      (values) =>
        !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {

          setEmployeesListContent(values[0]);
          setReservationsListContent(values[1]);
          setPropertyMgmtOrg(values[2]);

          setViewLoadingState(LoadingState.None);
          setViewFullDataLoadTimeStamp(Date.now);
        }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };
  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  const cleaningJobRefreshAction = () => {
    tabsScrollInfo.current[selectedTab] = window.scrollY;
    getAllViewData(shouldSetStatesBeCancelledFn);
  };

  const unexpectedLoadingErrorReload = () => {
    tabsScrollInfo.current = [0, 0, 0, 0];
    getAllViewData(shouldSetStatesBeCancelledFn);
  };

  const safeSetViewLoadingState = (loadingState: LoadingState) => !shouldSetStatesBeCancelledFn() && setViewLoadingState(loadingState);

  const safeSetUserMessageContent = (userMessageContent: string) => !shouldSetStatesBeCancelledFn() && setUserMessage(createMessageWithContent(userMessageContent));

  const refreshSingleReservationInList = (reservation: IReservation) => {
    if (!reservationsListContent) throw new Error('Function refreshReservation called in invalid state.');

    if (shouldSetStatesBeCancelledFn()) return;

    for (let i = 0; i < reservationsListContent.length; i++) if (reservationsListContent[i].id === reservation.id) reservationsListContent[i] = reservation;

    setReservationsListContent([...reservationsListContent]); //Replacing state object
  };

  return (
    <TabbedViewLayout
      loadingState={viewLoadingState}
      fab={{ iconName: 'refresh', fabAction: cleaningJobRefreshAction }}
      tabs={{
        scrollable: true,
        tabsDetails: [
          {
            label: translate(cleaningJobsForEmployeeDict.toConfirmTabLabel),
            pane: (
              <ToConfirmTabContent
                allReservations={reservationsListContent}
                allEmployees={employeesListContent}
                propertyMgmtOrg={propertyMgmtOrg}
                safeSetViewLoadingState={safeSetViewLoadingState}
                refreshSingleReservationInList={refreshSingleReservationInList}
                safeSetUserMessageContent={safeSetUserMessageContent}
              />
            ),
          },
          {
            label: translate(cleaningJobsForEmployeeDict.toDoTabLabel),
            pane: (
              <ToDoTabContent
                allReservations={reservationsListContent}
                allEmployees={employeesListContent}
                propertyMgmtOrg={propertyMgmtOrg}
                safeSetViewLoadingState={safeSetViewLoadingState}
                refreshSingleReservationInList={refreshSingleReservationInList}
                safeSetUserMessageContent={safeSetUserMessageContent}
              />
            ),
          },
          {
            label: translate(cleaningJobsForEmployeeDict.inProgressTabLabel),
            pane: (
              <InProgressTabContent
                allReservations={reservationsListContent}
                allEmployees={employeesListContent}
                propertyMgmtOrg={propertyMgmtOrg}
                safeSetViewLoadingState={safeSetViewLoadingState}
                refreshSingleReservationInList={refreshSingleReservationInList}
                safeSetUserMessageContent={safeSetUserMessageContent}
              />
            ),
          },
          {
            label: translate(cleaningJobsForEmployeeDict.DoneTabLabel),
            pane: (
              <DoneTabContent
                allReservations={reservationsListContent}
                allEmployees={employeesListContent}
                propertyMgmtOrg={propertyMgmtOrg}
                safeSetViewLoadingState={safeSetViewLoadingState}
                refreshSingleReservationInList={refreshSingleReservationInList}
                safeSetUserMessageContent={safeSetUserMessageContent}
              />
            ),
          },
        ],
      }}
      liftedSelectStateMode={{
        selectedTabIndex: selectedTab,
        onSelectedTabIndexChange: changeTab,
      }}
      contentBoxPaddingTop={1}
      contentBoxPaddingRight={1}
      contentBoxPaddingLeft={1}
      contentBoxPaddingBottom={7}
      unexpectedLoadingErrorReload={unexpectedLoadingErrorReload}
      userMessageInfo={{
        userMessage: userMessage,
        setUserMessage: setUserMessage,
      }}
    />
  );
};

export default CleaningJobsForEmployee;
