import { useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';
import useTranslator from '../../utilities/hooks/useTranslator';
import useQueryString from '../../utilities/hooks/useQueryString';

const bookingsChildDict = {
    title: { en: 'Bookings Child', fr: 'Réservations Enfant' }
}

type BookingsChildParams = { bookingid: string }

const BookingsChild = () => {

    const ctx = useContext(PropertyMgmtMobileAppContext);

    const translate = useTranslator(); //This a test view, only the view title will be translated.

    const [message, setMessage] = useState<IUserMessage | undefined>(createMessageWithContent('This is a long test message that is shown when opening view. This message should be displayed using multiple lines.'));

    const { bookingid } = useParams<BookingsChildParams>();

    const queryStringParams = useQueryString();

    return (
        <ViewLayout viewTitle={translate(bookingsChildDict.title)} isChildView={true} userMessageInfo={{ userMessage: message, setUserMessage: setMessage }}>
            <Box p={2}>
                <Typography variant='body1' component='p' >Bookings Child test View Content</Typography>
                <Typography variant='body1' component='p' mt={2} sx={{ overflowWrap: 'break-word' }}>PromertyMgmtApp Context : {JSON.stringify(ctx, undefined, 4)}</Typography>
                <Typography variant='body1' component='p' mt={2} sx={{ overflowWrap: 'break-word' }}>Booking ID value from URL : {bookingid}</Typography>
                <Typography variant='body1' component='p' mt={2} sx={{ overflowWrap: 'break-word' }}>Display Type Query String Param : {queryStringParams.get('displaytype')}</Typography>
            </Box>
        </ViewLayout>
    )
}

export default BookingsChild 