import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import ReactivationDialog from './components/ReactivationDialog';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import { parseApiDate } from '../../utilities/apiDates';
import useLocaleFormater from '../../utilities/hooks/useLocaleFormater';
import specialitiesAdapter, { ISpecialty } from './Adapters/specialitiesAdapter';
import employeeAdapter, { IEmployee } from './Adapters/employeeAdapter';


const employeeDict = {
  employee: { en: 'Employee', fr: 'Employé' },
  status: { en: 'Status:', fr: 'État:' },
  employeeNumber: { en: 'Employee No:', fr: `No d'employé:` },
  name: { en: 'Name:', fr: 'Nom:' },
  email: { en: 'Email:', fr: 'Courriel:' },
  nickname: { en: 'Nickname:', fr: 'Surnom:' },
  phone: { en: 'Phone {0}:', fr: 'Téléphone {0}:' },
  specialities: { en: 'Specialties:', fr: 'Spécialités:' },
  notes: { en: 'Notes:', fr: 'Notes:' },
  reactivate: { en: 'Reactivate', fr: 'Réactiver' },
  activeSince: { en: 'Active since', fr: 'Actif depuis' },
  inactiveSince: { en: 'Inactive since', fr: 'Inactif depuis' },
  inactive: { en: 'Inactive', fr: 'Inactif' },
  manager: { en: 'Manager', fr: 'Superviseur' }
};

type EmployeeUpdateParams = { employeeid: string }

const Employee = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const apiClient = useApiClient();
  const formater = useLocaleFormater();
  const translate = useTranslator();
  const history = useSafeHistory();

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
  const [employeeDetails, setEmployeeDetails] = useState<IEmployee | undefined>(undefined);
  const [specialities, setSpecialities] = useState<ISpecialty[] | undefined>(undefined);

  const [ReactivationDlgOpenInfo, setReactivationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);

  const { employeeid } = useParams<EmployeeUpdateParams>();

  const getSpecialityNameById = (specialityId: string) => specialities?.find((speciality) => speciality.id === specialityId)?.name;

  const employeeEdit = () => history.push(`/employeeupdate/${employeeDetails?.id}`);

  const getFormattedDate = (dateToFormat: string) => formater.formatDate(parseApiDate(dateToFormat), { year: 'numeric', month: 'numeric', day: 'numeric' });

  const getAllViewData = !employeeid
    ? undefined
    : (shouldSetStatesBeCancelledFn: () => boolean) => {
      setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

      Promise.all([
        employeeAdapter.getEmployee(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, employeeid),
        specialitiesAdapter.getAllSpecialties(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
      ])
        .then((values) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
          setEmployeeDetails(values[0]);
          setSpecialities(values[1])
          setViewLoadingState(LoadingState.None);
        }))
        .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
    };

  useSafeAsyncOperations(getAllViewData);

  return (
    <ViewLayout
      isChildView={true}
      viewTitle={translate(employeeDict.employee)}
      loadingState={viewLoadingState}
      contentBoxPaddingTop={1}
      contentBoxPaddingBottom={1}
      fab={employeeDetails?.isActive ? { iconName: 'edit', fabAction: employeeEdit } : undefined}>
      <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex" alignItems="baseline" justifyContent="space-between">
        <Box display="flex" flexGrow={1}>
          <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
            {translate(employeeDict.employeeNumber)}
          </Typography>
          <Typography>{employeeDetails?.employeeNumber}</Typography>
        </Box>
        {employeeDetails?.isManager ? <Typography sx={{ ml: 2 }} variant="h6">{translate(employeeDict.manager)}</Typography> : null}
      </Box>
      <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex">
        <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
          {translate(employeeDict.status)}
        </Typography>
        {employeeDetails?.isActive ? (
          <Typography>
            {translate(employeeDict.activeSince)} {employeeDetails.creationDate ? getFormattedDate(employeeDetails.creationDate) : getFormattedDate(employeeDetails.reactivationDate)}
          </Typography>
        ) : (
          <Typography color="primary.dark">
            {translate(employeeDict.inactiveSince)} {employeeDetails?.deactivationDate ? getFormattedDate(employeeDetails?.deactivationDate) : ''}
          </Typography>
        )}
      </Box>
      <Divider variant="middle" sx={{ mt: 1, mb: 1 }} />
      <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex" alignItems="baseline">
        <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
          {translate(employeeDict.name)}
        </Typography>
        <Typography variant="h6">
          {employeeDetails?.firstName} {employeeDetails?.lastName}
        </Typography>
      </Box>
      <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex" alignItems="baseline">
        <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
          {translate(employeeDict.nickname)}
        </Typography>
        <Typography variant="h6">{employeeDetails?.nickname}</Typography>
      </Box>
      <Box p={1} minWidth={0} sx={{ ml: 1, mr: 1 }} display="flex">
        <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
          {translate(employeeDict.email)}
        </Typography>
        <Typography>{employeeDetails?.email}</Typography>
      </Box>
      {employeeDetails?.phones && employeeDetails.phones.map((phone, index) => (
        <Box key={phone} p={1} sx={{ ml: 1, mr: 1, }} display="flex" alignItems={index === 0 ? 'baseline' : 'normal'}>
          <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
            {translate(employeeDict.phone, [(index + 1).toString()])}
          </Typography>
          <Typography variant={index === 0 ? 'h6' : undefined}>{phone}</Typography>
        </Box>
      )
      )}
      <Divider variant="middle" sx={{ mt: 1, mb: 1 }} />
      <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex">
        <Typography flexBasis={(theme) => theme.spacing(16)} flexShrink={0}>
          {translate(employeeDict.specialities)}
        </Typography>
        <Box>{!employeeDetails?.specialtyIds ? null : employeeDetails.specialtyIds.map((id, index) => <Typography key={index}>{getSpecialityNameById(id)}</Typography>)}</Box>
      </Box>
      <Divider variant="middle" sx={{ mt: 1, mb: 1 }} />
      <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex">
        <Typography flexBasis={(theme) => theme.spacing(9)} flexShrink={0}>
          {translate(employeeDict.notes)}
        </Typography>
        <Typography sx={{ maxWidth: (theme) => theme.spacing(35) }}>{employeeDetails?.notes}</Typography>
      </Box>
      <Paper elevation={2} sx={{ visibility: employeeDetails?.isActive ? 'hidden' : 'visible', backgroundColor: '#FAFAFA', width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', p: 1, zIndex: '2' }}>
        <Box>
          <Button onClick={() => setReactivationDlgOpenInfo({ open: true })} size="medium" variant="contained">
            {translate(employeeDict.reactivate)}
          </Button>
        </Box>
      </Paper>
      <ReactivationDialog
        openInfo={ReactivationDlgOpenInfo}
        setOpenInfo={setReactivationDlgOpenInfo}
        employeeDetails={employeeDetails}
        setViewLoadingState={setViewLoadingState} />
    </ViewLayout>
  );
};

export default Employee;
