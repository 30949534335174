import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import useApiClient from '../../utilities/hooks/useApiClient';
import propertyMgmtOrgsAdapter from './adapters/propertyMgmtOrgsAdapter';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useTranslator from '../../utilities/hooks/useTranslator';

const organisationsDict = {
    title: { en: 'Organisations', fr: 'Organisations' }
}

const Organisations = () => {

    const history = useHistory()

    const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

    const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
    const [organisationsListContent, setOrganisationsListContent] = useState<{ name: string, id: string }[] | undefined>(undefined);

    const apiClient = useApiClient();

    const translate = useTranslator();

    const getAllPropertyMgmtOrg = (shouldSetStatesBeCancelledFn: () => boolean) => {

        setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

        propertyMgmtOrgsAdapter.getAllPropertyMgmtOrgWhereUserHasRole(
            apiClient,
            propertyMgmtContext.userPropertyMgmtOrgRoles ? propertyMgmtContext.userPropertyMgmtOrgRoles.map((value) => value.propertyMgmtId) : []
        ).then((value) => {
            if (shouldSetStatesBeCancelledFn()) return;
            setOrganisationsListContent(value);
            setViewLoadingState(LoadingState.None);
        }
        ).catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
    }

    const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllPropertyMgmtOrg);

    const unexpectedLoadingErrorReload = () => getAllPropertyMgmtOrg(shouldSetStatesBeCancelledFn);

    const changeOrganisation = (id: string) => {
        propertyMgmtContext.changeActivePropertyMgmtId(id);
        history.push('/');
    };

    return (
        <ViewLayout loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload}>
            <Typography sx={{ marginTop: 2, marginLeft: 2, marginBottom: 1 }} variant='h5' component='div'>{translate(organisationsDict.title)}</Typography>
            <List>
                {organisationsListContent?.map((value) => <ListItem disablePadding key={value.id}>
                    <ListItemButton selected={value.id === propertyMgmtContext.activePropertyMgmtOrgId} disabled={value.id === propertyMgmtContext.activePropertyMgmtOrgId} onClick={() => changeOrganisation(value.id)}>
                        <ListItemText primary={value.name} />
                    </ListItemButton>
                </ListItem>)}
            </List>
        </ViewLayout>
    )
}

export default Organisations 