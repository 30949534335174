import IApiClient from '../../../utilities/IApiClient';

export interface IPropertyMgmtOrg {
    id: string,
    name: string
}

const propertyMgmtOrgsAdapter = {
    getAllPropertyMgmtOrgWhereUserHasRole: (apiClient: IApiClient, ids: string[]) =>
        apiClient.get<IPropertyMgmtOrg[]>('/PropertyMgmtOrgs/GetAllPropertyMgmtOrgWhereUserHasRole?propertyMgmtOrgIds=' + ids.join(','))
};

export default propertyMgmtOrgsAdapter;