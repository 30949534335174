import useTranslator from '../../../utilities/hooks/useTranslator';
import useSafeHistory from '../../../utilities/hooks/useSafeHistory';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

export interface CancelDialogProps {
  openInfo: {open: boolean} | undefined;
  setOpenInfo: (openInfo: {open: boolean} | undefined) => void;
}

const CancelDialogDict = {
  cancelDialogTitle: { en: 'Cancel Specialty Creation ?', fr: `Annuler la création de la spécialité ?` },
  cancelDialogMessage: { en: 'You will loose the data you entered about the new specialty. Do you agree ?', 
                         fr: `Vous perdrez les données saisies à propos de la nouvelle spécialité. Êtes-vous d'accord ?` },
  cancelDialogYes: { en: 'YES', fr: 'OUI' },
  cancelDialogNo: { en: 'NO', fr: 'NON' },
};

const CancelDialog = (props: CancelDialogProps) => {
  const translate = useTranslator();
  const history = useSafeHistory();

  const cancelDialogYes = () => {
    history.goBack()
  }
  const cancelDialogNo = () => {
    props.setOpenInfo({ open: false });
  };

  return (
    <Dialog fullWidth onClose={() => {}} open={!!props.openInfo && props.openInfo.open} keepMounted>
      <DialogTitle data-cy="CancelDialogTitle">{translate(CancelDialogDict.cancelDialogTitle)}</DialogTitle>
      <DialogContent >
        <DialogContentText data-cy="CancelDialogMessage">
          {translate(CancelDialogDict.cancelDialogMessage)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancelDialogNo} data-cy="CancelDialogNoAction">
          {translate(CancelDialogDict.cancelDialogNo)}
        </Button>
        <Button onClick={cancelDialogYes} data-cy="CancelDialogYesAction">
          {translate(CancelDialogDict.cancelDialogYes)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
