const propertyMgmtApiScopes = [
  'propertymgmtapi.reservations.read',
  'propertymgmtapi.reservations.readmany',
  'propertymgmtapi.propertymgmtorgs.read',
  'propertymgmtapi.rentalunits.read',
  'propertymgmtapi.employees.read',
  'propertymgmtapi.employees.readall',
  'propertymgmtapi.specialties.read',
  'propertymgmtapi.specialties.manage',
  'propertymgmtapi.propertymgmtorgs.readwhereuserhasrole',
  'propertymgmtapi.reservations.changecleaningjobstate',
  'propertymgmtapi.reservations.assigncleaningjob',
  'propertymgmtapi.reservations.readforemployee',
  'propertymgmtapi.employees.read',
  'propertymgmtapi.employees.create',
  'propertymgmtapi.employees.update',
  'propertymgmtapi.rentalunits.manage',
  'propertymgmtapi.globalservices.computecleaningworkload',
];

export default propertyMgmtApiScopes;