import { ReactNode, useState, useMemo } from 'react';

import UserLanguage from '../../utilities/UserLanguage';
import LocalUserProfileContext, { ILocalUserProfileContext } from '../../utilities/LocalUserProfileContext';

const StorageLocalUserProfileLanguageKey = 'LocalUserProfileLanguage';

interface ILocalUserProfileContextProviderProps {
    children?: ReactNode
}

const getLocalUserProfileLanguage: () => UserLanguage = () => {

    let language = localStorage.getItem(StorageLocalUserProfileLanguageKey);

    if (!language)
        language = navigator?.language;

    if (language && language.length > 1 && language.substring(0, 2) === 'fr')
        return 'fr';

    return 'en';
}

const LocalUserProfileContextProvider: (props: ILocalUserProfileContextProviderProps) => JSX.Element = (props) => {

    const [localUserProfileLanguage, setLocalUserProfileLanguage] = useState(getLocalUserProfileLanguage());

    const memoLocalUserProfileContextValue = useMemo<ILocalUserProfileContext>(() => ({
        language: localUserProfileLanguage,
        changeUserProfile: (language) => {
            setLocalUserProfileLanguage(language);
            localStorage.setItem(StorageLocalUserProfileLanguageKey, language);
        }
    }), [localUserProfileLanguage, setLocalUserProfileLanguage]);

    return (
        <LocalUserProfileContext.Provider value={memoLocalUserProfileContextValue}>
            {props.children}
        </LocalUserProfileContext.Provider>);
}

export default LocalUserProfileContextProvider;