import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { useHistory } from 'react-router-dom'

import PropertyMgmtMobileAppContextProvider from './PropertyMgmtMobileAppContextProvider';
import LocalUserProfileContextProvider from "./components/LocalUserProfileContextProvider/LocalUserProfileContextProvider";
import PropertyMgmtMobileApp from "./PropertyMgmtMobileApp";
import propertyMgmtApiScopes from "./propertyMgmtApiScopes";
import VisibilityStateAuthHandler from "./components/VisibilityStateAuthHandler/VisibilityStateAuthHandler";

const App = () => {

  if (process.env.REACT_APP_SENTRY_URL) {
    if (!process.env.REACT_APP_SENTRY_ENV)
      console.error('Environment variable REACT_APP_SENTRY_ENV must be set.')
    else
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing(),
        new CaptureConsoleIntegration(
          {
            // array of methods that should be captured
            // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ['warn', 'error']
          }
        )
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_SENTRY_ENV
      });
  }

  const loginCallbackPath = '/login/callback';

  const oktaAuthConfig = {
    issuer: `${process.env.REACT_APP_OKTA_ISSUER}`,
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    redirectUri: window.location.origin + loginCallbackPath,
    scopes: [
      'openid',
      'profile',
      ...propertyMgmtApiScopes
    ],
  }

  const oktaAuth = new OktaAuth(oktaAuthConfig)

  const history = useHistory()

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ): Promise<void> => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <VisibilityStateAuthHandler>
        <PropertyMgmtMobileAppContextProvider>
          <LocalUserProfileContextProvider>
            <PropertyMgmtMobileApp loginCallbackRoutePath={loginCallbackPath} />
          </LocalUserProfileContextProvider>
        </PropertyMgmtMobileAppContextProvider>
      </VisibilityStateAuthHandler>
    </Security>);
}

export default App