import IApiClient from '../../../utilities/IApiClient';

export interface IPropertyMgmtOrg {
  id: string;
  features: string[];
}

const propertyMgmtOrgsAdapter = {

  getFeatures: (apiClient: IApiClient, id: string) => apiClient.get<IPropertyMgmtOrg>('/PropertyMgmtOrgs/' + id)

};

export default propertyMgmtOrgsAdapter;
