const logManager = {

  logDebug: (debug: any) => {
    console.debug(debug);
  },

  logInfo: (info: any) => {
    console.info(info);
  },

  logWarning: (warning: any) => {
    console.warn(warning);
  },

  logError: (error: any) => {
    console.error(error)
  }
}

export default logManager;