import { useContext, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';

import useApiClient from '../../utilities/hooks/useApiClient';
import useTranslator from '../../utilities/hooks/useTranslator';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';

import specialtyAdapter, { ISpecialty } from './Adapters/specialtyAdapter';

type SpecialtyParams = { specialtyid: string }

const specialtyDict = {
  title: { en: 'Specialty', fr: 'Spécialité' },
  name: { en: 'Name', fr: 'Nom' },
  description: { en: 'Description', fr: 'Description' },
};

const Specialty = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const apiClient = useApiClient();
  const history = useSafeHistory()
  const translate = useTranslator();

  const {specialtyid} = useParams<SpecialtyParams>();
  const [specialty, setSpecialty] = useState<ISpecialty | undefined>(undefined);
  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    specialtyAdapter
      .getSpecialty(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, specialtyid)
      .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => { 
                        setSpecialty(value); 
                        setViewLoadingState(LoadingState.None);}))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const specialtyUpdate = () => {
    history.push(`/specialtyupdate/${specialtyid}`);
  };

  useSafeAsyncOperations(getAllViewData);

  return (
    <ViewLayout contentBoxPadding={2} viewTitle={translate(specialtyDict.title)} loadingState={viewLoadingState} fab={{iconName: 'edit', fabAction: specialtyUpdate}} isChildView={true}>
      <Box sx={{mb:2}} display="flex" alignItems="baseline">
        <Typography variant="body2" flexBasis={(theme) => theme.spacing(12)} flexShrink={0}>
          {translate(specialtyDict.name)} :
        </Typography>
        <Typography variant="h6" data-cy="Name">
          {specialty?.name}
        </Typography>
      </Box>
      <Box display="flex" alignItems="baseline">
        <Typography variant="body2" flexBasis={(theme) => theme.spacing(12)} flexShrink={0}>
          {translate(specialtyDict.description)} :
        </Typography>
        <Typography variant="body2" data-cy="Description">
          {specialty?.description}
        </Typography>
      </Box>
    </ViewLayout>
  );
};
  
export default Specialty;