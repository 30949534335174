import { useContext } from 'react';
import { useHistory } from 'react-router';
import * as H from 'history';

import SafeHistoryContext, { ISafeHistoryContext } from '../SafeHistoryContext';

export const buildSafeHistoryFormContext = (safeHistoryContext: ISafeHistoryContext, histo: H.History<H.LocationState>) => {

    const isCurrentViewFirstViewAndChildView = () => histo.location.key === safeHistoryContext.firstViewLocationKey && !!safeHistoryContext.firstViewIsChildView;

    return {
        IsCurrentViewFirstViewAndChildView: isCurrentViewFirstViewAndChildView,
        goBack: () => {
            if (safeHistoryContext.currentUnblockCbk) safeHistoryContext.currentUnblockCbk();

            if (isCurrentViewFirstViewAndChildView())
                histo.push('/');
            else
                histo.goBack();
        },
        push: (location: string) => {
            if (safeHistoryContext.currentUnblockCbk) safeHistoryContext.currentUnblockCbk();

            histo.push(location);
        }
    }
}

const useSafeHistory = () => {

    const safeHistoryContext = useContext(SafeHistoryContext);

    const histo = useHistory();

    return buildSafeHistoryFormContext(safeHistoryContext, histo);
}

export default useSafeHistory 