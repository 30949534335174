import React, { memo } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import { useLocation } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

interface ICustomSwipeableDrawerProps {
    appTitle: string,
    drawerNavLinks?: {
        label: string
        iconName: string,
        url: string
    }[],
    historyPush: (locationPath: string) => void,
    drawerIsOpened: boolean,
    setDrawerIsOpened: (drawerIsOpened: boolean) => void
}

const CustomSwipeableDrawer: (props: ICustomSwipeableDrawerProps) => JSX.Element = (props) => {

    const location = useLocation();

    const drawerButtonClick = (url: string) => {
        props.historyPush(url);
        props.setDrawerIsOpened(false);
    };

    return (<React.Fragment>
        {props.drawerNavLinks && props.drawerNavLinks.length > 0 && <SwipeableDrawer anchor='left' open={props.drawerIsOpened} disableSwipeToOpen={true} disableDiscovery={true} onOpen={() => { }} onClose={() => props.setDrawerIsOpened(false)} PaperProps={{ sx: { width: 'Calc(100% - 56px)', maxWidth: 'sm' } }} >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} data-cy='AppBarTitle'>
                    {props.appTitle}
                </Typography>
            </Toolbar>
            <Divider />
            <List>
                {props.drawerNavLinks.map((drawerNavLink) => (
                    <ListItem key={drawerNavLink.label} disablePadding sx={{ paddingTop: '4px', paddingBottom: '4px', pl: 1, pr: 1 }}>
                        <ListItemButton sx={{
                            borderRadius: '4px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            '&.Mui-selected': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
                            '&.Mui-selected.Mui-focusVisible': { backgroundColor: 'rgba(0, 0, 0, 0.12)' },
                            '&.Mui-selected:hover': { backgroundColor: 'rgba(0, 0, 0, 0.12)' }
                        }}
                            selected={location.pathname === drawerNavLink.url}
                            onClick={() => drawerButtonClick(drawerNavLink.url)}>
                            <ListItemIcon>
                                <Icon>{drawerNavLink.iconName}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={drawerNavLink.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </SwipeableDrawer>
        }</React.Fragment>);
}

export default memo(CustomSwipeableDrawer, (prevProps, nextProps) => {

    if (prevProps.drawerNavLinks && nextProps.drawerNavLinks)
        for (let i = 0; i < prevProps.drawerNavLinks.length; i++)
            if (prevProps.drawerNavLinks[i].iconName !== nextProps.drawerNavLinks[i].iconName
                || prevProps.drawerNavLinks[i].label !== nextProps.drawerNavLinks[i].label
                || prevProps.drawerNavLinks[i].url !== nextProps.drawerNavLinks[i].url)
                return false;

    return (prevProps.drawerIsOpened === nextProps.drawerIsOpened && prevProps.setDrawerIsOpened === nextProps.setDrawerIsOpened && !prevProps.drawerNavLinks === !nextProps.drawerNavLinks);
});