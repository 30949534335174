import IApiClient from '../../../utilities/IApiClient';

export interface IEmployee {
  id: string;
  uniqueUserId: string;
  firstName: string;
  lastName: string;
  email: string;
  phones: string[];
  isManager: boolean;
}

const employeesAdapter = {

  getAllEmployees: (apiClient: IApiClient, propertyMgmtId: string) => apiClient.get<IEmployee[]>('/Employees/' + propertyMgmtId)

};

export default employeesAdapter;
