import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useTranslator from '../../utilities/hooks/useTranslator';

interface IUnexpectedErrorProps {
    unexpectedErrorReload?: () => void,
}

const UnexpectedErrorContent = (props: IUnexpectedErrorProps) => {

    const viewUnexpectedLoadingErrorDict = {
        errorTitle: { fr: 'Quelque chose a mal tourné.', en: 'Something went wrong' },
        errorText: { fr: 'Une erreur inattendue s\'est produite.', en: 'An unexpected error occured.' },
        reloadButtonText: { fr: 'Recharger', en: 'Reload' }
    };

    const translate = useTranslator();

    return <Box sx={{ marginTop: 2, marginLeft: 2, marginBottom: 1 }}>
        <Typography variant='h5' paragraph={true}><Icon fontSize='large' sx={{ marginRight: 1, verticalAlign: (theme) => '-' + theme.spacing(1) }}>error</Icon>{translate(viewUnexpectedLoadingErrorDict.errorTitle)}</Typography>
        <Typography variant='body1' paragraph={true}>{translate(viewUnexpectedLoadingErrorDict.errorText)}</Typography>
        {props.unexpectedErrorReload && <Button variant='contained' color='secondary' onClick={props.unexpectedErrorReload}>{translate(viewUnexpectedLoadingErrorDict.reloadButtonText)}</Button>}
    </Box>;
}

export default UnexpectedErrorContent;