import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { parseApiDate } from '../../../utilities/apiDates';
import CommonCardContent from './CommonCardContent';
import LoadingState from '../../../components/LoadingState';
import useApiClient from '../../../utilities/hooks/useApiClient';
import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import ConfirmRefusalDialog from './ConfirmRefusalDialog';

export interface IInProgressTabContentProps {
  allReservations: IReservation[] | undefined;
  allEmployees: IEmployee[] | undefined;
  propertyMgmtOrg: IPropertyMgmtOrg | undefined;
  safeSetViewLoadingState: (loadingState: LoadingState) => void;
  refreshSingleReservationInList: (reservation: IReservation) => void;
  safeSetUserMessageContent: (messageContent: string) => void;
}

const InProgressTabContentDict = {
  inProgress: { en: 'In progress', fr: 'En cours' },
  tempStopButton: { en: 'Temp. Stop', fr: 'Arrêt Temp.' },
  setToDoneButton: { en: 'Complete', fr: 'Terminer' },
  setToRefuseButton: { en: 'Refuse', fr: 'Refuser' },
  confirmationConcurrencyError: {
    en: 'Reservation for {0} has been modified by another user. Employee cannot be confirmed. Please refresh view and retry if needed.',
    fr: "La réservation pour {0} a été modifiée par un autre utilisateurs. L'employé ne peut être confirmer. SVP rafraîchir et réessayer si nécessaire.",
  },
  confirmationInternalError: {
    en: 'Internal error while trying to confirm for {0}. Please refresh and retry if needed.',
    fr: "Erreur interne lors de la confirmation de l'pour {0}. SVP rafraîchir et réessayer si nécessaire.",
  },
  InProgressNoReservationMsg: {
    en: 'There is no cleaning job in progress.',
    fr: "Aucune job d'entretien en cours.",
  },
};

const InProgressTabContent = (props: IInProgressTabContentProps) => {
  const translate = useTranslator();
  const apiClient = useApiClient();
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const propertyMgmtOrgId = props.propertyMgmtOrg?.id!;

  const [confirmRefusalDlgOpenInfo, setConfirmRefusalDlgOpenInfo] = useState<{ open: boolean; reservation: IReservation } | undefined>(undefined);


  const renderNow = new Date();

  const toDoReservation = props.allReservations?.filter((value) => value.cleaningJob.status === 'InProgress');

  toDoReservation?.sort((a, b) => parseApiDate(a.startDate).getTime() - parseApiDate(b.startDate).getTime());

  const isInWarningState = (reservation: IReservation) =>
    !!reservation.nextReservation?.startDate && (parseApiDate(reservation.nextReservation.startDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.shouldHaveBeenCompletedTimeOut ?? 0);

  const isInUrgentState = (reservation: IReservation) =>
    !!reservation.nextReservation?.startDate && (parseApiDate(reservation.nextReservation.startDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.mustHaveBeenCompletedTimeOut ?? 0);

  const tempStopEmployeeOnCleaningJob = (reservation: IReservation) => {
    reservationsAdapter
      .tempStopEmployeeOnCleaningJob(apiClient, propertyMgmtOrgId, reservation.id, reservation.aggregateTimeStamp, { targetEmployeeUniqueUserId: propertyMgmtContext.uniqueUserId! }, true)
      .then((value) => {
        props.refreshSingleReservationInList(value);
      })
      .catch((error: any) => {
        if (isApiConcurrencyError(error)) props.safeSetUserMessageContent(translate(InProgressTabContentDict.confirmationConcurrencyError, [reservation.rentalUnit.name]));
        else props.safeSetUserMessageContent(translate(InProgressTabContentDict.confirmationInternalError, [reservation.rentalUnit.name]));
      })
      .finally(() => props.safeSetViewLoadingState(LoadingState.None));
  };

  const employeeCompletesCleaningJob = (reservation: IReservation) => {
    reservationsAdapter
      .employeeCompletesCleaningJob(
        apiClient,
        propertyMgmtOrgId,
        reservation.id,
        reservation.aggregateTimeStamp,
        {
          targetEmployeeUniqueUserId: propertyMgmtContext.uniqueUserId!,
        },
        true
      )
      .then((value) => {
        props.refreshSingleReservationInList(value);
      })
      .catch((error: any) => {
        if (isApiConcurrencyError(error)) props.safeSetUserMessageContent(translate(InProgressTabContentDict.confirmationConcurrencyError, [reservation.rentalUnit.name]));
        else props.safeSetUserMessageContent(translate(InProgressTabContentDict.confirmationInternalError, [reservation.rentalUnit.name]));
      })
      .finally(() => props.safeSetViewLoadingState(LoadingState.None));
  };

  return !(props.allReservations && props.allEmployees && props.propertyMgmtOrg) ? null : (
    <React.Fragment>
      {toDoReservation?.map((reservation, index) => (
        <Card key={reservation.id} sx={{ mb: 1 }} data-cy={'InProgressCard_' + index.toString()}>
          <CommonCardContent
            reservation={reservation}
            isInUrgentState={isInUrgentState(reservation)}
            isInWarningState={isInWarningState(reservation)}
            cardStateDescription={translate(InProgressTabContentDict.inProgress)}
            allEmployees={props.allEmployees as IEmployee[]}
          />
          <CardActions disableSpacing={true}>
            <Button color="secondary" onClick={() => tempStopEmployeeOnCleaningJob(reservation)} data-cy="CardTempStopAction" sx={{ ml: 1 }}>
              {translate(InProgressTabContentDict.tempStopButton)}
            </Button>
            <Button color="secondary" onClick={() => employeeCompletesCleaningJob(reservation)} data-cy="CardCompleteAction">
              {translate(InProgressTabContentDict.setToDoneButton)}
            </Button>
            <Button color="secondary" onClick={() => setConfirmRefusalDlgOpenInfo({ open: true, reservation: reservation })} sx={{ ml: 1 }} data-cy="CardRefuseAction">
              {translate(InProgressTabContentDict.setToRefuseButton)}
            </Button>
          </CardActions>
        </Card>
      ))}
      {toDoReservation?.length === 0 && (
        <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} m={1} fontStyle={'italic'} data-cy="InProgressNoReservationMsg">
          {translate(InProgressTabContentDict.InProgressNoReservationMsg)}
        </Typography>
      )}
      <ConfirmRefusalDialog
        openInfo={confirmRefusalDlgOpenInfo}
        setOpenInfo={setConfirmRefusalDlgOpenInfo}
        propertyMgmtOrg={props.propertyMgmtOrg}
        safeSetViewLoadingState={props.safeSetViewLoadingState}
        refreshSingleReservationInList={props.refreshSingleReservationInList}
        safeSetUserMessageContent={props.safeSetUserMessageContent}
      />
    </React.Fragment>
  );
};

export default InProgressTabContent;
