import { useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import useTranslator from '../../utilities/hooks/useTranslator';
import useViewNavUXStateStack from '../../utilities/hooks/useViewNavUXStateStack';
import employeesAdapter, { IEmployee } from './Adapters/employeesAdapter';
import EmployeeList from './Components/EmployeeList';

const employeesDict = {
  employee: { en: 'Employees', fr: 'Employés' },
}

const Employees = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const apiClient = useApiClient();

  const history = useSafeHistory();

  const translate = useTranslator();

  const [employeesNavUXState, pushEmployeesNavUXState] = useViewNavUXStateStack<number>();

  const [employeesListContent, setEmployeesListContent] = useState<IEmployee[] | undefined>(undefined);

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

  const [viewFullDataLoadTimeStamp, setViewFullDataLoadTimeStamp] = useState<number | undefined>(undefined);

  const viewScrollInfo = useRef(employeesNavUXState ? employeesNavUXState : 0);

  useEffect(() => {

    window.scrollTo(0, viewScrollInfo.current);
  }, [viewFullDataLoadTimeStamp]);

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);
    employeesAdapter
      .getAllEmployees(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
       .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => { 
        setEmployeesListContent(value);
        setViewLoadingState(LoadingState.None); 
        setViewFullDataLoadTimeStamp(Date.now); 
      }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const employeeCreate = () => {
    history.push(`/employeecreate`);

    viewScrollInfo.current = window.scrollY;
    pushEmployeesNavUXState(viewScrollInfo.current);

  };

  const gotoEmployee = (employeeId: string) => {
    history.push(`/employee/${employeeId}`);

    viewScrollInfo.current = window.scrollY;
    pushEmployeesNavUXState(viewScrollInfo.current);

  };

  const unexpectedLoadingErrorReload = () => {
    viewScrollInfo.current = 0;
    getAllViewData(shouldSetStatesBeCancelledFn);
  };

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  return (
    <ViewLayout viewTitle={translate(employeesDict.employee)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} fab={{ iconName: 'add', fabAction: employeeCreate }}>
      <EmployeeList allEmployees={employeesListContent!} gotoEmployee={gotoEmployee} />
    </ViewLayout>
  );
};

export default Employees;
