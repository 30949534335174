const RentalUnitUpdateDict = {
    title: { en: 'Update Rental Unit', fr: 'Modifier une unité de location' },
    name: { en: 'Name', fr: 'Nom' },
    required: { en: '*Required', fr: '*Requis' },
    civicNumber: { en: 'Civic Number', fr: 'Numéro Civique' },
    street: { en: 'Street', fr: 'Rue' },
    city: { en: 'City', fr: 'Ville' },
    province: { en: 'Province', fr: 'Province' },
    manager: { en: 'Manager', fr: 'Manager' },
    email: { en: 'Email:', fr: 'Courriel:' },
    phone: { en: 'Phone {0}:', fr: 'Téléphone {0}:' },
    notes: { en: 'Notes', fr: 'Notes' },
    cancel: { en: 'CANCEL', fr: 'ANNULER' },
    create: { en: 'CREATE', fr: 'CRÉER' },
    invalidFormat: { en: 'Format not valid', fr: `Format non valide` },
    deactivate: { en: 'DEACTIVATE', fr: 'DÉSACTIVER' },
    update: { en: 'UPDATE', fr: 'MODIFIER' },
    none: { en: 'None', fr: 'Aucun' },
    warning: { en: 'Warning: some of the fields are locked because this rental unit is managed by {0}', fr: 'Attention: certains champs sont verrouillés car cette unité est gérée par {0}' },
    updationConcurrencyError: { en: "Rental Unit has been modified by another user. These changes cannot be applied. Please cancel your changes.", fr: "L'unité de location a été modifiée par un autre utilisateur. Ces modifications ne peuvent pas être appliquées. Veuillez annuler vos modifications." },
    updationInternalError: { en: "Internal error while trying to update the Rental Unit. Please retry.", fr: "Erreur interne lors de la tentative de mise à jour de l'unité de location. Veuillez réessayer." },
    managerMustExistErrorMessage: { en: 'The manager does not exist anymore. Please check or leave the manager empty.', fr: `Le gestionnaire n'existe plus. SVP vérifiez ou laissez la donnée vide.` },
};

export default RentalUnitUpdateDict;