import React from 'react';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { IReservation, ICleaningJobEmployee } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import useLocaleFormater from '../../../utilities/hooks/useLocaleFormater';
import { parseApiDate } from '../../../utilities/apiDates';

export interface ICommonCardContentProps {
    reservation: IReservation,
    cardStateDescription: string,
    isInWarningState: boolean,
    isInUrgentState: boolean,
    allEmployees: IEmployee[]
}

const commonCardContentDict = {
    availableLabel: { en: 'Free in {0}d {1}h ({2})', fr: 'Libre dans {0}j {1}h ({2})' },
    availablePastLabel: { en: 'Guests left {0}d {1}h ago ({2})', fr: 'Invités partis depuis {0}j {1}h ({2})' },
    nextArrivalLabel: { en: 'Next arrival in {0}d {1}h ({2})', fr: 'Pr. arrivée dans {0}j {1}h ({2})' },
    nextArrivalPastLabel: { en: 'Next arr. was {0}d {1}h ago ({2})', fr: 'Pr. arrivée passée depuis {0}j {1}h ({2})' },
    noNextArrivalLabel: { en: 'No upcoming arrival scheduled ', fr: 'Aucune arrivée n\'est prévue prochainement' },
    noAgentAssignedLabel: { en: 'No agent has been assigned', fr: 'Aucun agent n\'a été assigné' },
    notConfirmedEmployeeState: { en: 'NOT CONFIRMED', fr: 'NON CONFIRMÉ' },
    confirmedEmployeeState: { en: 'CONFIRMED', fr: 'CONFIRMÉ' },
    inProgressEmployeeState: { en: 'IN PROGRESS', fr: 'EN COURS' },
    doneEmployeeState: { en: 'DONE', fr: 'TERMINÉ' }
}

const getDayHourDiff = (dateValue1: Date, dateValue2: Date, floorRounding: boolean) => {

    const roundingMethod = floorRounding ? Math.floor : Math.ceil;

    const diffInMs = Math.abs(dateValue1.getTime() - dateValue2.getTime());

    const hours = roundingMethod(diffInMs / 1000 / 60 / 60);

    return { days: Math.floor(hours / 24), hours: hours - (Math.floor(hours / 24) * 24) }
}

const CommonCardContent = (props: ICommonCardContentProps) => {

    const translate = useTranslator();

    const formater = useLocaleFormater();

    const renderNow = new Date();

    const isDateInThePast = (dateString: string) => parseApiDate(dateString).getTime() < renderNow.getTime();

    const buildDateDiffLabelParamArray = (dateString: string, floorRounding: boolean) => {

        const dateValue = parseApiDate(dateString);

        const dayHourDiff = getDayHourDiff(dateValue, renderNow, floorRounding);

        return [dayHourDiff.days.toString(), dayHourDiff.hours.toString(), formater.formatDate(dateValue, { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })];
    }

    const getFormattedEmployeeNameAndState = (cleaningJobEmployee: ICleaningJobEmployee) => {

        const employee = props.allEmployees.find((employee) => employee.id === cleaningJobEmployee.id);

        return employee?.lastName + ' ' + employee?.firstName + ' : ' + (
            cleaningJobEmployee.status === 'Assigned' ? translate(commonCardContentDict.notConfirmedEmployeeState) :
                cleaningJobEmployee.status === 'Confirmed' ? translate(commonCardContentDict.confirmedEmployeeState) :
                    cleaningJobEmployee.status === 'Working' ? translate(commonCardContentDict.inProgressEmployeeState) :
                        cleaningJobEmployee.status === 'Done' ? translate(commonCardContentDict.doneEmployeeState) : '');
    }

    const reservationActivesEmployees = props.reservation.cleaningJob.employees.filter((employee) => employee.status === 'Assigned' || employee.status === 'Confirmed' || employee.status === 'Working' || employee.status === 'Done');

    const buildNameAddressSection = (reservation: IReservation) =>
        <React.Fragment>
            <Typography variant='h6' component="h6" data-cy='CardRentalUnitName'>{reservation.rentalUnit.name}</Typography>
            <Typography variant="subtitle2" component="p" mb={1} data-cy='CardRentalUnitAddress'>{reservation.rentalUnit.civicNumber}, {reservation.rentalUnit.street}, {reservation.rentalUnit.city}</Typography>
        </React.Fragment>;

    return <CardContent>
        {(props.isInWarningState || props.isInUrgentState) ?
            <Box display='flex' flexDirection='row' alignItems='start' >
                <Box sx={{ flexGrow: 0, flexShrink: 0, padding: 1, backgroundColor: props.isInUrgentState ? 'primary.dark' : 'primary.light', mr: 1, borderRadius: (theme) => theme.shape.borderRadius + 'px', lineHeight: 1 }}>
                    <Icon fontSize='large' sx={{ color: 'primary.contrastText' }} data-cy='CardStatusIcon'>{props.isInUrgentState ? 'error' : 'warning'}</Icon>
                </Box>
                <Box>{buildNameAddressSection(props.reservation)}</Box>
            </Box> :
            buildNameAddressSection(props.reservation)}
        <Typography variant="body2" component="p" data-cy='CardRentalUnitNextFreeTime'>{translate(isDateInThePast(props.reservation.endDate) ? commonCardContentDict.availablePastLabel : commonCardContentDict.availableLabel, buildDateDiffLabelParamArray(props.reservation.endDate, isDateInThePast(props.reservation.endDate)))}</Typography>
        <Typography variant="body2" component="p" mb={1} data-cy='CardRentalUnitNextArrivalTime'>{props.reservation.nextReservation ? translate(isDateInThePast(props.reservation.nextReservation.startDate) ? commonCardContentDict.nextArrivalPastLabel : commonCardContentDict.nextArrivalLabel, buildDateDiffLabelParamArray(props.reservation.nextReservation.startDate, true)) : translate(commonCardContentDict.noNextArrivalLabel)}</Typography>
        {reservationActivesEmployees.length === 0 ?
            <Typography variant="body2" component="p" mb={1} data-cy='CardNoAgentAssigned'>{translate(commonCardContentDict.noAgentAssignedLabel)}</Typography>
            : reservationActivesEmployees.map((employee, index) =>
                <Typography key={employee.id} variant="body2" component="p" mb={index === (reservationActivesEmployees.length - 1) ? 1 : undefined} data-cy={'CardRentalUnitCleaningTeamEmployee_' + index.toString()}>
                    {getFormattedEmployeeNameAndState(employee)}
                </Typography>)}
        <Typography variant="body2" component="p" data-cy='CardRentalUnitCleaningJobStatus'>{props.cardStateDescription}</Typography>
    </CardContent>;
};

export default CommonCardContent