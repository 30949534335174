import { ReactNode, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import AppLayoutContext from '../AppLayout/AppLayoutContext';
import LoadingState from '../LoadingState';
import ViewFab from '../internals/ViewFab';
import UserMessagePane from '../internals/UserMessagePane';
import IUserMessage from '../IUserMessage';
import UnexpectedErrorContent from '../internals/UnexpectedErrorContent';
import logManager from '../../utilities/logManager';

interface IViewLayoutProps {
    viewTitle?: string,
    isChildView?: boolean,
    loadingState?: LoadingState,
    fab?: { iconName: string, fabAction: () => void, disabled?: boolean },
    userMessageInfo?: { userMessage: IUserMessage | undefined, setUserMessage: (message: IUserMessage | undefined) => void },
    unexpectedLoadingErrorReload?: () => void,
    contentBoxPadding?: number | string,
    contentBoxPaddingTop?: number | string,
    contentBoxPaddingRight?: number | string,
    contentBoxPaddingBottom?: number | string,
    contentBoxPaddingLeft?: number | string,
    childViewBackOverride?: () => void,
    children?: ReactNode
}

const ViewLayout = (props: IViewLayoutProps) => {

    const appLayoutCtx = useContext(AppLayoutContext);

    const isLoading = props.loadingState && props.loadingState !== LoadingState.None && props.loadingState !== LoadingState.UnexpectedLoadingError;

    const [isContentVisibleWithCurrentViewInfo, setIsContentVisibleWithCurrentViewInfo] = useState(false);

    const invisibleContent = props.loadingState === LoadingState.NotModalWithInvisibleContent || props.loadingState === LoadingState.ModalWithInvisibleContent || props.loadingState === LoadingState.UnexpectedLoadingError;

    if (!props.isChildView && props.childViewBackOverride)
        logManager.logError('Prop childViewBackOverride can only be set when isChildView is set to true.');

    useEffect(() => {
        appLayoutCtx.setCurrentViewInfo(props.viewTitle, props.isChildView, isLoading, props.childViewBackOverride);
        setIsContentVisibleWithCurrentViewInfo(!invisibleContent); //Delayed content visibility (content is shown after the viewInfo is set)
    },
        [appLayoutCtx, props.viewTitle, props.isChildView, isLoading, props.childViewBackOverride, invisibleContent]
    );

    if (appLayoutCtx.invalidAppContext) {

        logManager.logError('ViewLayout rendered with invalid context.');
        return <UnexpectedErrorContent />;
    }

    return (
        <Box>
            {<Modal open={props.loadingState === LoadingState.ModalWithVisibleContent || props.loadingState === LoadingState.ModalWithInvisibleContent} aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,.32)', zIndex: (theme) => theme.zIndex.drawer + 1, width: '100%' } }}
                disableEscapeKeyDown={true}
                closeAfterTransition={true}><Fade in={props.loadingState === LoadingState.ModalWithVisibleContent || props.loadingState === LoadingState.ModalWithInvisibleContent}><Box></Box></Fade></Modal>}
            <Box display={invisibleContent ? 'none' : undefined}
                visibility={!invisibleContent && !isContentVisibleWithCurrentViewInfo ? 'hidden' : undefined}
                p={props.contentBoxPadding ? props.contentBoxPadding : undefined}
                pt={props.contentBoxPaddingTop ? props.contentBoxPaddingTop : undefined}
                pr={props.contentBoxPaddingRight ? props.contentBoxPaddingRight : undefined}
                pb={props.contentBoxPaddingBottom ? props.contentBoxPaddingBottom : undefined}
                pl={props.contentBoxPaddingLeft ? props.contentBoxPaddingLeft : undefined}>
                {props.children}
            </Box>
            {props.loadingState === LoadingState.UnexpectedLoadingError && <UnexpectedErrorContent unexpectedErrorReload={props.unexpectedLoadingErrorReload} />}
            {props.userMessageInfo && <UserMessagePane userMessage={props.userMessageInfo.userMessage} setUserMessage={props.userMessageInfo.setUserMessage} isInChildView={!!props.isChildView} isInViewWithFab={!!props.fab} />}
            {(isContentVisibleWithCurrentViewInfo && props.fab) &&
                <ViewFab iconName={props.fab.iconName} fabAction={props.fab.fabAction} isInChildView={!!props.isChildView} disabled={props.fab.disabled} />}
        </Box>
    );
};

export default ViewLayout;