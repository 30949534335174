import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { IReservation } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { parseApiDate } from '../../../utilities/apiDates';
import CommonCardContent from './CommonCardContent';
import LoadingState from '../../../components/LoadingState';
import RestartDialog from './RestartDialog';

export interface IDoneTabContentProps {
    allReservations: IReservation[] | undefined,
    allEmployees: IEmployee[] | undefined,
    propertyMgmtOrg: IPropertyMgmtOrg | undefined,
    safeSetViewLoadingState: (loadingState: LoadingState) => void,
    refreshSingleReservationInList: (reservation: IReservation) => void,
    safeSetUserMessageContent: (messageContent: string) => void
}

const DoneTabContentDict = {
    workIsComplete: { en: 'Work is complete', fr: 'Travail terminé' },
    restartButton: { en: 'Start over', fr: 'Redémarrer' },
    DoneNoReservationMsg: { en: 'There is no cleaning job completed in the last {0} hours.', fr: 'Aucune job d\'entretien complétée dans les dernières {0} heures.' }
};

const DoneTabContent = (props: IDoneTabContentProps) => {

    const translate = useTranslator();

    const [restartDlgOpenInfo, setRestartDlgOpenInfo] = useState<{ open: boolean, reservation: IReservation } | undefined>(undefined);

    const doneReservations = props.allReservations?.filter((value) => value.cleaningJob.status === 'Done');

    doneReservations?.sort((a, b) => parseApiDate(b.startDate).getTime() - parseApiDate(a.startDate).getTime());

    return !(props.allReservations && props.allEmployees && props.propertyMgmtOrg) ? null :
        <React.Fragment>
            {doneReservations?.map((reservation, index) => <Card key={reservation.id} sx={{ mb: 1 }} data-cy={'DoneCard_' + index.toString()}>
                <CommonCardContent
                    reservation={reservation}
                    isInUrgentState={false}
                    isInWarningState={false}
                    cardStateDescription={translate(DoneTabContentDict.workIsComplete)}
                    allEmployees={props.allEmployees as IEmployee[]} />
                <CardActions disableSpacing={true}>
                    <Button color='secondary' onClick={() => setRestartDlgOpenInfo({ open: true, reservation: reservation })} data-cy='CardStartOverAction'>{translate(DoneTabContentDict.restartButton)}</Button>
                </CardActions>
            </Card>)}
            {doneReservations?.length === 0 && <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} m={1} fontStyle={'italic'} data-cy='DoneNoReservationMsg'>{translate(DoneTabContentDict.DoneNoReservationMsg, [props.propertyMgmtOrg.cleaningJobSettings.rearViewTimeSpan.toString()])}</Typography>}
            <RestartDialog openInfo={restartDlgOpenInfo}
                setOpenInfo={setRestartDlgOpenInfo}
                allEmployees={props.allEmployees}
                propertyMgmtOrg={props.propertyMgmtOrg}
                safeSetViewLoadingState={props.safeSetViewLoadingState}
                refreshSingleReservationInList={props.refreshSingleReservationInList}
                safeSetUserMessageContent={props.safeSetUserMessageContent} />
        </React.Fragment >;
};

export default DoneTabContent