import IApiClient from '../../../utilities/IApiClient';

export interface ISpecialty {
  name: string;
  description: string;
}

const specialtyAdapter = {
  createSpecialty: (apiClient: IApiClient, propertyMgmtId: string, specialty: ISpecialty) => apiClient.post('/Specialties/' + propertyMgmtId, specialty),
};

export default specialtyAdapter;
