import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import { IReservation } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { parseApiDate } from '../../../utilities/apiDates';
import CommonCardContent from './CommonCardContent';
import LoadingState from '../../../components/LoadingState';
import StartDialog from './StartDialog';
import RemoveDialog from './RemoveDialog';
import StopDialog from './StopDialog';
import CompleteDialog from './CompleteDialog';
import CommonActionMenu from './CommonActionMenu';
import RestartDialog from './RestartDialog';

export interface IToDoTabContentProps {
    allReservations: IReservation[] | undefined,
    allEmployees: IEmployee[] | undefined,
    propertyMgmtOrg: IPropertyMgmtOrg | undefined,
    safeSetViewLoadingState: (loadingState: LoadingState) => void,
    refreshSingleReservationInList: (reservation: IReservation) => void,
    safeSetUserMessageContent: (messageContent: string) => void
}

const ToDoTabContentDict = {
    waitingToBeStarted: { en: 'Waiting to be started', fr: 'En attente de démarrage' },
    startButton: { en: 'Start', fr: 'Démarrer' },
    removeButton: { en: 'Remove', fr: 'Retirer' },
    ToDoNoReservationMsg: { en: 'There is no cleaning job to do.', fr: 'Aucune job d\'entretien à faire.' }
};

const ToDoTabContent = (props: IToDoTabContentProps) => {

    const translate = useTranslator();

    const [startDlgOpenInfo, setStartDlgOpenInfo] = useState<{ open: boolean, reservation: IReservation } | undefined>(undefined);
    const [stopDlgOpenInfo, setStopDlgOpenInfo] = useState<{ open: boolean, reservation: IReservation } | undefined>(undefined);
    const [completeDlgOpenInfo, setCompleteDlgOpenInfo] = useState<{ open: boolean, reservation: IReservation } | undefined>(undefined);
    const [restartDlgOpenInfo, setRestartDlgOpenInfo] = useState<{ open: boolean, reservation: IReservation } | undefined>(undefined);
    const [removeDlgOpenInfo, setRemoveDlgOpenInfo] = useState<{ open: boolean, reservation: IReservation } | undefined>(undefined);

    const [cardMenuOpen, setCardMenuOpen] = useState(false);
    const [cardMenuAnchorEl, setCardMenuAnchorEl] = useState<undefined | HTMLElement>(undefined);
    const [cardMenuReservation, setCardMenuReservation] = useState<undefined | IReservation>(undefined);

    const renderNow = new Date();

    const toDoReservation = props.allReservations?.filter((value) => value.cleaningJob.status === 'ToDo');

    toDoReservation?.sort((a, b) => parseApiDate(a.startDate).getTime() - parseApiDate(b.startDate).getTime());

    const isInWarningState = (reservation: IReservation) => !!(reservation.nextReservation?.startDate) &&
        ((parseApiDate(reservation.nextReservation.startDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60) < (props.propertyMgmtOrg?.cleaningJobSettings.shouldHaveStartedTimeOut ?? 0);

    const isInUrgentState = (reservation: IReservation) => !!(reservation.nextReservation?.startDate) &&
        ((parseApiDate(reservation.nextReservation.startDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60) < (props.propertyMgmtOrg?.cleaningJobSettings.mustHaveStartedTimeOut ?? 0);

    const handleCardMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, reservation: IReservation) => {
        setCardMenuReservation(reservation)
        setCardMenuAnchorEl(event.currentTarget);
        setCardMenuOpen(true);
    };

    return !(props.allReservations && props.allEmployees && props.propertyMgmtOrg) ? null :
        <React.Fragment>
            {toDoReservation?.map((reservation, index) => <Card key={reservation.id} sx={{ mb: 1 }} data-cy={'ToDoCard_' + index.toString()}>
                <CommonCardContent
                    reservation={reservation}
                    isInUrgentState={isInUrgentState(reservation)}
                    isInWarningState={isInWarningState(reservation)}
                    cardStateDescription={translate(ToDoTabContentDict.waitingToBeStarted)}
                    allEmployees={props.allEmployees as IEmployee[]} />
                <CardActions disableSpacing={true}>
                    <Button color='secondary' onClick={() => setStartDlgOpenInfo({ open: true, reservation: reservation })} data-cy='CardStartAction'>{translate(ToDoTabContentDict.startButton)}</Button>
                    <IconButton aria-label="more" sx={{ ml: 'auto' }} onClick={(event) => handleCardMenuOpen(event, reservation)} data-cy='CardOverflowMenu'>
                        <Icon>more_vert</Icon>
                    </IconButton>
                </CardActions>
            </Card>)}
            {toDoReservation?.length === 0 && <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} m={1} fontStyle={'italic'} data-cy='ToDoNoReservationMsg'>{translate(ToDoTabContentDict.ToDoNoReservationMsg)}</Typography>}
            <CommonActionMenu stopActionHandler={(reservation) => setStopDlgOpenInfo({ open: true, reservation: reservation })}
                completeActionHandler={(reservation) => setCompleteDlgOpenInfo({ open: true, reservation: reservation })}
                restartActionHandler={(reservation) => setRestartDlgOpenInfo({ open: true, reservation: reservation })}
                removeActionHandler={(reservation) => setRemoveDlgOpenInfo({ open: true, reservation: reservation })}
                closeMenuActionHandler={() => setCardMenuOpen(false)}
                open={cardMenuOpen}
                menuAnchorEl={cardMenuAnchorEl}
                reservation={cardMenuReservation} />
            <StartDialog openInfo={startDlgOpenInfo}
                setOpenInfo={setStartDlgOpenInfo}
                allEmployees={props.allEmployees}
                propertyMgmtOrg={props.propertyMgmtOrg}
                safeSetViewLoadingState={props.safeSetViewLoadingState}
                refreshSingleReservationInList={props.refreshSingleReservationInList}
                safeSetUserMessageContent={props.safeSetUserMessageContent} />
            <StopDialog openInfo={stopDlgOpenInfo}
                setOpenInfo={setStopDlgOpenInfo}
                allEmployees={props.allEmployees}
                propertyMgmtOrg={props.propertyMgmtOrg}
                safeSetViewLoadingState={props.safeSetViewLoadingState}
                refreshSingleReservationInList={props.refreshSingleReservationInList}
                safeSetUserMessageContent={props.safeSetUserMessageContent} />
            <CompleteDialog openInfo={completeDlgOpenInfo}
                setOpenInfo={setCompleteDlgOpenInfo}
                allEmployees={props.allEmployees}
                propertyMgmtOrg={props.propertyMgmtOrg}
                safeSetViewLoadingState={props.safeSetViewLoadingState}
                refreshSingleReservationInList={props.refreshSingleReservationInList}
                safeSetUserMessageContent={props.safeSetUserMessageContent} />
            <RestartDialog openInfo={restartDlgOpenInfo}
                setOpenInfo={setRestartDlgOpenInfo}
                allEmployees={props.allEmployees}
                propertyMgmtOrg={props.propertyMgmtOrg}
                safeSetViewLoadingState={props.safeSetViewLoadingState}
                refreshSingleReservationInList={props.refreshSingleReservationInList}
                safeSetUserMessageContent={props.safeSetUserMessageContent} />
            <RemoveDialog openInfo={removeDlgOpenInfo}
                setOpenInfo={setRemoveDlgOpenInfo}
                allEmployees={props.allEmployees}
                propertyMgmtOrg={props.propertyMgmtOrg}
                safeSetViewLoadingState={props.safeSetViewLoadingState}
                refreshSingleReservationInList={props.refreshSingleReservationInList}
                safeSetUserMessageContent={props.safeSetUserMessageContent} />
        </React.Fragment>;
};

export default ToDoTabContent