const convertNullValuesInArray = (arrayValue: any[]) => {

    for (let i = 0; i < arrayValue.length; i++) {

        if (arrayValue[i] === null)
            arrayValue[i] = undefined;
        else
            convertNullValuesInNonNullValue(arrayValue[i]);
    }
};

const convertNullValuesInObject = (objectValue: any) => {

    for (let key in objectValue)
        if (objectValue.hasOwnProperty(key)) {

            if (objectValue[key] === null)
                objectValue[key] = undefined;
            else
                convertNullValuesInNonNullValue(objectValue[key]);
        }
};

const convertNullValuesInNonNullValue = (anyValue: any) => {

    if (anyValue === undefined || typeof (anyValue) !== 'object')
        return;

    if (Array.isArray(anyValue))
        convertNullValuesInArray(anyValue);
    else
        convertNullValuesInObject(anyValue);
}

const convertNullValuesToUndefined = (anyValue: any) => {

    if (anyValue === null)
        return undefined;

    convertNullValuesInNonNullValue(anyValue);

    return anyValue;
};

export default convertNullValuesToUndefined;