import IApiClient from '../../../utilities/IApiClient';

export interface ISpecialty {
  id: string;
  propertyMgmtOrgId: string;
  aggregateType: string;
  aggregateTimeStamp: string;
  name: string;
  description: string;
}

const specialtyAdapter = {
  getSpecialty: (apiClient: IApiClient, propertyMgmtId: string, specialtyId: string) => 
                 apiClient.get<ISpecialty>('/Specialties/' + propertyMgmtId + '/' + specialtyId),

  updateSpecialty: (apiClient: IApiClient, propertyMgmtId: string, specialtyId: string, specialty: ISpecialty, expectConcurrencyError: boolean) => 
                    apiClient.put('/Specialties/' + propertyMgmtId + '/' + specialtyId, specialty, expectConcurrencyError),
};

export default specialtyAdapter;
