import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { parseApiDate } from '../../../utilities/apiDates';
import CommonCardContent from './CommonCardContent';
import LoadingState from '../../../components/LoadingState';
import useApiClient from '../../../utilities/hooks/useApiClient';
import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import ConfirmRefusalDialog from './ConfirmRefusalDialog';

export interface IToConfirmTabContentProps {
  allReservations: IReservation[] | undefined;
  allEmployees: IEmployee[] | undefined;
  propertyMgmtOrg: IPropertyMgmtOrg | undefined;
  safeSetViewLoadingState: (loadingState: LoadingState) => void;
  refreshSingleReservationInList: (reservation: IReservation) => void;
  safeSetUserMessageContent: (messageContent: string) => void;
}

const ToConfirmTabContentDict = {
  waitingForConfirmation: {
    en: 'Waiting for confirmation',
    fr: 'En attente de confirmation',
  },
  confirmButton: { en: 'Confirm', fr: 'Confirmer' },
  refuseButton: { en: 'Refuse', fr: 'Refuser' },
  confirmationConcurrencyError: {
    en: 'Reservation for {0} has been modified by another user. Employee cannot be confirmed. Please refresh view and retry if needed.',
    fr: "La réservation pour {0} a été modifiée par un autre utilisateurs. L'employé ne peut être confirmer. SVP rafraîchir et réessayer si nécessaire.",
  },
  confirmationInternalError: {
    en: 'Internal error while trying to confirm for {0}. Please refresh and retry if needed.',
    fr: "Erreur interne lors de la confirmation de l'pour {0}. SVP rafraîchir et réessayer si nécessaire.",
  },
  ToConfirmNoReservationMsg: {
    en: 'There is no cleaning job to confirm.',
    fr: "Aucune job d'entretien à confirmer.",
  },
};

const ToConfirmTabContent = (props: IToConfirmTabContentProps) => {
  const translate = useTranslator();
  const apiClient = useApiClient();
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const [confirmRefusalDlgOpenInfo, setConfirmRefusalDlgOpenInfo] = useState<{ open: boolean; reservation: IReservation } | undefined>(undefined);

  const renderNow = new Date();

  const toBeConfirmedReservation = props.allReservations?.filter((value) => value.cleaningJob.status === 'ToBeConfirmed');

  toBeConfirmedReservation?.sort((a, b) => parseApiDate(a.startDate).getTime() - parseApiDate(b.startDate).getTime());

  const propertyMgmtOrgId = props.propertyMgmtOrg?.id!;

  const isInWarningState = (reservation: IReservation) => (parseApiDate(reservation.endDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.shouldHaveBeenConfirmedTimeOut ?? 0);

  const isInUrgentState = (reservation: IReservation) => (parseApiDate(reservation.endDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.mustHaveBeenConfirmedTimeOut ?? 0);

  const confirmEmployeeOnCleaningJob = (reservation: IReservation) => {
    reservationsAdapter
      .confirmEmployeeOnCleaningJob(apiClient, propertyMgmtOrgId, reservation.id, reservation.aggregateTimeStamp, { targetEmployeeUniqueUserId: propertyMgmtContext.uniqueUserId! }, true)
      .then((value) => {
        props.refreshSingleReservationInList(value);
      })
      .catch((error: any) => {
        if (isApiConcurrencyError(error)) props.safeSetUserMessageContent(translate(ToConfirmTabContentDict.confirmationConcurrencyError, [reservation.rentalUnit.name]));
        else props.safeSetUserMessageContent(translate(ToConfirmTabContentDict.confirmationInternalError, [reservation.rentalUnit.name]));
      })
      .finally(() => props.safeSetViewLoadingState(LoadingState.None));
  };

  return !(props.allReservations && props.allEmployees && props.propertyMgmtOrg) ? null : (
    <React.Fragment>
      {toBeConfirmedReservation?.map((reservation, index) => (
        <Card key={reservation.id} sx={{ mb: 1 }} data-cy={'ToConfirmCard_' + index.toString()}>
          <CommonCardContent
            reservation={reservation}
            isInUrgentState={isInUrgentState(reservation)}
            isInWarningState={isInWarningState(reservation)}
            cardStateDescription={translate(ToConfirmTabContentDict.waitingForConfirmation)}
            allEmployees={props.allEmployees as IEmployee[]}
          />
          <CardActions disableSpacing={true}>
            <Button color="secondary" onClick={() => confirmEmployeeOnCleaningJob(reservation)} data-cy="CardConfirmAction">
              {translate(ToConfirmTabContentDict.confirmButton)}
            </Button>
            <Button color="secondary" onClick={() => setConfirmRefusalDlgOpenInfo({ open: true, reservation: reservation })} sx={{ ml: 1 }} data-cy="CardRefuseAction">
              {translate(ToConfirmTabContentDict.refuseButton)}
            </Button>
          </CardActions>
        </Card>
      ))}
      {toBeConfirmedReservation?.length === 0 && (
        <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} m={1} fontStyle={'italic'} data-cy="ToConfirmNoReservationMsg">
          {translate(ToConfirmTabContentDict.ToConfirmNoReservationMsg)}
        </Typography>
      )}
      <ConfirmRefusalDialog
        openInfo={confirmRefusalDlgOpenInfo}
        setOpenInfo={setConfirmRefusalDlgOpenInfo}
        propertyMgmtOrg={props.propertyMgmtOrg}
        safeSetViewLoadingState={props.safeSetViewLoadingState}
        refreshSingleReservationInList={props.refreshSingleReservationInList}
        safeSetUserMessageContent={props.safeSetUserMessageContent}
      />
    </React.Fragment>
  );
};

export default ToConfirmTabContent;
