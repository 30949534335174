import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { parseApiDate } from '../../../utilities/apiDates';
import CommonCardContent from './CommonCardContent';
import LoadingState from '../../../components/LoadingState';
import useApiClient from '../../../utilities/hooks/useApiClient';
import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import { Typography } from '@mui/material';

export interface IDoneTabContentProps {
  allReservations: IReservation[] | undefined;
  allEmployees: IEmployee[] | undefined;
  propertyMgmtOrg: IPropertyMgmtOrg | undefined;
  safeSetViewLoadingState: (loadingState: LoadingState) => void;
  refreshSingleReservationInList: (reservation: IReservation) => void;
  safeSetUserMessageContent: (messageContent: string) => void;
}

const DoneTabContentDict = {
  theWorkIsCompleted: {
    en: 'The work is complete',
    fr: 'Le travail est terminé',
  },
  toRestartButton: { en: 'Start over', fr: 'Redémarrer' },
  confirmationConcurrencyError: {
    en: 'Reservation for {0} has been modified by another user. Employee cannot be confirmed. Please refresh view and retry if needed.',
    fr: "La réservation pour {0} a été modifiée par un autre utilisateurs. L'employé ne peut être confirmer. SVP rafraîchir et réessayer si nécessaire.",
  },
  confirmationInternalError: {
    en: 'Internal error while trying to confirm for {0}. Please refresh and retry if needed.',
    fr: "Erreur interne lors de la confirmation de l'pour {0}. SVP rafraîchir et réessayer si nécessaire.",
  },
  DoneNoReservationMsg: {
    en: 'There is no cleaning job completed in the last {0} hours.',
    fr: "Aucune job d'entretien complétée dans les dernières {0} heures.",
  },
};

const ToConfirmTabContent = (props: IDoneTabContentProps) => {
  const translate = useTranslator();
  const apiClient = useApiClient();
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const propertyMgmtOrgId = props.propertyMgmtOrg?.id!;

  const renderNow = new Date();

  const doneReservations = props.allReservations?.filter((value) => value.cleaningJob.status === 'Done');

  doneReservations?.sort((a, b) => parseApiDate(a.startDate).getTime() - parseApiDate(b.startDate).getTime());

  const isInWarningState = (reservation: IReservation) => (parseApiDate(reservation.endDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.shouldHaveBeenConfirmedTimeOut ?? 0);

  const isInUrgentState = (reservation: IReservation) => (parseApiDate(reservation.endDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.mustHaveBeenConfirmedTimeOut ?? 0);

  const employeeRestartsCleaningJob = (reservation: IReservation) => {
    reservationsAdapter
      .employeeRestartsCleaningJob(apiClient, propertyMgmtOrgId, reservation.id, reservation.aggregateTimeStamp, { targetEmployeeUniqueUserId: propertyMgmtContext.uniqueUserId! }, true)
      .then((value) => {
        props.refreshSingleReservationInList(value);
      })
      .catch((error: any) => {
        if (isApiConcurrencyError(error)) props.safeSetUserMessageContent(translate(DoneTabContentDict.confirmationConcurrencyError, [reservation.rentalUnit.name]));
        else props.safeSetUserMessageContent(translate(DoneTabContentDict.confirmationInternalError, [reservation.rentalUnit.name]));
      })
      .finally(() => props.safeSetViewLoadingState(LoadingState.None));
  };

  return !(props.allReservations && props.allEmployees && props.propertyMgmtOrg) ? null : (
    <React.Fragment>
      {doneReservations?.map((reservation, index) => (
        <Card key={reservation.id} sx={{ mb: 1 }} data-cy={'DoneCard_' + index.toString()}>
          <CommonCardContent
            reservation={reservation}
            isInUrgentState={isInUrgentState(reservation)}
            isInWarningState={isInWarningState(reservation)}
            cardStateDescription={translate(DoneTabContentDict.theWorkIsCompleted)}
            allEmployees={props.allEmployees as IEmployee[]}
          />
          <CardActions disableSpacing={true}>
            <Button
              color="secondary"
              onClick={() => {
                employeeRestartsCleaningJob(reservation);
              }}
              data-cy="CardStartOverAction">
              {translate(DoneTabContentDict.toRestartButton)}
            </Button>
          </CardActions>
        </Card>
      ))}
      {doneReservations?.length === 0 && (
        <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} m={1} fontStyle={'italic'} data-cy="DoneNoReservationMsg">
          {translate(DoneTabContentDict.DoneNoReservationMsg, [props.propertyMgmtOrg.cleaningJobSettings.rearViewTimeSpan.toString()])}
        </Typography>
      )}
    </React.Fragment>
  );
};

export default ToConfirmTabContent;
