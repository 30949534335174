import { useOktaAuth } from '@okta/okta-react'
import { useEffect, ReactNode } from 'react';

//VisibilityStateAuthHandler is necessary for app running on mobile phones. The token autorenew functionality pauses when the phone goes to sleep or the user switch to another non web app.

const VisibilityStateAuthHandler = (props: { children?: ReactNode }) => {

    const oktaContext = useOktaAuth();

    useEffect(() => {

        const visibilityChangeHandler = () => {

            if (document.visibilityState !== 'visible')
                return;

            const accessToken = oktaContext.oktaAuth.tokenManager.getTokensSync().accessToken;

            if (accessToken && oktaContext.oktaAuth.tokenManager.hasExpired(accessToken)) {

                console.warn("Reset expire event timeout.");

                oktaContext.oktaAuth.tokenManager.resetExpireEventTimeoutAll();
            }
        };

        window.addEventListener('visibilitychange', visibilityChangeHandler);

        return () => { window.removeEventListener('visibilitychange', visibilityChangeHandler) };
    }, [oktaContext.oktaAuth]);

    return <>{props.children}</>;
}

export default VisibilityStateAuthHandler