import React from 'react';

import { Box, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import useTranslator from '../../../utilities/hooks/useTranslator';
import { IEmployee } from '../Adapters/employeesAdapter';
export interface IEmployeeListProps {
  allEmployees: IEmployee[] | undefined;
  gotoEmployee: (employeeId: string) => void;
}

const employeeListDict = {
  employeeNumber: { en: 'Employee No', fr: "Employé no" },
  manager: { en: 'Manager', fr: 'Superviseur'}
};

const EmployeeList = (props: IEmployeeListProps) => {
  const translate = useTranslator();

  return !props.allEmployees ? null : (
    <React.Fragment>
      {props.allEmployees.map((employee) => (
        <Card key={employee.id} sx={{ m: 1 }}>
          <CardActionArea onClick={() => props.gotoEmployee(employee.id)}>
            <CardContent sx={{ mb: 1 }}>
              <Box display="flex" justifyContent="space-between">
                {employee.isActive ? (
                  <Typography variant="subtitle2">
                    {translate(employeeListDict.employeeNumber)} {employee.employeeNumber}
                  </Typography>
                ) : (
                  <Typography sx={{ color: 'primary.dark' }} variant="subtitle2">
                    {translate(employeeListDict.employeeNumber)} {employee.employeeNumber} - Inactive
                  </Typography>
                )}
                   {employee.isManager ? <Typography variant="subtitle2">{translate(employeeListDict.manager)}</Typography> : null}
              </Box>
              <Typography variant="h6" component="h6">
                {employee.firstName} {employee.lastName}
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 1.5 }} color="text.secondary">
                {employee.nickname}
              </Typography>
              <Typography variant="body2">{employee.email}</Typography>
              <Typography variant="body2">{employee.phones[0]}</Typography>
              <Typography variant="body2">{employee.phones[1]}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </React.Fragment>
  );
};
export default EmployeeList;
