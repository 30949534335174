import { useEffect, useRef } from 'react';

export type ShouldSetStatesBeCancelledFunction = () => boolean;

const useSafeAsyncOperations: (initialAsyncOperationEffect?: (shouldSetStatesBeCancelledFn: ShouldSetStatesBeCancelledFunction) => void) => ShouldSetStatesBeCancelledFunction = (asyncOperationEffect) => {

    const cancelSetStatesRef = useRef({ shouldSetStatesBeCancelled: false, initialAsyncOperationEffect: asyncOperationEffect });

    const shouldSetStatesBeCancelledFn = () => cancelSetStatesRef.current.shouldSetStatesBeCancelled;

    useEffect(() => {

        const cancelSetStatesRefCurrent = cancelSetStatesRef.current;

        if (cancelSetStatesRefCurrent.initialAsyncOperationEffect) {
            cancelSetStatesRefCurrent.initialAsyncOperationEffect(shouldSetStatesBeCancelledFn);
            cancelSetStatesRefCurrent.initialAsyncOperationEffect = undefined;
        }

        return () => { cancelSetStatesRefCurrent.shouldSetStatesBeCancelled = true; }

    }, []);

    return shouldSetStatesBeCancelledFn;
}

export default useSafeAsyncOperations 