import { LoginCallback } from '@okta/okta-react'
import LinearProgress from '@mui/material/LinearProgress';

import logManager from '../../utilities/logManager';
import UnexpectedErrorContent from '../internals/UnexpectedErrorContent';

const LoggingUnexpectedErrorContent = (props: { error: Error }) => {

    if (props.error)
        logManager.logError(props.error);

    return <UnexpectedErrorContent />
};

const AdvancedLoginCallback = () =>
    <LoginCallback errorComponent={LoggingUnexpectedErrorContent} loadingElement={<LinearProgress color='primary'></LinearProgress>} />;


export default AdvancedLoginCallback;