import IApiClient from '../../../utilities/IApiClient';

export interface ISpecialty {
    id: string,
    name: string
}

const specialtiesAdapter = {
    getAllSpecialties: (apiClient: IApiClient, propertyMgmtId: string) => apiClient.get<ISpecialty[]>('/Specialties/' + propertyMgmtId)
};

export default specialtiesAdapter;