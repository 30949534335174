import { BarChart, Bar, XAxis, ResponsiveContainer } from 'recharts';
import { Box } from '@mui/material';

export interface INextMonthChartProps {
    noOfUnitsToClean: number[] | undefined;
}


const NextMonthChart = (props: INextMonthChartProps) => {


    const data = props.noOfUnitsToClean?.map((noOfUnitsToCleanOnWeekday, index) => ({ units: noOfUnitsToCleanOnWeekday, day: index + 1 }));

    return (
        <Box mt={1} border={1}>
            <ResponsiveContainer width="100%" height={240}>
                <BarChart data={data}>
                    <XAxis interval={0} tick={{ fontSize: 7 }} dataKey="day" />
                    <Bar barSize={5} dataKey="units" fill="#C13600" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};
export default NextMonthChart;
