import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import useTranslator from '../../../utilities/hooks/useTranslator';
import useSafeHistory from '../../../utilities/hooks/useSafeHistory';
import { IRentalUnit } from '../Adapters/rentalUnitAdapters';

export interface ConfirmCancellationDialogProps {
  openInfo: { open: boolean } | undefined;
  rentalUnit: IRentalUnit | undefined;
  setOpenInfo: (openInfo: { open: boolean } | undefined) => void;
}

const ConfirmCancellationDialogDict = {
  confirmCancellationDlgTitle: { en: 'Cancel Update ?', fr: `Annuler la modification ?` },
  confirmationCancellationDlgText: { en: 'You will loose the changes you did to rental unit {0}. Do you agree ?', fr: `Vous perdrez les données saisies à propos de la nouvelle unité de location {0}. Êtes-vous d'accord?` },
  confirmationRefusalDlgYesActionLbl: { en: 'YES', fr: 'OUI' },
  confirmationRefusalDlgNoActionLbl: { en: 'NO', fr: 'NON' },

};

const ConfirmCancellationDialog = (props: ConfirmCancellationDialogProps) => {
  const translate = useTranslator();
  const history = useSafeHistory();

  const cancelConfirmCancellationDialog = () => {
    props.setOpenInfo({ open: false });
  };
  const confirmCancellationDialog = () => {
    history.goBack()
  }
  return (
    <Dialog fullWidth onClose={() => { }} open={!!props.openInfo && props.openInfo.open} keepMounted>
      <DialogTitle data-cy="CancelDialogTitle">{translate(ConfirmCancellationDialogDict.confirmCancellationDlgTitle)}</DialogTitle>
      <DialogContent >
        <DialogContentText data-cy="CancelDialogMessage">
          {translate(ConfirmCancellationDialogDict.confirmationCancellationDlgText, [props.rentalUnit?.name!])}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancelConfirmCancellationDialog} data-cy="CancelDialogNoAction">
          {translate(ConfirmCancellationDialogDict.confirmationRefusalDlgNoActionLbl)}
        </Button>
        <Button onClick={confirmCancellationDialog} data-cy="CancelDialogYesAction">
          {translate(ConfirmCancellationDialogDict.confirmationRefusalDlgYesActionLbl)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancellationDialog;
