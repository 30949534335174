import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningJobEmployee {
    id: string,
    status: 'Assigned' | 'Confirmed' | 'Refused' | 'Removed' | 'Working' | 'Done'
}

export interface IReservation {
    id: string,
    aggregateTimeStamp: string,
    startDate: string,
    endDate: string,
    rentalUnit: {
        name: string,
        civicNumber: string,
        street: string,
        city: string,
        province: string
    },
    cleaningJob: {
        status: 'ToBeAssigned' | 'ToBeConfirmed' | 'ToDo' | 'InProgress' | 'Done',
        employees: ICleaningJobEmployee[],
        mustBeCompletedBeforeDate: string,
        numberOfRequiredCleaningEmployees: number
    },
    nextReservation?: {
        startDate: string
    },
}

export interface IAssignEmployeeToCleaningJobDTO {
    targetEmployeeId: string
}

export interface IRemoveEmployeeFromCleaningJobDTO {
    targetEmployeeId: string
}

const reservationsAdapter = {
    getReservationsWithScheduledCleaningJobs: (apiClient: IApiClient, propertyMgmtOrgId: string, periodStartDate: string) =>
        apiClient.get<IReservation[]>('/Reservations/' + propertyMgmtOrgId + '/withScheduledCleaningJobs?periodStartDate=' + periodStartDate),

    getReservation: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string) =>
        apiClient.get<IReservation>('/Reservations/' + propertyMgmtOrgId + '/' + reservationId),

    assignEmployeeToCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, assignEmployeeToCleaningJobDTO: IAssignEmployeeToCleaningJobDTO, expectConcurrencyError: boolean) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/AssignEmployeeToCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            assignEmployeeToCleaningJobDTO,
            expectConcurrencyError),

    removeEmployeeFromCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, removeEmployeeFromCleaningJobDTO: IRemoveEmployeeFromCleaningJobDTO, expectConcurrencyError: boolean) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/RemoveEmployeeFromCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            removeEmployeeFromCleaningJobDTO,
            expectConcurrencyError)
};

export default reservationsAdapter;