import { useOktaAuth } from '@okta/okta-react';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosTransformer } from 'axios'

import logManager from '../logManager';
import IApiClient from '../IApiClient';
import IApiConcurrencyError from '../IApiConcurrencyError';
import IApiBusinessLogicError from '../IApiBusinessLogicError';
import convertNullValuesToUndefined from '../convertNullValuesToUndefined';

function returnAxiosInstance(accessToken?: string) {
    return Axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        ...(accessToken && { headers: { Authorization: 'Bearer ' + accessToken } }),
    })
}

function tryBuildBLError(errorResponse: any): IApiBusinessLogicError | undefined {

    if (errorResponse.status !== 422)
        return undefined;

    if (!errorResponse.data) {
        logManager.logError(`Invalid Business Logic Error returned by API. Status is ${errorResponse.status} but response data is empty.`);
        return undefined;
    }

    if (!errorResponse.data.code) {
        logManager.logError(`Invalid Business Logic Error returned by API. Status is ${errorResponse.status} but response data is missing the code field. Response Data : ${JSON.stringify(errorResponse.data)}.`);
        return undefined;
    }

    return { code: errorResponse.data.code, helperMessage: errorResponse.data.helperMessage, isApiBusinessLogicError: true };
}

function tryBuildConcurrencyError(errorResponse: any): IApiConcurrencyError | undefined {

    if (errorResponse.status !== 409)
        return undefined;

    return { isApiConcurrencyError: true };
}

function configJSONReplacer(key: string, value: any) {
    return key === 'Authorization' ? undefined : value;
}

function handleAxiosError<T>(endpoint: string, error: any, preferredUserName?: string, expectConcurrencyError?: boolean, expectedLogicErrorCodes?: string[]): Promise<T> {

    if (error.response) {

        if (expectedLogicErrorCodes) {

            const businessLogicError = tryBuildBLError(error.response);

            if (businessLogicError && expectedLogicErrorCodes.indexOf(businessLogicError.code) !== -1) {

                logManager.logDebug(
                    'ApiClient Error converted to business logic error.\n' +
                    `Error Request Config : ${JSON.stringify(error.config, configJSONReplacer, 2)}\n` +
                    `Error data : ${JSON.stringify(error.response.data, null, 2)}\n` +
                    `Error User name : ${preferredUserName}`);

                return Promise.reject<T>(businessLogicError);
            }
        }

        if (expectConcurrencyError) {

            const concurrencyError = tryBuildConcurrencyError(error.response);

            if (concurrencyError) {

                logManager.logDebug(
                    'ApiClient Error converted to concurrency error.\n' +
                    `Error Request Config : ${JSON.stringify(error.config, configJSONReplacer, 2)}\n` +
                    `Error User name : ${preferredUserName}`);

                return Promise.reject<T>(concurrencyError);
            }
        }

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        logManager.logError(
            `Error returned by API(${process.env.REACT_APP_API_URL}) with endpoint : ${endpoint}
            Error status : ${error.response.status}
            Error data : ${JSON.stringify(error.response.data, null, 2)}
            Error headers : ${JSON.stringify(error.response.headers, null, 2)}
            Error config : ${JSON.stringify(error.config, configJSONReplacer, 2)}
            Error User name : ${preferredUserName}`);

    } else if (error.request) {

        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser 
        logManager.logError(
            `No response received from API(${process.env.REACT_APP_API_URL}) with endpoint : ${endpoint}
            Error request : ${JSON.stringify(error.request, null, 2)}
            Error config : ${JSON.stringify(error.config, configJSONReplacer, 2)}
            Error User name : ${preferredUserName}`);

    } else {

        // Something happened in setting up the request that triggered an Error
        logManager.logError(
            `Error setting up request for API(${process.env.REACT_APP_API_URL}) with endpoint : ${endpoint}
            Error message : ${error.message}
            Error config : ${JSON.stringify(error.config, configJSONReplacer, 2)}
            Error User name : ${preferredUserName}`);
    }

    return Promise.reject<T>(error);
}

function buildTransformConfig(currentAxiosInstance: AxiosInstance): AxiosRequestConfig | undefined {

    let transformResponse: AxiosTransformer[] = [];

    if (currentAxiosInstance.defaults.transformResponse)
        transformResponse = transformResponse.concat(Array.isArray(currentAxiosInstance.defaults.transformResponse) ? currentAxiosInstance.defaults.transformResponse : [currentAxiosInstance.defaults.transformResponse]);

    transformResponse.push((data: any) => convertNullValuesToUndefined(data));

    return { transformResponse: transformResponse };
}

const useApiClient: () => IApiClient = () => {

    const { authState } = useOktaAuth();

    const serializedAccesToken = authState?.accessToken?.accessToken;

    const preferredUserName = authState?.idToken?.claims.preferred_username;

    return {
        get: <T>(endpoint: string, expectedLogicErrorCodes?: string[]) => {
            const axios = returnAxiosInstance(serializedAccesToken);
            return axios.get<T>(endpoint, buildTransformConfig(axios)).then((value) => value.data, (reason) => handleAxiosError<T>(endpoint, reason, preferredUserName, undefined, expectedLogicErrorCodes));
        },
        post: <T>(endpoint: string, data?: any, expectConcurrencyError?: boolean, expectedLogicErrorCodes?: string[]) => {
            const axios = returnAxiosInstance(serializedAccesToken);
            return axios.post<T>(endpoint, data, buildTransformConfig(axios)).then((value) => value.data, (reason) => handleAxiosError<T>(endpoint, reason, preferredUserName, expectConcurrencyError, expectedLogicErrorCodes));
        },
        put: <T>(endpoint: string, data: any, expectConcurrencyError?: boolean, expectedLogicErrorCodes?: string[]) => {
            const axios = returnAxiosInstance(serializedAccesToken);
            return axios.put<T>(endpoint, data, buildTransformConfig(axios)).then((value) => value.data, (reason) => handleAxiosError<T>(endpoint, reason, preferredUserName, expectConcurrencyError, expectedLogicErrorCodes));
        },
        del: (endpoint: string, expectConcurrencyError?: boolean, expectedLogicErrorCodes?: string[]) => {
            const axios = returnAxiosInstance(serializedAccesToken);
            return axios.delete<number>(endpoint).then((value) => value.status, (reason) => handleAxiosError<number>(endpoint, reason, preferredUserName, expectConcurrencyError, expectedLogicErrorCodes));
        }
    }
};

export default useApiClient 