import IApiClient from '../../../utilities/IApiClient';

export interface IEmployee {
  id: string;
  employeeNumber: string;
  firstName: string;
  lastName: string;
  nickname: string;
  isActive: boolean;
  email: string;
  phones: string[];
  isManager: boolean;
}

const employeesAdapter = {
  getAllEmployees: (apiClient: IApiClient, propertyMgmtId: string) => apiClient.get<IEmployee[]>('/Employees/' + propertyMgmtId),
};

export default employeesAdapter;
