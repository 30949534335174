import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import LoadingState from '../../../components/LoadingState';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { stringifyApiDate } from '../../../utilities/apiDates';
import useApiClient from '../../../utilities/hooks/useApiClient';
import employeeAdapter, { IEmployee } from '../Adapters/employeeAdapter';
import useSafeHistory from '../../../utilities/hooks/useSafeHistory';

export interface ReactivationDialogProps {
  openInfo: { open: boolean } | undefined;
  setOpenInfo: (openInfo: { open: boolean } | undefined) => void;
  employeeDetails: IEmployee | undefined;
  setViewLoadingState: (loadingState: LoadingState) => void;
}

const ReactivationDialogDict = {
  ReactivationDlgTitle: { en: 'Reactivate Employee', fr: `Réactiver un employé` },
  confirmationReactivationDlgText: { en: 'Please confirm the reactivation of employee {0} ({1})', fr: `Veuillez confirmer la réactivation de l'employé {0} ({1})` },
  confirmationRefusalDlgYesActionLbl: { en: 'CONFIRM', fr: 'CONFIRMER' },
  confirmationRefusalDlgNoActionLbl: { en: 'CANCEL', fr: 'ANNULER' },
};

const ReactivationDialog = (props: ReactivationDialogProps) => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const apiClient = useApiClient();
  const translate = useTranslator();
  const history = useSafeHistory();

  const cancelReactivationDialog = () => {
    props.setOpenInfo({ open: false });
  };

  const currentDate = new Date();
  const date = stringifyApiDate(currentDate);

  const handleReactivate = () => {
    props.setViewLoadingState(LoadingState.ModalWithVisibleContent);
    employeeAdapter
      .reactivateEmployee(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, props.employeeDetails?.aggregateTimeStamp!, props.employeeDetails?.id!, { reactivationDate: date }, true)
      .then(() => history.push(`/employees`))
      .catch(() => props.setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };
  return (
    <Dialog fullWidth onClose={() => { }} open={!!props.openInfo && props.openInfo.open} keepMounted>
      <DialogTitle data-cy="DeactivateDialogTitle">{translate(ReactivationDialogDict.ReactivationDlgTitle)}</DialogTitle>
      <DialogContent>
        <DialogContentText data-cy="DeactivateDialogMessage">
          {translate(ReactivationDialogDict.confirmationReactivationDlgText, [props.employeeDetails?.firstName! + ' ' + props.employeeDetails?.lastName!, props.employeeDetails?.email!])}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancelReactivationDialog} data-cy="DeactivateDialogCancelAction">
          {translate(ReactivationDialogDict.confirmationRefusalDlgNoActionLbl)}
        </Button>
        <Button onClick={handleReactivate} data-cy="DeactivateDialogConfirmAction">
          {translate(ReactivationDialogDict.confirmationRefusalDlgYesActionLbl)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReactivationDialog;
