const RentalUnitCreateContentDict = {
  title: { en: 'Create Rental Unit', fr: 'Créer une unité de location' },
  name: { en: 'Name', fr: 'Nom' },
  required: { en: '*Required', fr: '*Requis' },
  civicNumber: { en: 'Civic Number', fr: 'Numéro Civique' },
  street: { en: 'Street', fr: 'Rue' },
  city: { en: 'City', fr: 'Ville' },
  province: { en: 'Province', fr: 'Province' },
  manager: { en: 'Manager', fr: 'Manager' },
  email: { en: 'Email:', fr: 'Courriel:' },
  phone: { en: 'Phone {0}:', fr: 'Téléphone {0}:' },
  notes: { en: 'Notes', fr: 'Notes' },
  cancel: { en: 'CANCEL', fr: 'ANNULER' },
  create: { en: 'CREATE', fr: 'CRÉER' },
  none: { en: 'None', fr: 'Aucun' },
  invalidFormat: { en: 'Format not valid', fr: `Format non valide` },
  rentalUnitAlreadExistsErrorMessage: { en: 'A similar rental unit already exists. Please check your data.', fr: 'Une unité similaire existe déjà. SVP vérifiez vos données' },
  managerMustExistErrorMessage: { en: 'The manager does not exist anymore. Please check or leave the manager empty.', fr: `Le gestionnaire n'existe plus. SVP vérifiez ou laissez la donnée vide.` },
  rentalUnitCreateInternalError: { en: 'Internal error while trying to create a rental unit. Please try again.', fr: `Erreur interne lors de la tentative de création d'un logement locatif. Veuillez réessayer.` },
};

export default RentalUnitCreateContentDict;
