import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import useLocaleFormater from '../../../utilities/hooks/useLocaleFormater';
import { parseApiDate } from '../../../utilities/apiDates';
import LoadingState from '../../../components/LoadingState';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import { isApiBusinessLogicErrorWithCode } from '../../../utilities/IApiBusinessLogicError';
import useApiClient from '../../../utilities/hooks/useApiClient';

export interface IStartDialogProps {
    openInfo: { open: boolean, reservation: IReservation } | undefined,
    setOpenInfo: (openInfo: { open: boolean, reservation: IReservation } | undefined) => void,
    allEmployees: IEmployee[],
    propertyMgmtOrg: IPropertyMgmtOrg,
    safeSetViewLoadingState: (loadingState: LoadingState) => void,
    refreshSingleReservationInList: (reservation: IReservation) => void,
    safeSetUserMessageContent: (messageContent: string) => void
}

const StartDialogDict = {
    startDlgTitle: { en: 'Start', fr: 'Démarrer' },
    startDlgTextBeforeJobName: { en: 'Start employee on job ', fr: 'Démarrer pour l\'employé sur la job ' },
    startDlgTextAfterJobName: { en: ' starting on {0} and that must be done before {1}.', fr: ' commençant le {0} et qui doit être terminée avant le {1}.' },
    startDlgOkActionLbl: { en: 'Ok', fr: 'Ok' },
    startDlgCancelActionLbl: { en: 'Cancel', fr: 'Annuler' },
    startConcurrencyError: { en: 'Reservation for {0} has been modified by another user. Job cannot be started for employee. Please refresh view and retry if needed.', fr: 'La réservation pour {0} a été modifiée par un autre utilisateurs. La job ne peut être démarrée pour l\'employé. SVP rafraîchir et réessayer si nécessaire.' },
    startEmployeeAlreadyWorkingError: { en: 'Job cannot be started for employee {0}. He is already working on another job.', fr: 'La job ne peut être démarrée pour l\'employé {0}. Il est déjà en train de travailler sur une autre job.' },
    startInternalError: { en: 'Internal error while trying to start job of {0} for {1}. Please refresh and retry if needed.', fr: 'Erreur interne lors du démarrage de la job pour {0} pour {1}. SVP rafraîchir et réessayer si nécessaire.' }
};

const StartDialog = (props: IStartDialogProps) => {

    const translate = useTranslator();
    const formater = useLocaleFormater();
    const apiClient = useApiClient();

    const [dialogEmployeeIdToStart, setDialogEmployeeIdToStart] = useState<string>('');

    const cancelStartDialog = () => {

        setDialogEmployeeIdToStart('');
        props.setOpenInfo({ open: false, reservation: props.openInfo!.reservation });
    }

    const getFormattedJobDate = (jobDate: string) => formater.formatDate(parseApiDate(jobDate), { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });

    const getEmployeesToStart = (reservation: IReservation) => reservation.cleaningJob.employees.filter((employee) => employee.status === 'Confirmed');

    const getEmployee = (id: string) => props.allEmployees?.find((employee) => employee.id === id);

    const getFormattedEmployeeName = (employee: IEmployee) => employee.lastName + ' ' + employee.firstName;

    const getFormattedEmployeeNameWithId = (id: string) => {

        const employee = getEmployee(id);
        return employee ? getFormattedEmployeeName(employee) : '';
    };

    const startEmployee = () => {

        const employeeToStart = getEmployee(dialogEmployeeIdToStart);

        if (!employeeToStart)
            throw new Error('Internal error : Employee cannot be found.');

        const reservationToStart = props.openInfo!.reservation;

        setDialogEmployeeIdToStart('');
        props.setOpenInfo({ open: false, reservation: props.openInfo!.reservation });

        props.safeSetViewLoadingState(LoadingState.ModalWithVisibleContent);

        reservationsAdapter.startEmployeeOnCleaningJob(
            apiClient,
            props.propertyMgmtOrg?.id,
            reservationToStart.id,
            reservationToStart.aggregateTimeStamp,
            { targetEmployeeUniqueUserId: employeeToStart.uniqueUserId },
            true,
            ['EmployeeAlreadyWorksOnAnotherJob']
        )
            .then((value) => {
                props.refreshSingleReservationInList(value)
            })
            .catch((error: any) => {

                if (isApiConcurrencyError(error))
                    props.safeSetUserMessageContent(translate(StartDialogDict.startConcurrencyError, [reservationToStart.rentalUnit.name]));
                else if (isApiBusinessLogicErrorWithCode(error, 'EmployeeAlreadyWorksOnAnotherJob'))
                    props.safeSetUserMessageContent(translate(StartDialogDict.startEmployeeAlreadyWorkingError, [getFormattedEmployeeName(employeeToStart)]));
                else
                    props.safeSetUserMessageContent(translate(StartDialogDict.startInternalError, [reservationToStart.rentalUnit.name, getFormattedEmployeeName(employeeToStart)]));
            })
            .finally(() => props.safeSetViewLoadingState(LoadingState.None));
    };

    return <Dialog fullWidth onClose={() => { }} open={!!props.openInfo && props.openInfo.open} keepMounted data-cy='StartDialog'>
        <DialogTitle>{translate(StartDialogDict.startDlgTitle)}</DialogTitle>
        <DialogContent dividers>
            <DialogContentText data-cy='StartDialogText'>{translate(StartDialogDict.startDlgTextBeforeJobName)}<Box component='span' sx={{ fontWeight: 700 }}>{props.openInfo?.reservation.rentalUnit.name}</Box>{translate(StartDialogDict.startDlgTextAfterJobName, [props.openInfo ? getFormattedJobDate(props.openInfo.reservation.endDate) : '', props.openInfo ? getFormattedJobDate(props.openInfo.reservation.cleaningJob.mustBeCompletedBeforeDate) : ''])}</DialogContentText>
            <FormControl sx={{ mt: 2 }}>
                <RadioGroup
                    aria-labelledby="employees-to-start"
                    name="employees-to-start-rd"
                    onChange={(ev, value) => setDialogEmployeeIdToStart(value)}
                    value={dialogEmployeeIdToStart}>
                    {props.openInfo && getEmployeesToStart(props.openInfo.reservation).map((cleaningEmployee, index) => <FormControlLabel value={cleaningEmployee.id} key={cleaningEmployee.id} control={<Radio />} label={getFormattedEmployeeNameWithId(cleaningEmployee.id)} data-cy={'StartDialogEmployee_' + index.toString()} />)}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={cancelStartDialog} data-cy='StartDialogCancelAction'>{translate(StartDialogDict.startDlgCancelActionLbl)}</Button>
            <Button disabled={dialogEmployeeIdToStart === ''} onClick={startEmployee} data-cy='StartDialogOkAction'>{translate(StartDialogDict.startDlgOkActionLbl)}</Button>
        </DialogActions>
    </Dialog>;
};

export default StartDialog