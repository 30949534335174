import { useOktaAuth } from '@okta/okta-react'
import { useContext } from 'react';

import logManager from '../../utilities/logManager';
import UnexpectedErrorContent from '../internals/UnexpectedErrorContent';
import AppLayoutContext from '../AppLayout/AppLayoutContext';
import ViewLayout from '../ViewLayout/ViewLayout';

const UnresolvedRoute = () => {

    const oktaContext = useOktaAuth();

    const appLayoutCtx = useContext(AppLayoutContext);

    if (oktaContext.authState?.isAuthenticated === undefined) //Currently trying to authenticate
        return null;

    logManager.logInfo('Route cannot be resolved!');

    if (appLayoutCtx.invalidAppContext)
        return <UnexpectedErrorContent />;
    else
        return <ViewLayout><UnexpectedErrorContent /></ViewLayout>;
}

export default UnresolvedRoute