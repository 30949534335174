import IApiClient from '../../../utilities/IApiClient';
export interface IEmployee {
  id: string;
  propertyMgmtOrgId: string;
  aggregateType: string;
  deactivationDate: string;
  reactivationDate: string;
  aggregateTimeStamp: string;
  creationDate: string;
  isActive: boolean;
  uniqueUserId: string;
  employeeNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  notes: string;
  phones: string[];
  specialtyIds: string[];
  isManager: boolean;
}
export interface IDeactivateEmployeeDTO {
  deactivationDate: string;
}

const employeeAdapter = {
  getEmployee: (apiClient: IApiClient, propertyMgmtId: string, employeeId: string) => apiClient.get<IEmployee>('/Employees/' + propertyMgmtId + '/' + employeeId),

  updateEmployee: (apiClient: IApiClient, propertyMgmtId: string, employeeId: string, updateEmployee: IEmployee, expectConcurrencyError: boolean) =>
    apiClient.put('/Employees/' + propertyMgmtId + '/' + employeeId, updateEmployee, expectConcurrencyError),

  deactivateEmployee: (apiClient: IApiClient, propertyMgmtId: string, aggregateTimeStamp: string, employeeId: string, updateEmployee: IDeactivateEmployeeDTO, expectConcurrencyError: boolean) =>
    apiClient.post('/Employees/' + propertyMgmtId + '/' + employeeId + '/deactivate?aggregateTimeStamp=' + aggregateTimeStamp, updateEmployee, expectConcurrencyError),
};
export default employeeAdapter;
