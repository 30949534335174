import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IViewFab {
    iconName: string,
    fabAction: () => void,
    isInChildView: boolean,
    disabled?: boolean
}

const ViewFab = (props: IViewFab) => {

    const theme = useTheme();
    const tooBigViewport = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box className={!tooBigViewport ? 'mui-fixed' : undefined} position='fixed' maxWidth='sm' width='100%' display='flex' bottom={0} zIndex={(theme) => theme.zIndex.appBar} sx={{ pointerEvents: 'none', pb: !props.isInChildView ? 7 : 0 }}>
            <Fab color="secondary" aria-label="refresh" sx={{ marginLeft: 'auto', marginRight: 2, marginBottom: 2, pointerEvents: 'auto' }} onClick={props.fabAction} disabled={props.disabled} data-cy='ViewFabAction'>
                <Icon>{props.iconName}</Icon>
            </Fab>
        </Box>
    );
};

export default ViewFab;