import IApiClient from '../../../utilities/IApiClient';

export interface ITomorrow {
  date: string;
  totalNumberOfUnitsToClean: number;
}
export interface INextWeek {
  startDate: string;
  endDate: string;
  totalNumberOfUnitsToClean: number;
  numberOfUnitsToClean: number[];
}
export interface INextMonth {
  startDate: string;
  endDate: string;
  totalNumberOfUnitsToClean: number;
  numberOfUnitsToClean: number[];
}

export interface ICleaningWorkload {
    tomorrow: ITomorrow;
    nextWeek: INextWeek;
    nextMonth: INextMonth;
}



const globalServicesAdapter = {

  getCleaningWorkload: (apiClient: IApiClient, propertyMgmtId: string) => apiClient.post<ICleaningWorkload>('/GlobalServices/' + propertyMgmtId + '/ComputeCleaningWorkload')

};

export default globalServicesAdapter;
