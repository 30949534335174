const ISO_UTC_DATE_REGEXP = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d{1,3})?Z$/;

export const parseApiDate = (stringDateValue: string) => {

    if (!ISO_UTC_DATE_REGEXP.test(stringDateValue))
        throw new Error(`Date string passed to parseApiDate is not valid. Passed value : ${stringDateValue}`);

    return new Date(stringDateValue);
}

export const stringifyApiDate = (dateValue: Date) => dateValue.toISOString();