import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import useLocaleFormater from '../../../utilities/hooks/useLocaleFormater';
import { parseApiDate } from '../../../utilities/apiDates';
import LoadingState from '../../../components/LoadingState';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import useApiClient from '../../../utilities/hooks/useApiClient';

export interface IRemoveDialogProps {
    openInfo: { open: boolean, reservation: IReservation } | undefined,
    setOpenInfo: (openInfo: { open: boolean, reservation: IReservation } | undefined) => void,
    allEmployees: IEmployee[],
    propertyMgmtOrg: IPropertyMgmtOrg,
    safeSetViewLoadingState: (loadingState: LoadingState) => void,
    refreshSingleReservationInList: (reservation: IReservation) => void,
    safeSetUserMessageContent: (messageContent: string) => void
}

const RemoveDialogDict = {
    removeDlgTitle: { en: 'Remove', fr: 'Retirer' },
    removeDlgTextBeforeJobName: { en: 'Remove employee on job ', fr: 'Retirer l\'employé sur la job ' },
    removeDlgTextAfterJobName: { en: ' starting on {0} and that must be done before {1}.', fr: ' commençant le {0} et qui doit être terminée avant le {1}.' },
    removeDlgOkActionLbl: { en: 'Ok', fr: 'Ok' },
    removeDlgCancelActionLbl: { en: 'Cancel', fr: 'Annuler' },
    removeConcurrencyError: { en: 'Reservation for {0} has been modified by another user. Job cannot be removed for employee. Please refresh view and retry if needed.', fr: 'La réservation pour {0} a été modifiée par un autre utilisateurs. La job ne peut être retirée pour l\'employé. SVP rafraîchir et réessayer si nécessaire.' },
    removeInternalError: { en: 'Internal error while trying to remove job of {0} for {1}. Please refresh and retry if needed.', fr: 'Erreur interne lors de la suppression de la job pour {0} pour {1}. SVP rafraîchir et réessayer si nécessaire.' }
};

const RemoveDialog = (props: IRemoveDialogProps) => {

    const translate = useTranslator();
    const formater = useLocaleFormater();
    const apiClient = useApiClient();

    const [dialogEmployeeIdToRemove, setDialogEmployeeIdToRemove] = useState<string>('');

    const cancelRemoveDialog = () => {

        setDialogEmployeeIdToRemove('');
        props.setOpenInfo({ open: false, reservation: props.openInfo!.reservation });
    }

    const getFormattedJobDate = (jobDate: string) => formater.formatDate(parseApiDate(jobDate), { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });

    const getEmployeesToRemove = (reservation: IReservation) => reservation.cleaningJob.employees.filter((employee) => employee.status === 'Assigned' || employee.status === 'Confirmed' || employee.status === 'Working' || employee.status === 'Done');

    const getEmployee = (id: string) => props.allEmployees?.find((employee) => employee.id === id);

    const getFormattedEmployeeName = (employee: IEmployee) => employee.lastName + ' ' + employee.firstName;

    const getFormattedEmployeeNameWithId = (id: string) => {

        const employee = getEmployee(id);
        return employee ? getFormattedEmployeeName(employee) : '';
    };

    const removeEmployee = () => {

        const employeeToRemove = getEmployee(dialogEmployeeIdToRemove);

        if (!employeeToRemove)
            throw new Error('Internal error : Employee cannot be found.');

        const reservationToRemove = props.openInfo!.reservation;

        setDialogEmployeeIdToRemove('');
        props.setOpenInfo({ open: false, reservation: props.openInfo!.reservation });

        props.safeSetViewLoadingState(LoadingState.ModalWithVisibleContent);

        reservationsAdapter.removeEmployeeFromCleaningJob(
            apiClient,
            props.propertyMgmtOrg?.id,
            reservationToRemove.id,
            reservationToRemove.aggregateTimeStamp,
            { targetEmployeeId: employeeToRemove.id },
            true
        )
            .then((value) => {
                props.refreshSingleReservationInList(value)
            })
            .catch((error: any) => {

                if (isApiConcurrencyError(error))
                    props.safeSetUserMessageContent(translate(RemoveDialogDict.removeConcurrencyError, [reservationToRemove.rentalUnit.name]));
                else
                    props.safeSetUserMessageContent(translate(RemoveDialogDict.removeInternalError, [reservationToRemove.rentalUnit.name, getFormattedEmployeeName(employeeToRemove)]));
            })
            .finally(() => props.safeSetViewLoadingState(LoadingState.None));
    };

    return <Dialog fullWidth onClose={() => { }} open={!!props.openInfo && props.openInfo.open} keepMounted data-cy='RemoveDialog'>
        <DialogTitle>{translate(RemoveDialogDict.removeDlgTitle)}</DialogTitle>
        <DialogContent dividers>
            <DialogContentText data-cy='RemoveDialogText'>{translate(RemoveDialogDict.removeDlgTextBeforeJobName)}<Box component='span' sx={{ fontWeight: 700 }}>{props.openInfo?.reservation.rentalUnit.name}</Box>{translate(RemoveDialogDict.removeDlgTextAfterJobName, [props.openInfo ? getFormattedJobDate(props.openInfo.reservation.endDate) : '', props.openInfo ? getFormattedJobDate(props.openInfo.reservation.cleaningJob.mustBeCompletedBeforeDate) : ''])}</DialogContentText>
            <FormControl sx={{ mt: 2 }}>
                <RadioGroup
                    aria-labelledby="employees-to-start"
                    name="employees-to-start-rd"
                    onChange={(ev, value) => setDialogEmployeeIdToRemove(value)}
                    value={dialogEmployeeIdToRemove}>
                    {props.openInfo && getEmployeesToRemove(props.openInfo.reservation).map((cleaningEmployee, index) => <FormControlLabel value={cleaningEmployee.id} key={cleaningEmployee.id} control={<Radio />} label={getFormattedEmployeeNameWithId(cleaningEmployee.id)} data-cy={'RemoveDialogEmployee_' + index.toString()} />)}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={cancelRemoveDialog} data-cy='RemoveDialogCancelAction'>{translate(RemoveDialogDict.removeDlgCancelActionLbl)}</Button>
            <Button disabled={dialogEmployeeIdToRemove === ''} onClick={removeEmployee} data-cy='RemoveDialogOkAction'>{translate(RemoveDialogDict.removeDlgOkActionLbl)}</Button>
        </DialogActions>
    </Dialog>;
};

export default RemoveDialog