import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningJobEmployee {
    id: string,
    status: 'Assigned' | 'Confirmed' | 'Refused' | 'Removed' | 'Working' | 'Done'
}

export interface IReservation {
    id: string,
    aggregateTimeStamp: string,
    startDate: string,
    endDate: string,
    rentalUnit: {
        name: string,
        civicNumber: string,
        street: string,
        city: string,
        province: string
    },
    cleaningJob: {
        status: 'ToBeAssigned' | 'ToBeConfirmed' | 'ToDo' | 'InProgress' | 'Done',
        employees: ICleaningJobEmployee[],
        mustBeCompletedBeforeDate: string
    },
    nextReservation?: {
        startDate: string
    },
}

export interface IConfirmEmployeeOnCleaningJobDTO {
    targetEmployeeUniqueUserId: string
}

export interface IStartEmployeeOnCleaningJobDTO {
    targetEmployeeUniqueUserId: string
}

export interface IEmployeeStopsWorkingOnCleaningJobDTO {
    targetEmployeeUniqueUserId: string
}

export interface IEmployeeRestartsWorkingOnCleaningJobDTO {
    targetEmployeeUniqueUserId: string
}

export interface IEmployeeCompletesCleaningJobDTO {
    targetEmployeeUniqueUserId: string
}

export interface IRemoveEmployeeFromCleaningJobDTO {
    targetEmployeeId: string
}

const reservationsAdapter = {
    getReservationsThatNeedAttentionFromCleaningManager: (apiClient: IApiClient, propertyMgmtOrgId: string) =>
        apiClient.get<IReservation[]>('/Reservations/' + propertyMgmtOrgId + '/thatNeedAttentionFromCleaningManager'),

    confirmEmployeeOnCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, confirmEmployeeOnCleaningJobDTO: IConfirmEmployeeOnCleaningJobDTO, expectConcurrencyError: boolean) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/ConfirmEmployeeOnCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            confirmEmployeeOnCleaningJobDTO,
            expectConcurrencyError),

    startEmployeeOnCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, startEmployeeOnCleaningJobDTO: IStartEmployeeOnCleaningJobDTO, expectConcurrencyError: boolean, expectedLogicErrors: string[]) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/EmployeeStartsWorkingOnCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            startEmployeeOnCleaningJobDTO,
            expectConcurrencyError,
            expectedLogicErrors),

    stopEmployeeOnCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, stopEmployeeOnCleaningJobDTO: IEmployeeStopsWorkingOnCleaningJobDTO, expectConcurrencyError: boolean) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/EmployeeStopsWorkingOnCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            stopEmployeeOnCleaningJobDTO,
            expectConcurrencyError),

    restartEmployeeOnCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, restartEmployeeOnCleaningJobDTO: IEmployeeRestartsWorkingOnCleaningJobDTO, expectConcurrencyError: boolean, expectedLogicErrors: string[]) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/EmployeeRestartsWorkingOnCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            restartEmployeeOnCleaningJobDTO,
            expectConcurrencyError,
            expectedLogicErrors),

    employeeCompletesCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, employeeCompletesCleaningJobDTO: IEmployeeCompletesCleaningJobDTO, expectConcurrencyError: boolean) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/EmployeeCompletesCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            employeeCompletesCleaningJobDTO,
            expectConcurrencyError),

    removeEmployeeFromCleaningJob: (apiClient: IApiClient, propertyMgmtOrgId: string, reservationId: string, reservationTimeStamp: string, removeEmployeeFromCleaningJobDTO: IRemoveEmployeeFromCleaningJobDTO, expectConcurrencyError: boolean) =>
        apiClient.post<IReservation>('/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/RemoveEmployeeFromCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
            removeEmployeeFromCleaningJobDTO,
            expectConcurrencyError)
};

export default reservationsAdapter;