import { useCallback, useContext, useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { FormHelperText, InputLabel, MenuItem } from '@mui/material';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';
import FeatureInputField from './components/FeatureInputField';
import ConfirmCancellationDialog from './components/ConfirmCancellationDialog';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import { isApiBusinessLogicErrorWithCode } from '../../utilities/IApiBusinessLogicError';
import employeesAdapter from './Adapters/employeesAdapter';
import { IEmployee } from './Adapters/employeesAdapter';
import propertyMgmtOrgsAdapter from './Adapters/propertyMgmtOrgsAdapter';
import rentalUnitAdapter from './Adapters/rentalUnitAdapters';
import RentalUnitCreateContentDict from './RentalUnitDict';
import { unstable_batchedUpdates } from 'react-dom';

export interface IFeature {
    name: string;
    value: string;
}
export interface ICleaningManager {
    uniqueUserId: string;
    email: string;
    name: string;
    phones: string[];
}

const RentalUnitCreate = () => {

    const translate = useTranslator();

    const history = useSafeHistory();

    const apiClient = useApiClient();

    const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

    const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

    const [name, setName] = useState<string>('');
    const [civicNumber, setCivicNumber] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [notes, setNotes] = useState<string | undefined>(undefined);
    const [province, setProvince] = useState<string>('');
    const [features, setFeatures] = useState<string[]>([]);
    const [featureList, setFeatureList] = useState<IFeature[]>([{ name: '', value: '' }]);
    const [employees, setEmployees] = useState<IEmployee[] | undefined>(undefined);
    const [managerId, setManagerId] = useState<string | undefined>(undefined);

    const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);

    const [confirmCancellationDlgOpenInfo, setConfirmCancellationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);

    const openCancelDialogCbk = useCallback(() => setConfirmCancellationDlgOpenInfo({ open: true }), [setConfirmCancellationDlgOpenInfo]);

    const managers = employees?.filter((employee) => employee.isManager);

    const selectedManager = managers?.find((manager) => manager.id === managerId);

    const manager: ICleaningManager | undefined = !managerId ? undefined : { uniqueUserId: selectedManager?.uniqueUserId!, email: selectedManager?.email!, name: selectedManager?.firstName! + ' ' + selectedManager?.lastName!, phones: selectedManager?.phones! };

    const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {

        setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

        Promise.all([
            employeesAdapter.getAllEmployees(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string),
            propertyMgmtOrgsAdapter.getFeatures(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
        ])
            .then((values) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
                setViewLoadingState(LoadingState.None);
                setEmployees(values[0]);
                setFeatures(values[1].features);
            }))
            .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
    };

    const unexpectedLoadingErrorReload = !getAllViewData ? undefined : () => getAllViewData(shouldSetStatesBeCancelledFn);

    const submitRentalUnitForm = () => {

        if (shouldSetStatesBeCancelledFn()) return;

        setViewLoadingState(LoadingState.ModalWithVisibleContent)

        featureList.pop()

        rentalUnitAdapter
            .createRentalUnit(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, { name: name!, civicNumber: civicNumber!, street: street!, city: city!, province: province!, manager: manager, features: featureList!, notes: notes! }, false, ['RentalUnitAlreadyExists', 'ManagerMustExist'])
            .then(() => history.goBack())
            .catch((error) => {
                featureList.push({ name: '', value: '' });
                if (isApiBusinessLogicErrorWithCode(error, 'RentalUnitAlreadyExists'))
                    setUserMessage(createMessageWithContent(translate(RentalUnitCreateContentDict.rentalUnitAlreadExistsErrorMessage)));
                else if (isApiBusinessLogicErrorWithCode(error, 'ManagerMustExist'))
                    setUserMessage(createMessageWithContent(translate(RentalUnitCreateContentDict.managerMustExistErrorMessage)));
                else
                    setUserMessage(createMessageWithContent(translate(RentalUnitCreateContentDict.rentalUnitCreateInternalError)));
            })
            .finally(() => {
                if (shouldSetStatesBeCancelledFn()) return;

                setViewLoadingState(LoadingState.None);

            });
    };

    const featureValidation = (featureListToValidate: IFeature[]): boolean => {

        const validatedFeatures = featureListToValidate.filter((feature) => feature.name);

        return validatedFeatures.every((feature) => feature.value.length !== 0) && featureListToValidate.length > 0;
    }

    const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

    return (
        <ViewLayout contentBoxPadding={2} contentBoxPaddingBottom={8} viewTitle={translate(RentalUnitCreateContentDict.title)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} userMessageInfo={{ userMessage: userMessage, setUserMessage: setUserMessage }} childViewBackOverride={openCancelDialogCbk} isChildView={true}>
            <TextField fullWidth inputProps={{ maxLength: 50 }} value={name || ''} onChange={(event) => setName(event.target.value)} sx={{ mb: 1 }} helperText={!name ? translate(RentalUnitCreateContentDict.required) : ' '} label={translate(RentalUnitCreateContentDict.name)} data-cy="NameInput" />
            <Box display="flex" mb={1}>
                <TextField value={civicNumber || ''} onChange={(event) => setCivicNumber(event.target.value)} helperText={!civicNumber ? translate(RentalUnitCreateContentDict.required) : ' '} label={translate(RentalUnitCreateContentDict.civicNumber)} data-cy="CivicNumberInput" />
                <TextField fullWidth value={street || ''} onChange={(event) => setStreet(event.target.value)} sx={{ ml: 1, minWidth: 170 }} helperText={!street ? translate(RentalUnitCreateContentDict.required) : ' '} label={translate(RentalUnitCreateContentDict.street)} data-cy="StreetInput" />
            </Box>
            <TextField fullWidth inputProps={{ maxLength: 50 }} value={city || ''} onChange={(event) => setCity(event.target.value)} sx={{ mb: 1 }} helperText={!city ? translate(RentalUnitCreateContentDict.required) : ' '} label={translate(RentalUnitCreateContentDict.city)} data-cy="CityInput" />
            <TextField fullWidth inputProps={{ maxLength: 50 }} value={province || ''} onChange={(event) => setProvince(event.target.value)} sx={{ mb: 2 }} helperText={!province ? translate(RentalUnitCreateContentDict.required) : ' '} label={translate(RentalUnitCreateContentDict.province)} data-cy="ProvinceInput" />
            <FormControl fullWidth >
                <InputLabel id='select-manager-label'>{translate(RentalUnitCreateContentDict.manager)}</InputLabel>
                <Select
                    label={translate(RentalUnitCreateContentDict.manager)}
                    labelId='select-manager-label'
                    id='select-manager'
                    value={managerId || ''}
                    onChange={(event) => setManagerId(event.target.value)}
                    data-cy="ManagerList"
                >
                    <MenuItem value={undefined}> <em>{translate(RentalUnitCreateContentDict.none)}</em> </MenuItem>
                    {managers?.map((manager) => (<MenuItem value={manager.id} key={manager.id}>{manager.firstName} {manager.lastName}</MenuItem>))}
                </Select>
                <FormHelperText>{' '}</FormHelperText>
            </FormControl>
            {selectedManager ? (
                <>
                    <Box mb={1} display="flex" alignItems="baseline">
                        <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                            {translate(RentalUnitCreateContentDict.email)}
                        </Typography>
                        <Typography variant='body1' data-cy="ManagerEmail">{selectedManager?.email}</Typography>
                    </Box>
                    {selectedManager && selectedManager?.phones.map((phone, index) => (
                        <Box mb={index === (selectedManager.phones.length - 1) ? 3 : 1} key={phone} display="flex" alignItems='baseline'>
                            <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                                {translate(RentalUnitCreateContentDict.phone, [(index + 1).toString()])}
                            </Typography>
                            <Typography variant={index === 0 ? 'h6' : 'body1'} data-cy={"CleaningManagerPhone_" + index.toString}>{phone}</Typography>
                        </Box>
                    )
                    )}
                </>
            ) : null}
            <FeatureInputField
                features={features}
                featureList={featureList}
                setFeatureList={setFeatureList}
            />
            <TextField sx={{ mt: 2 }} fullWidth inputProps={{ maxLength: 1000 }} value={notes || ''} onChange={(event) => setNotes(event.target.value)} helperText={' '} label={translate(RentalUnitCreateContentDict.notes)} multiline rows={8} data-cy="NotesInput" />
            <Paper elevation={2} sx={{ backgroundColor: '#FAFAFA', marginLeft: -2, width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', p: 1, zIndex: '2' }}>
                <Box display="flex" justifyContent="flex-end">
                    <Button sx={{ mr: 1 }} onClick={() => setConfirmCancellationDlgOpenInfo({ open: true })} size="medium" variant="contained" data-cy="CancelAction">
                        {translate(RentalUnitCreateContentDict.cancel)}
                    </Button>
                    <Button disabled={!name || !street || !city || !civicNumber || !province || !featureValidation(featureList)} size="medium" onClick={submitRentalUnitForm} variant="contained" data-cy="CreateAction">
                        {translate(RentalUnitCreateContentDict.create)}
                    </Button>
                </Box>
            </Paper>
            <ConfirmCancellationDialog openInfo={confirmCancellationDlgOpenInfo} setOpenInfo={setConfirmCancellationDlgOpenInfo} />
        </ViewLayout>
    );
}



export default RentalUnitCreate;