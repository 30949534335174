import { useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import useViewNavUXStateStack from '../../utilities/hooks/useViewNavUXStateStack';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import rentalUnitsAdapter, { IRentalUnit } from './Adapters/rentalUnitsAdapter';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';

const rentalUnitsDict = {
  rentalUnits: { en: 'Rental Units', fr: `Unités de location` },
  employeeNumber: { en: 'Employee number', fr: `Numéro d'employé` },
  inActive: { en: 'Inactive', fr: `Inactif` },
  cleaningManager: { en: 'Cleaning Manager : ', fr: `Superviseur des ménages : ` },
  dirty: { en: 'DIRTY', fr: 'SALE' },
  newJobButton: { en: 'New Job', fr: 'Nouvelle tâche' },
  noCleaningManagerAssigned: { en: 'No cleaning manager assigned.', fr: `Aucun superviseur des ménages n'a été assigné.` }
};

const RentalUnits = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const apiClient = useApiClient();

  const history = useSafeHistory();

  const translate = useTranslator();

  const [rentalUnitsNavUXState, pushRentalUnitsNavUXState] = useViewNavUXStateStack<number>();

  const [rentalUnitsListContent, setRentalUnitListContent] = useState<IRentalUnit[] | undefined>(undefined);

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

  const [viewFullDataLoadTimeStamp, setViewFullDataLoadTimeStamp] = useState<number | undefined>(undefined);

  const viewScrollInfo = useRef(rentalUnitsNavUXState ? rentalUnitsNavUXState : 0);

  useEffect(() => {

    window.scrollTo(0, viewScrollInfo.current);
  }, [viewFullDataLoadTimeStamp]);

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {

    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    rentalUnitsAdapter
      .getAllRentalUnits(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
      .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
        setRentalUnitListContent(value);
        setViewLoadingState(LoadingState.None);
        setViewFullDataLoadTimeStamp(Date.now);
      }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const rentalUnitCreate = () => {
    history.push('/rentalunitcreate');

    viewScrollInfo.current = window.scrollY;
    pushRentalUnitsNavUXState(viewScrollInfo.current);
  };

  const gotoRentalUnit = (rentalUnitId: string) => {
    history.push(`/rentalunit/${rentalUnitId}`);

    viewScrollInfo.current = window.scrollY;
    pushRentalUnitsNavUXState(viewScrollInfo.current);
  };

  const unexpectedLoadingErrorReload = () => {
    viewScrollInfo.current = 0;
    getAllViewData(shouldSetStatesBeCancelledFn);
  };

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  return (
    <ViewLayout viewTitle={translate(rentalUnitsDict.rentalUnits)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} fab={{ iconName: 'add', fabAction: rentalUnitCreate }}>
      {rentalUnitsListContent?.map((rentalUnit) => (
        <Card key={rentalUnit.id} sx={{ m: 1 }}>
          <CardActionArea onClick={() => gotoRentalUnit(rentalUnit.id)} >
            <CardContent>
              {!rentalUnit.isActive ? (
                <Typography variant="body2" color="primary.dark">
                  {translate(rentalUnitsDict.inActive)}
                </Typography>
              ) : ''}
              {rentalUnit.isDirty ? (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="baseline">
                  <Box>
                    <Typography variant="h6">{rentalUnit.name}</Typography>
                  </Box>
                  <Box sx={{ flexGrow: 0, flexShrink: 0, lineHeight: 1 }}>
                    <Typography variant="h4">{translate(rentalUnitsDict.dirty)}</Typography>
                  </Box>
                </Box>
              ) : (
                <Typography variant="h6">{rentalUnit.name}</Typography>
              )}
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                {rentalUnit.civicNumber} {rentalUnit.street} {rentalUnit.city} {rentalUnit.province}
              </Typography>
              {rentalUnit.manager ? (
                <>
                  <Typography variant="body2">
                    {translate(rentalUnitsDict.cleaningManager)} {rentalUnit.manager.name}
                  </Typography>
                  <Typography variant="body2">{rentalUnit.manager.email}</Typography>
                  {rentalUnit.manager.phones.map((phone) => (
                    <Typography key={phone} variant="body2">
                      {phone}
                    </Typography>
                  ))}
                </>
              ) : (
                <Typography variant="body2">
                  {translate(rentalUnitsDict.cleaningManager)} {translate(rentalUnitsDict.noCleaningManagerAssigned)}
                </Typography>)}
            </CardContent>
          </CardActionArea>{rentalUnit.isActive ? (<CardActions disableSpacing={true}> <Button color='secondary' onClick={() => history.push(`/GeneralJobCreate?rentalUnitId=${rentalUnit.id}`)} data-cy='NewJobAction'>{translate(rentalUnitsDict.newJobButton)}</Button></CardActions>) : null}
        </Card>
      ))}
    </ViewLayout>
  );
};
export default RentalUnits;

