const CleaningworkloadDict = {
    cleaningWorkload: { en: 'Cleaning Workload', fr: 'Charge de travail de nettoyage' },
    tomorrow: { en: 'Tomorrow', fr: 'Demain' },
    noUnitToClean: { en: 'No unit to clean', fr: `Aucune unité à nettoyer` },
    unitToClean: { en: '1 unit to clean', fr: `1 unité à nettoyer` },
    unitsToClean: { en: 'units to clean', fr: `unités à nettoyer` },
    to: { en: 'to', fr: 'au' },
    nextWeek: { en: `Next week`, fr: `Semaine prochaine` },
    weekDays: { en: 'Street', fr: 'Rue' },
    nextMonth: { en: 'Next Month', fr: 'Mois prochain' },
};

export default CleaningworkloadDict;
