import { useContext } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import LoadingState from '../../../components/LoadingState';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { stringifyApiDate } from '../../../utilities/apiDates';
import useApiClient from '../../../utilities/hooks/useApiClient';
import useSafeHistory from '../../../utilities/hooks/useSafeHistory';
import rentalUnitAdapter, { IRentalUnit } from '../Adapters/rentalUnitAdapters';

export interface DeactivationDialogProps {
  openInfo: { open: boolean } | undefined;
  setOpenInfo: (openInfo: { open: boolean } | undefined) => void;
  rentalUnit: IRentalUnit | undefined;
  setViewLoadingState: (loadingState: LoadingState) => void;
}

const DeactivationDialogDict = {
  DeactivationDlgTitle: { en: 'Deactivate Rental Unit	', fr: `Désactiver l'unité de location` },
  confirmationDeactivationDlgText: { en: 'Please confirm the deactivation of rental unit {0}', fr: `Veuillez confirmer la désactivation de l'unité de location {0}` },
  confirmationRefusalDlgYesActionLbl: { en: 'CONFIRM', fr: 'CONFIRMER' },
  confirmationRefusalDlgNoActionLbl: { en: 'CANCEL', fr: 'ANNULER' },
};

const DeactivationDialog = (props: DeactivationDialogProps) => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const apiClient = useApiClient();
  const translate = useTranslator();
  const history = useSafeHistory();

  const cancelDeactivationDialog = () => {
    props.setOpenInfo({ open: false });
  };

  const currentDate = new Date();
  const date = stringifyApiDate(currentDate);


  const handleDeactivate = () => {

    props.setViewLoadingState(LoadingState.ModalWithVisibleContent);

    rentalUnitAdapter
      .deactivateRentalUnit(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, props.rentalUnit?.aggregateTimeStamp!, props.rentalUnit?.id!, { deactivationDate: date })
      .then(() => history.goBack())
      .catch(() => props.setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };
  return (
    <Dialog fullWidth onClose={() => { }} open={!!props.openInfo && props.openInfo.open} keepMounted>
      <DialogTitle data-cy="DeactivateDialogTitle">{translate(DeactivationDialogDict.DeactivationDlgTitle)}</DialogTitle>
      <DialogContent>
        <DialogContentText data-cy="DeactivateDialogMessage">
          {translate(DeactivationDialogDict.confirmationDeactivationDlgText, [props.rentalUnit?.name!])}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancelDeactivationDialog} data-cy="DeactivateDialogCancelAction">
          {translate(DeactivationDialogDict.confirmationRefusalDlgNoActionLbl)}
        </Button>
        <Button onClick={handleDeactivate} data-cy="DeactivateDialogConfirmAction">
          {translate(DeactivationDialogDict.confirmationRefusalDlgYesActionLbl)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivationDialog;
