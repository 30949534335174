import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import employeeAdapter, { IEmployee } from './Adapters/employeeAdapter';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useTranslator from '../../utilities/hooks/useTranslator';
import useLocaleFormater from '../../utilities/hooks/useLocaleFormater';
import { parseApiDate } from '../../utilities/apiDates';
import { isApiConcurrencyError } from '../../utilities/IApiConcurrencyError';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import specialitiesAdapter, { ISpecialty } from './Adapters/specialitiesAdapter';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import ConfirmCancellationDialog from './components/ConfirmCancellationDialog';
import DeactivationDialog from './components/DeactivationDialog';
import PhoneInputField from './components/PhoneInputField'
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';

const EmployeeUpdateContentDict = {
  title: { en: 'Update Employee', fr: `Mettre à jour l'employé` },
  status: { en: 'Status', fr: `Statut` },
  employeeNumber: { en: 'Number', fr: `Numéro` },
  firstName: { en: 'First Name', fr: 'Prénom' },
  lastName: { en: 'Last Name', fr: 'Nom' },
  required: { en: '*Required', fr: '*Requis' },
  nickname: { en: 'Nickname', fr: 'Surnom' },
  email: { en: 'Email', fr: 'Courriel' },
  phone: { en: 'Phone', fr: 'Téléphone' },
  specialities: { en: 'Specialities', fr: 'Spécialités' },
  notes: { en: 'Notes', fr: 'Notes' },
  deactivate: { en: 'DEACTIVATE', fr: 'DÉSACTIVER' },
  cancel: { en: 'CANCEL', fr: 'ANNULER' },
  update: { en: 'UPDATE', fr: 'MODIFIER' },
  activeSince: { en: 'Active : {0}', fr: 'Actif : {0}' },
  isManager: { en: 'Is Manager', fr: `Est superviseur` },
  invalidFormat: { en: 'Format not valid', fr: `Format non valide` },
  updationConcurrencyError: { en: "Employee has been modified by another user. These changes cannot be applied. Please cancel your changes.", fr: "L'employé a été modifié par un autre utilisateur. Les modifications ne pourront être appliquées. Veuillez annuler vos modifications." },
  updationInternalError: { en: "Internal error while trying to update the employee. Please retry.", fr: "Erreur interne lors de la sauvegarde de l'employé. Veuillez réessayer." }
};

type EmployeeUpdateParams = { employeeid: string }

const EmployeeUpdate = () => {

  const translate = useTranslator();

  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
  const [specialities, setSpecialities] = useState<ISpecialty[] | undefined>(undefined);

  const [confirmCancellationDlgOpenInfo, setConfirmCancellationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);
  const [DeactivationDlgOpenInfo, setDeactivationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);

  const [employeeDetails, setEmployeeDetails] = useState<IEmployee | undefined>(undefined);

  const [isManager, setIsManager] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string>('');
  const [nickname, setNickname] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const [validEmail, setValidEmail] = useState<string | undefined>(undefined);
  const [specialityIds, setSpecialityIds] = useState<string[]>([]);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [phoneList, setPhoneList] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<boolean>(false);

  const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);

  const openCancelDialogCbk = useCallback(() => setConfirmCancellationDlgOpenInfo({ open: true }), [setConfirmCancellationDlgOpenInfo]);

  const apiClient = useApiClient();
  const formater = useLocaleFormater();
  const history = useSafeHistory();

  const { employeeid } = useParams<EmployeeUpdateParams>();

  const safeSetUserMessageContent = (userMessageContent: string) => !shouldSetStatesBeCancelledFn() && setUserMessage(createMessageWithContent(userMessageContent));

  const getAllViewData = !employeeid ? undefined : (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    Promise.all([
      employeeAdapter.getEmployee(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, employeeid),
      specialitiesAdapter.getAllSpecialties(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
    ])
      .then((values) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
        setViewLoadingState(LoadingState.None);
        setEmployeeDetails(values[0]);
        setFirstName(values[0].firstName);
        setLastName(values[0].lastName);
        setNickname(values[0].nickname);
        setEmail(values[0].email);
        setValidEmail(values[0].email);
        setPhoneList([...values[0].phones, '']);
        setSpecialityIds(values[0].specialtyIds);
        setNotes(values[0].notes);
        setIsManager(values[0].isManager);
        setSpecialities(values[1]);
      }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const unexpectedLoadingErrorReload = !getAllViewData ? undefined : () => getAllViewData(shouldSetStatesBeCancelledFn);

  const handleUpdate = () => {

    var phoneListToUpdate = [...phoneList];
    phoneListToUpdate.pop();

    employeeDetails!.firstName = firstName!;
    employeeDetails!.lastName = lastName!;
    employeeDetails!.nickname = nickname!;
    employeeDetails!.email = validEmail!;
    employeeDetails!.phones = phoneListToUpdate;
    employeeDetails!.specialtyIds = specialityIds;
    employeeDetails!.notes = notes!;
    employeeDetails!.isManager = isManager!;

    setViewLoadingState(LoadingState.ModalWithVisibleContent);

    employeeAdapter
      .updateEmployee(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, employeeDetails!.id, employeeDetails!, true)
      .then(() => history.goBack())
      .catch((error: any) => {
        if (isApiConcurrencyError(error))
          safeSetUserMessageContent(translate(EmployeeUpdateContentDict.updationConcurrencyError));
        else
          safeSetUserMessageContent(translate(EmployeeUpdateContentDict.updationInternalError));
      })
      .finally(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.None));
  }

  const getFormattedDate = (dateToFormat: string) => formater.formatDate(parseApiDate(dateToFormat), { year: 'numeric', month: 'numeric', day: 'numeric', });

  const handleEmailValidation = (event: ChangeEvent<HTMLInputElement>) => {

    setEmail(event.target.value);

    const EMAIL_REGEX = new RegExp('^[A-Za-z0-9._%+-]{1,64}@(?:[A-Za-z0-9-]{1,63}\\.){1,125}[A-Za-z]{2,63}$');

    if (EMAIL_REGEX.test(event.target.value)) {
      setValidEmail(event.target.value);
      setEmailError(false);
    } else {
      setEmailError(true);
      setValidEmail('');
    }

  };

  const handleSpecialityChange = (event: ChangeEvent<HTMLInputElement>) => {

    const index = specialityIds.indexOf(event.target.value);

    if (index === -1) setSpecialityIds([...specialityIds, event.target.value]);
    else setSpecialityIds(specialityIds.filter((specialityIds) => specialityIds !== event.target.value));

  };

  const phonesValidation = (phoneListToValidate: string[]): boolean => {
    const validatedPhones = phoneListToValidate.filter((phones) => phones !== '');
    return validatedPhones.every((phone) => phone.length === 13) && phoneListToValidate.length > 1;
  }

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  return (
    <ViewLayout contentBoxPadding={2} contentBoxPaddingBottom={8} viewTitle={translate(EmployeeUpdateContentDict.title)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} childViewBackOverride={openCancelDialogCbk} isChildView={true} userMessageInfo={{ userMessage: userMessage, setUserMessage: setUserMessage }} >
      {employeeDetails && <>
        <Box display="flex">
          <TextField fullWidth disabled value={(translate(EmployeeUpdateContentDict.activeSince, [!!employeeDetails ? employeeDetails?.creationDate! ? getFormattedDate(employeeDetails?.creationDate!) : getFormattedDate(employeeDetails?.reactivationDate!) : ''])) || ''} sx={{ mr: 1, minWidth: 170 }} label={translate(EmployeeUpdateContentDict.status)} data-cy="StatusInput" />
          <TextField disabled value={employeeDetails?.employeeNumber || ''} label={translate(EmployeeUpdateContentDict.employeeNumber)} data-cy="EmployeeNumberInput" />
        </Box>
        <FormControlLabel
          label={translate(EmployeeUpdateContentDict.isManager)}
          sx={{ my: 1 }}
          control={<Checkbox color="secondary" checked={isManager} onChange={(event) => setIsManager(event.target.checked)} size="small" />}
        />
        <Box display="flex" my={1}>
          <TextField fullWidth inputProps={{ maxLength: 50 }} value={firstName || ''} onChange={(event) => setFirstName(event.target.value)} sx={{ mr: 1 }} helperText={!firstName ? translate(EmployeeUpdateContentDict.required) : ' '} label={translate(EmployeeUpdateContentDict.firstName)} data-cy="FirstNameInput" />
          <TextField fullWidth inputProps={{ maxLength: 50 }} value={lastName || ''} onChange={(event) => setLastName(event.target.value)} helperText={!lastName ? translate(EmployeeUpdateContentDict.required) : ' '} label={translate(EmployeeUpdateContentDict.lastName)} data-cy="LastNameInput" />
        </Box>
        <TextField fullWidth inputProps={{ maxLength: 50 }} value={nickname || ''} onChange={(event) => setNickname(event.target.value)} helperText={' '} label={translate(EmployeeUpdateContentDict.nickname)} sx={{ mb: 1 }} data-cy="NicknameInput" />
        <TextField fullWidth type='email' value={email || ''} error={(emailError && !!email)} onChange={handleEmailValidation} helperText={emailError && !!email ? translate(EmployeeUpdateContentDict.invalidFormat) : !email ? translate(EmployeeUpdateContentDict.required) : ' '} sx={{ mb: 1 }} label={translate(EmployeeUpdateContentDict.email)} data-cy="EmailInput" />
        <PhoneInputField
          phoneList={phoneList}
          setPhoneList={setPhoneList}
        />
        <FormGroup sx={{ m: 2 }}>
          <Typography color="text.secondary">{translate(EmployeeUpdateContentDict.specialities)}</Typography>
          {specialities?.map((speciality, index) => (
            <FormControlLabel key={speciality.id} control={<Checkbox checked={specialityIds.includes(speciality.id)} onChange={handleSpecialityChange} size="small" color='secondary' data-cy={"SpecialtyCheckbox_" + index.toString()} />} label={speciality.name} value={speciality.id}
            />
          ))}
        </FormGroup>
        <TextField fullWidth inputProps={{ maxLength: 1000 }} value={notes || ''} onChange={(event) => setNotes(event.target.value)} helperText={' '} label={translate(EmployeeUpdateContentDict.notes)} multiline rows={3} data-cy="NotesInput" />
        <Paper elevation={2} sx={{ backgroundColor: '#FAFAFA', marginLeft: -2, width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', p: 1, zIndex: '2' }}>
          <Box display="flex" justifyContent='space-between'>
            <Button onClick={() => setDeactivationDlgOpenInfo({ open: true })} size="medium" variant="contained" data-cy="DeactivateAction">
              {translate(EmployeeUpdateContentDict.deactivate)}
            </Button>
            <Box>
              <Button sx={{ mr: 1, justifyContent: 'flex-end' }} onClick={() => setConfirmCancellationDlgOpenInfo({ open: true })} size="medium" variant="contained" data-cy="CancelAction">
                {translate(EmployeeUpdateContentDict.cancel)}
              </Button>
              <Button disabled={!firstName || !lastName || !phonesValidation(phoneList) || !validEmail || specialityIds.length === 0} size="medium" onClick={handleUpdate} variant="contained" data-cy="UpdateAction">
                {translate(EmployeeUpdateContentDict.update)}
              </Button>
            </Box>
          </Box>
        </Paper>
      </>
      }
      <ConfirmCancellationDialog
        openInfo={confirmCancellationDlgOpenInfo}
        setOpenInfo={setConfirmCancellationDlgOpenInfo}
        employeeDetails={employeeDetails} />
      <DeactivationDialog
        openInfo={DeactivationDlgOpenInfo}
        setOpenInfo={setDeactivationDlgOpenInfo}
        employeeDetails={employeeDetails}
        setViewLoadingState={setViewLoadingState} />
    </ViewLayout>
  );
};

export default EmployeeUpdate;
