import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ITasks } from '../Adapters/propertyMgmtOrgsAdapter';

export interface IListTabContentProps {
  allTasks: ITasks[];
}

const truncateDescription = (description: string) => (description.length > 80 ? `${description.substring(0, 80)}...` : description);

const ListTabContent = (props: IListTabContentProps) => {
  return !props.allTasks ? null : (
    <List sx={{ width: '100', bgcolor: 'background.paper', p: 1 }}>
      {props.allTasks.map((task, index) => (
        <React.Fragment key={task.title}>
          <ListItem sx={{maxWidth:380}} alignItems="flex-start">
            <ListItemText
              data-cy={'TaskName_' + index.toString()}
              primary={task.title}
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" data-cy={'TaskDescription_' + index.toString()}>
                    {truncateDescription(task.description)}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};
export default ListTabContent;
