import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningJobEmployee {
  id: string;
  status: 'Assigned' | 'Confirmed' | 'Refused' | 'Removed' | 'Working' | 'Done';
}
export interface IReservation {
  id: string;
  aggregateTimeStamp: string;
  startDate: string;
  endDate: string;
  rentalUnit: {
    name: string;
    civicNumber: string;
    street: string;
    city: string;
    province: string;
  };
  cleaningJob: {
    status: 'ToBeAssigned' | 'ToBeConfirmed' | 'ToDo' | 'InProgress' | 'Done';
    employees: ICleaningJobEmployee[];
    mustBeCompletedBeforeDate: string;
  };
  nextReservation?: {
    startDate: string;
  };
}
export interface IConfirmEmployeeOnCleaningJobDTO {
  targetEmployeeUniqueUserId: string;
}

export interface IRefuseEmployeeOnCleaningJobDTO {
  targetEmployeeUniqueUserId: string;
}

export interface IEmployeeStopsWorkingOnCleaningJobDTO {
  targetEmployeeUniqueUserId: string;
}

export interface IEmployeeCompletesCleaningJobDTO {
  targetEmployeeUniqueUserId: string;
}
export interface IEmployeeRestartsCleaningJobDTO {
  targetEmployeeUniqueUserId: string;
}

const reservationsAdapter = {
  getReservations: (apiClient: IApiClient, id: string, currentDateTime: string, employeeUniqueUserId: string) =>
    apiClient.get<IReservation[]>(
      '/reservations/' + id + '/forEmployeeCleaningJobs?periodStart=' + currentDateTime + '&employeeUniqueUserId=' + employeeUniqueUserId
    ),

  confirmEmployeeOnCleaningJob: (
    apiClient: IApiClient,
    propertyMgmtOrgId: string,
    reservationId: string,
    reservationTimeStamp: string,
    confirmEmployeeOnCleaningJobDTO: IConfirmEmployeeOnCleaningJobDTO,
    expectConcurrencyError: boolean
  ) =>
    apiClient.post<IReservation>(
      '/reservations/' +
        propertyMgmtOrgId +
        '/' +
        reservationId +
        '/ConfirmEmployeeOnCleaningJob?aggregateTimeStamp=' +
        reservationTimeStamp,
      confirmEmployeeOnCleaningJobDTO,
      expectConcurrencyError
    ),
  employeeRefuseOnCleaningJob: (
    apiClient: IApiClient,
    propertyMgmtOrgId: string,
    reservationId: string,
    reservationTimeStamp: string,
    refuseEmployeeOnCleaningJobDTO: IRefuseEmployeeOnCleaningJobDTO,
    expectConcurrencyError: boolean
  ) =>
    apiClient.post<IReservation>(
      '/reservations/' + propertyMgmtOrgId + '/' + reservationId + '/EmployeeRefusesCleaningJob?aggregateTimeStamp=' + reservationTimeStamp,
      refuseEmployeeOnCleaningJobDTO,
      expectConcurrencyError
    ),
  employeeStartsCleaningJob: (
    apiClient: IApiClient,
    propertyMgmtOrgId: string,
    reservationId: string,
    reservationTimeStamp: string,
    confirmEmployeeOnCleaningJobDTO: IConfirmEmployeeOnCleaningJobDTO,
    expectConcurrencyError: boolean
  ) =>
    apiClient.post<IReservation>(
      '/reservations/' +
        propertyMgmtOrgId +
        '/' +
        reservationId +
        '/EmployeeStartsWorkingOnCleaningJob?aggregateTimeStamp=' +
        reservationTimeStamp,
      confirmEmployeeOnCleaningJobDTO,
      expectConcurrencyError
    ),

  tempStopEmployeeOnCleaningJob: (
    apiClient: IApiClient,
    propertyMgmtOrgId: string,
    reservationId: string,
    reservationTimeStamp: string,
    tempStopEmployeeOnCleaningJobDTO: IEmployeeStopsWorkingOnCleaningJobDTO,
    expectConcurrencyError: boolean
  ) =>
    apiClient.post<IReservation>(
      '/reservations/' +
        propertyMgmtOrgId +
        '/' +
        reservationId +
        '/EmployeeStopsWorkingOnCleaningJob?aggregateTimeStamp=' +
        reservationTimeStamp,
      tempStopEmployeeOnCleaningJobDTO,
      expectConcurrencyError
    ),

  employeeCompletesCleaningJob: (
    apiClient: IApiClient,
    propertyMgmtOrgId: string,
    reservationId: string,
    reservationTimeStamp: string,
    employeeCompletesCleaningJobDTO: IEmployeeCompletesCleaningJobDTO,
    expectConcurrencyError: boolean
  ) =>
    apiClient.post<IReservation>(
      '/reservations/' +
        propertyMgmtOrgId +
        '/' +
        reservationId +
        '/EmployeeCompletesCleaningJob?aggregateTimeStamp=' +
        reservationTimeStamp,
      employeeCompletesCleaningJobDTO,
      expectConcurrencyError
    ),
  employeeRestartsCleaningJob: (
    apiClient: IApiClient,
    propertyMgmtOrgId: string,
    reservationId: string,
    reservationTimeStamp: string,
    employeeRestartsCleaningJobDTO: IEmployeeRestartsCleaningJobDTO,
    expectConcurrencyError: boolean
  ) =>
    apiClient.post<IReservation>(
      '/reservations/' +
        propertyMgmtOrgId +
        '/' +
        reservationId +
        '/EmployeeRestartsWorkingOnCleaningJob?aggregateTimeStamp=' +
        reservationTimeStamp,
      employeeRestartsCleaningJobDTO,
      expectConcurrencyError
    )
};

export default reservationsAdapter;
