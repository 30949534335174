import { unstable_batchedUpdates } from 'react-dom';
import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { FormHelperText, InputLabel, MenuItem } from '@mui/material';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';
import FeatureInputField from './components/FeatureInputField';
import ConfirmCancellationDialog from './components/ConfirmCancellationDialog';
import DeactivationDialog from './components/DeactivationDialog';
import useTranslator from '../../utilities/hooks/useTranslator';
import { isApiConcurrencyError } from '../../utilities/IApiConcurrencyError';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import propertyMgmtOrgsAdapter from './Adapters/propertyMgmtOrgsAdapter';
import rentalUnitAdapter, { ICleaningManager } from './Adapters/rentalUnitAdapters';
import employeesAdapter, { IEmployee } from './Adapters/employeesAdapter';
import { IRentalUnit } from './Adapters/rentalUnitAdapters';
import RentalUnitUpdateDict from './RentalUnitUpdateDict';
import { isApiBusinessLogicErrorWithCode } from '../../utilities/IApiBusinessLogicError';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';

export interface IFeature {
  name: string;
  value: string;
}

type RentalUnitChildParams = { rentalunitid: string }

const RentalUnitUpdate = () => {

  const translate = useTranslator();

  const apiClient = useApiClient();

  const history = useSafeHistory();

  const { rentalunitid } = useParams<RentalUnitChildParams>();

  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

  const [rentalUnit, setRentalUnit] = useState<IRentalUnit | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [civicNumber, setCivicNumber] = useState<string | undefined>(undefined);
  const [street, setStreet] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [province, setProvince] = useState<string | undefined>(undefined);
  const [features, setFeatures] = useState<string[]>([]);
  const [featureList, setFeatureList] = useState<IFeature[]>([{ name: '', value: '' }]);
  const [employees, setEmployees] = useState<IEmployee[] | undefined>(undefined);
  const [managerId, setManagerId] = useState<string | undefined>(undefined);

  const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);

  const [confirmCancellationDlgOpenInfo, setConfirmCancellationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);
  const [DeactivationDlgOpenInfo, setDeactivationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);

  const openCancelDialogCbk = useCallback(() => setConfirmCancellationDlgOpenInfo({ open: true }), [setConfirmCancellationDlgOpenInfo]);

  const managers = employees?.filter((employee) => employee.isManager);

  const selectedManager = managers?.find((manager) => manager.uniqueUserId === managerId);

  const manager: ICleaningManager | undefined = !managerId || !selectedManager ? undefined : { uniqueUserId: selectedManager?.uniqueUserId!, email: selectedManager?.email!, name: selectedManager?.firstName! + ' ' + selectedManager?.lastName!, phones: selectedManager?.phones! };

  const getAllViewData = !rentalunitid ? undefined : (shouldSetStatesBeCancelledFn: () => boolean) => {

    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    Promise.all([
      propertyMgmtOrgsAdapter.getFeatures(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string),
      employeesAdapter.getAllEmployees(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string),
      rentalUnitAdapter.getRentalUnitById(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, rentalunitid)
    ])
      .then((values) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
        setViewLoadingState(LoadingState.None);
        setFeatures(values[0].features);
        setEmployees(values[1]);
        setRentalUnit(values[2]);
        setName(values[2].name);
        setCivicNumber(values[2].civicNumber);
        setStreet(values[2].street);
        if (values[2].manager) setManagerId(values[2].manager.uniqueUserId);
        setCity(values[2].city);
        setProvince(values[2].province);
        setFeatureList([...values[2].features, { name: '', value: '' }]);
        setNotes(values[2].notes);

      }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const unexpectedLoadingErrorReload = !getAllViewData ? undefined : () => getAllViewData(shouldSetStatesBeCancelledFn);

  const handleUpdate = () => {

    var featuresToUpdate = [...featureList];
    featuresToUpdate.pop();

    rentalUnit!.name = name!;
    rentalUnit!.civicNumber = civicNumber!;
    rentalUnit!.street = street!;
    rentalUnit!.city = city!;
    rentalUnit!.province = province!;
    rentalUnit!.manager = manager!;
    rentalUnit!.features = featuresToUpdate!;
    rentalUnit!.notes = notes!

    setViewLoadingState(LoadingState.ModalWithVisibleContent);

    rentalUnitAdapter.updateRentalUnit(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, rentalUnit!.id, rentalUnit!, true, ['ManagerMustExist'])
      .then(() => history.goBack())
      .catch((error: any) => {
        if (isApiConcurrencyError(error))
          setUserMessage(createMessageWithContent(translate(RentalUnitUpdateDict.updationConcurrencyError)));
        else if (isApiBusinessLogicErrorWithCode(error, 'ManagerMustExist'))
          setUserMessage(createMessageWithContent(translate(RentalUnitUpdateDict.managerMustExistErrorMessage)));
        else
          setUserMessage(createMessageWithContent(translate(RentalUnitUpdateDict.updationInternalError)));
      })
      .finally(() => {
        if (shouldSetStatesBeCancelledFn()) return;

        setViewLoadingState(LoadingState.None);

      });

  }

  const featureValidation = (featureListToValidate: IFeature[]): boolean => {

    const validatedFeatures = featureListToValidate.filter((feature) => feature.name);

    return validatedFeatures.every((feature) => feature.value.length !== 0) && featureListToValidate.length > 1;
  }

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  return (
    <ViewLayout contentBoxPadding={2} contentBoxPaddingBottom={8} viewTitle={translate(RentalUnitUpdateDict.title)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} userMessageInfo={{ userMessage: userMessage, setUserMessage: setUserMessage }} childViewBackOverride={openCancelDialogCbk} isChildView={true}>
      {rentalUnit && <>
        {rentalUnit?.isManagedExternally ? <Typography variant="h6" color="primary.dark" >{translate(RentalUnitUpdateDict.warning, [rentalUnit?.sourceName!])}</Typography> : null}
        <TextField fullWidth inputProps={{ maxLength: 50 }} value={name || ''} onChange={(event) => setName(event.target.value)} sx={{ mb: 1 }} helperText={!name ? translate(RentalUnitUpdateDict.required) : ' '} label={translate(RentalUnitUpdateDict.name)} data-cy="NameInput" />
        <Box display="flex" mb={1}>
          <TextField value={civicNumber || ''} onChange={(event) => setCivicNumber(event.target.value)} helperText={!civicNumber ? translate(RentalUnitUpdateDict.required) : ' '} label={translate(RentalUnitUpdateDict.civicNumber)} data-cy="CivicNumberInput" />
          <TextField fullWidth value={street || ''} onChange={(event) => setStreet(event.target.value)} sx={{ ml: 1, minWidth: 170 }} helperText={!street ? translate(RentalUnitUpdateDict.required) : ' '} label={translate(RentalUnitUpdateDict.street)} data-cy="StreetInput" />
        </Box>
        <TextField fullWidth inputProps={{ maxLength: 50 }} value={city || ''} onChange={(event) => setCity(event.target.value)} sx={{ mb: 1 }} helperText={!city ? translate(RentalUnitUpdateDict.required) : ' '} label={translate(RentalUnitUpdateDict.city)} data-cy="CityInput" />
        <TextField fullWidth inputProps={{ maxLength: 50 }} value={province || ''} onChange={(event) => setProvince(event.target.value)} sx={{ mb: 2 }} helperText={!province ? translate(RentalUnitUpdateDict.required) : ' '} label={translate(RentalUnitUpdateDict.province)} data-cy="ProvinceInput" />
        <FormControl fullWidth>
          <InputLabel id='select-manager-label'>{translate(RentalUnitUpdateDict.manager)}</InputLabel>
          <Select
            label={translate(RentalUnitUpdateDict.manager)}
            labelId='select-manager-label'
            id='select-manager'
            value={!selectedManager ? '' : managerId}
            onChange={(event) => setManagerId(event.target.value)}
            data-cy="ManagerList"
          >
            <MenuItem value={undefined}> <em>{translate(RentalUnitUpdateDict.none)}</em> </MenuItem>
            {managers?.map((manager) => (<MenuItem value={manager.uniqueUserId} key={manager.id}>{manager.firstName} {manager.lastName}</MenuItem>))}
          </Select>
          <FormHelperText>{' '}</FormHelperText>
        </FormControl>
        {selectedManager ? (
          <>
            <Box mb={1} display="flex" alignItems="baseline">
              <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                {translate(RentalUnitUpdateDict.email)}
              </Typography>
              <Typography variant='body1' data-cy="ManagerEmail">{selectedManager?.email}</Typography>
            </Box>
            {selectedManager && selectedManager?.phones.map((phone: string, index: number) => (
              <Box mb={index === (selectedManager.phones.length - 1) ? 3 : 1} key={phone} display="flex" alignItems="baseline">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                  {translate(RentalUnitUpdateDict.phone, [(index + 1).toString()])}
                </Typography>
                <Typography variant={index === 0 ? 'h6' : 'body1'} data-cy={"CleaningManagerPhone_" + index.toString}>{phone}</Typography>
              </Box>
            )
            )}
          </>
        ) : null}
        <FeatureInputField
          features={features}
          featureList={featureList}
          setFeatureList={setFeatureList}
        />
        <TextField sx={{ mt: 2 }} fullWidth inputProps={{ maxLength: 1000 }} value={notes || ''} onChange={(event) => setNotes(event.target.value)} helperText={' '} label={translate(RentalUnitUpdateDict.notes)} multiline rows={8} data-cy="NotesInput" />
        <Paper elevation={2} sx={{ backgroundColor: '#FAFAFA', marginLeft: -2, width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', p: 1, zIndex: '2' }}>
          <Box display="flex" justifyContent='space-between'>
            <Button onClick={() => setDeactivationDlgOpenInfo({ open: true })} size="medium" variant="contained" data-cy="DeactivateAction">
              {translate(RentalUnitUpdateDict.deactivate)}
            </Button>
            <Box>
              <Button sx={{ mr: 1, justifyContent: 'flex-end' }} onClick={() => setConfirmCancellationDlgOpenInfo({ open: true })} size="medium" variant="contained" data-cy="CancelAction">
                {translate(RentalUnitUpdateDict.cancel)}
              </Button>
              <Button disabled={!name || !street || !city || !civicNumber || !province || !featureValidation(featureList)} size="medium" onClick={handleUpdate} variant="contained" data-cy="CreateAction">
                {translate(RentalUnitUpdateDict.update)}
              </Button>
            </Box>
          </Box>
        </Paper>
      </>
      }
      <ConfirmCancellationDialog openInfo={confirmCancellationDlgOpenInfo} rentalUnit={rentalUnit} setOpenInfo={setConfirmCancellationDlgOpenInfo} />
      <DeactivationDialog openInfo={DeactivationDlgOpenInfo} setOpenInfo={setDeactivationDlgOpenInfo} rentalUnit={rentalUnit} setViewLoadingState={setViewLoadingState} />
    </ViewLayout>
  );
}



export default RentalUnitUpdate;