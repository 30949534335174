import { useLocation } from 'react-router-dom';
import { useRef } from 'react';

import logManager from '../logManager';

interface ViewUXState {
    viewLocationKey: string,
    viewSerializedState: string
}

const StorageLocationsUXStatesKey = 'ViewsUXStates';

const getViewUXStates = () => {

    const serializedViewsUXStates = sessionStorage.getItem(StorageLocationsUXStatesKey);

    return serializedViewsUXStates ? (JSON.parse(serializedViewsUXStates) as ViewUXState[]) : [];
}

const findLocationUXStateIndex = (locationKey: string, viewsUXStates: ViewUXState[]) => viewsUXStates.findIndex((value) => value.viewLocationKey === locationKey);

const saveUXStateInStorage = <TState>(locationKey: string, state: TState | undefined) => {

    const viewsUXStates = getViewUXStates();

    const viewUXStateIndex = findLocationUXStateIndex(locationKey, viewsUXStates);

    if (viewUXStateIndex !== -1)
        viewsUXStates.splice(viewUXStateIndex, 1);

    viewsUXStates.push({ viewLocationKey: locationKey, viewSerializedState: JSON.stringify(state) });

    if (viewsUXStates.length > 6)
        viewsUXStates.shift();

    sessionStorage.setItem(StorageLocationsUXStatesKey, JSON.stringify(viewsUXStates));
};

const useViewNavUXStateStack: <TState>() => [viewNavUXState: TState | undefined, pushViewNavUXState: (state?: TState) => void] = () => {

    const location = useLocation();

    const currentViewUXStateRef = useRef();

    if (!location.key)
        logManager.logInfo('Hook useViewNavUXStateStack used with undefined location key. Using default key value for location ' + location.pathname);

    const locationKey = location.key ?? 'default';

    if (!currentViewUXStateRef.current) {
        const viewsUXStates = getViewUXStates();

        const viewUXStateIndex = findLocationUXStateIndex(locationKey, viewsUXStates);

        currentViewUXStateRef.current = viewUXStateIndex === -1 ? undefined : (JSON.parse(viewsUXStates[viewUXStateIndex].viewSerializedState));
    }

    const pushViewNavUXState = <TState>(state?: TState) => saveUXStateInStorage(locationKey, state);

    return [currentViewUXStateRef.current, pushViewNavUXState];
}

export default useViewNavUXStateStack 