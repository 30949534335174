import { useContext, useState, useRef, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';

import useApiClient from '../../utilities/hooks/useApiClient';
import useTranslator from '../../utilities/hooks/useTranslator';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useViewNavUXStateStack from '../../utilities/hooks/useViewNavUXStateStack';

import specialtiesAdapter, { ISpecialty } from './Adapters/specialtiesAdapter';

const specialtiesDict = {
  title: { en: 'Specialties', fr: 'Spécialités' },
};

const Specialties = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const apiClient = useApiClient();
  const history = useSafeHistory()
  const translate = useTranslator();

  const [specialties, setSpecialties] = useState<ISpecialty[] | undefined>(undefined);
  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
  const [specialtiesNavUXState, pushSpecialtiesNavUXState] = useViewNavUXStateStack<number>();
  const [viewFullDataLoadTimeStamp, setViewFullDataLoadTimeStamp] = useState<number | undefined>(undefined);

  const viewScrollInfo = useRef(specialtiesNavUXState ? specialtiesNavUXState : 0);

  useEffect(() => { window.scrollTo(0, viewScrollInfo.current);}, [viewFullDataLoadTimeStamp]);
  
  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    specialtiesAdapter
      .getAllSpecialties(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
      .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => { 
                       setSpecialties(value); 
                       setViewLoadingState(LoadingState.None);
                       setViewFullDataLoadTimeStamp(Date.now);}))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const gotoSpecialty = (specialtyId: string) => {
    history.push(`/specialty/${specialtyId}`);
    viewScrollInfo.current = window.scrollY;
    pushSpecialtiesNavUXState(viewScrollInfo.current);
  };

  const specialtyCreate = () => {
    history.push(`/specialtycreate`);
    viewScrollInfo.current = window.scrollY;
    pushSpecialtiesNavUXState(viewScrollInfo.current);
  };

  const unexpectedLoadingErrorReload = () => {
    viewScrollInfo.current = 0;
    getAllViewData(shouldSetStatesBeCancelledFn);
  };

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  return  (
    <ViewLayout viewTitle={translate(specialtiesDict.title)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} fab={{ iconName: 'add', fabAction: specialtyCreate }}>
      {specialties?.map((specialty, index) => (
        <Card key={specialty.id} sx={{m:1}} data-cy={'Specialty_' + index}>
          <CardActionArea onClick={() => gotoSpecialty(specialty.id)}>
            <CardContent>
              <Typography variant="h6" component="h6" sx={{mb:1}} data-cy="Name">{specialty.name}</Typography>
              <Typography variant="body2" data-cy="Description">{specialty.description}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </ViewLayout>
  );
};
  
export default Specialties;