
import { BarChart, Bar, XAxis, ResponsiveContainer } from 'recharts';
import { Box } from '@mui/material';
import useTranslator from '../../../utilities/hooks/useTranslator';

export interface INextWeekChartProps {
    noOfUnitsToClean: number[] | undefined;
}

const nextWeekChartDict = {
    Monday: { en: 'Mo', fr: 'Lu' },
    Tuesday: { en: 'Tu', fr: 'Ma' },
    Wednesday: { en: 'We', fr: 'Me' },
    Thursday: { en: 'Th', fr: 'Je' },
    Friday: { en: 'Fr', fr: 'Ve' },
    Saturday: { en: 'Sa', fr: 'Sa' },
    Sunday: { en: 'Su', fr: 'Di' }
    
}

const NextWeekChart = (props: INextWeekChartProps) => {

    const translate = useTranslator();

    let weekDays: string[] = [
        translate(nextWeekChartDict.Monday),
        translate(nextWeekChartDict.Tuesday),
        translate(nextWeekChartDict.Wednesday),
        translate(nextWeekChartDict.Thursday),
        translate(nextWeekChartDict.Friday),
        translate(nextWeekChartDict.Saturday),
        translate(nextWeekChartDict.Sunday)
    ];

    const data = props.noOfUnitsToClean?.map((noOfUnitsToCleanOnWeekday, index) => ({ units: noOfUnitsToCleanOnWeekday, day: weekDays[index] }));

    return (
        <Box mt={1} border={1}>
            <ResponsiveContainer width="100%" height={240}>
                <BarChart data={data}>
                    <XAxis tick={{ fontSize: 10 }} dataKey="day" />
                    <Bar barSize={25} dataKey="units" fill="#C13600" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};
export default NextWeekChart;
