import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import useTranslator from '../../utilities/hooks/useTranslator';
import useViewNavUXStateStack from '../../utilities/hooks/useViewNavUXStateStack';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';

interface IBookingsViewState {
    info: string
}

const bookingsDict = {
    title: { en: 'Bookings View', fr: 'Vue Réservations' }
}

const Bookings = () => {

    const [bookingsNavUXState, pushBookingsNavUXState] = useViewNavUXStateStack<IBookingsViewState>();

    const ctx = useContext(PropertyMgmtMobileAppContext);

    const history = useHistory()

    const translate = useTranslator(); //This a test view, only the view title will be translated.

    const [message, setMessage] = useState<IUserMessage | undefined>(createMessageWithContent('This is a long test message that is shown when opening view. This message should be displayed using multiple lines.'));

    const [dialogOpened, setDialogOpened] = useState<boolean>(false);

    const gotoReservationsChildTest = () => {

        pushBookingsNavUXState({ info: 'Timestamp ' + Date.now() });

        history.push('/bookingschild/524b3bcb-5bf8-421b-b26a-b2b5fea9a64f?displaytype=simple');
    }

    return (
        <ViewLayout userMessageInfo={{ userMessage: message, setUserMessage: setMessage }} >
            <Box p={2}>
                <Typography variant='h5' component='div'>{translate(bookingsDict.title)}</Typography>
                <Typography variant='body1' component='p' mt={2} sx={{ overflowWrap: 'break-word' }}>PromertyMgmtApp Context : {JSON.stringify(ctx, undefined, 4)}</Typography>
                <Typography variant='body1' component='p' mt={2} sx={{ overflowWrap: 'break-word' }}>Child View Back State Context : {bookingsNavUXState?.info}</Typography>
                <Button variant='contained' color='secondary' sx={{ display: 'block', mt: 2, mb: 2 }} onClick={gotoReservationsChildTest}>Goto Bookings Child View</Button>
                <Button variant='contained' color='secondary' sx={{ display: 'block', mb: 2 }} onClick={() => setMessage(createMessageWithContent('This is a new message.'))}>Display new message</Button>
                <Button variant='contained' color='secondary' sx={{ display: 'block' }} onClick={() => { setDialogOpened(true); }}>Test Dialog</Button>
            </Box>
            <Dialog fullWidth onClose={() => { }} open={dialogOpened} keepMounted>
                <DialogTitle>Test Dialog</DialogTitle>
                <DialogContent>
                    <DialogContentText>This is a test modal dialog box.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialogOpened(false)}>Cancel</Button>
                    <Button onClick={() => setDialogOpened(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </ViewLayout >
    )
}

export default Bookings 