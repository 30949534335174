import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import LoadingState from '../../../components/LoadingState';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import useApiClient from '../../../utilities/hooks/useApiClient';
import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import useLocaleFormater from '../../../utilities/hooks/useLocaleFormater';
import { parseApiDate } from '../../../utilities/apiDates';

export interface ConfirmRefusalDialogProps {
  openInfo: { open: boolean; reservation: IReservation } | undefined;
  setOpenInfo: (openInfo: { open: boolean; reservation: IReservation } | undefined) => void;
  propertyMgmtOrg: IPropertyMgmtOrg;
  safeSetViewLoadingState: (loadingState: LoadingState) => void;
  refreshSingleReservationInList: (reservation: IReservation) => void;
  safeSetUserMessageContent: (messageContent: string) => void;
}

const ConfirmRefusalDialogDict = {
  confirmationRefusalDlgTitle: { en: 'Refuse Job', fr: 'Refuser le travail' },
  confirmationRefusalDlgTextBeforeJobName: {
    en: "I refuse the job on rental unit ",
    fr: "Je refuse le travail sur l'unité  "
  },
  confirmationRefusalDlgTextAfterJobName: { en: ' starting on {0} and that must be done before {1}.', fr: ' commençant le {0} et qui doit être terminé avant le {1}.' },
  confirmationRefusalDlgOkActionLbl: { en: 'Proceed', fr: 'Continuer' },
  confirmationRefusalDlgCancelActionLbl: { en: 'Cancel', fr: 'Annuler' },
  refusalConcurrencyError: {
    en: 'Reservation for {0} has been modified by another user. Employee cannot be confirmed. Please refresh view and retry if needed.',
    fr: "La réservation pour {0} a été modifiée par un autre utilisateurs. L'employé ne peut être confirmer. SVP rafraîchir et réessayer si nécessaire.",
  },
  refusalInternalError: {
    en: 'Internal error while trying to confirm for {0}. Please refresh and retry if needed.',
    fr: "Erreur interne lors de la confirmation de l'pour {0}. SVP rafraîchir et réessayer si nécessaire.",
  },
  refusalMessage: {
    en: 'A message was sent to the manager.',
    fr: 'Un message a été envoyé au responsable.',
  },
};

const ConfirmRefusalDialog = (props: ConfirmRefusalDialogProps) => {
  const translate = useTranslator();
  const apiClient = useApiClient();
  const formater = useLocaleFormater();

  const cancelConfirmRefusalDialog = () => {
    props.setOpenInfo({ open: false, reservation: props.openInfo!.reservation });
  };

  const getFormattedJobDate = (jobDate: string) => formater.formatDate(parseApiDate(jobDate), { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });

  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

  const refuseEmployeeOnCleaningJob = () => {
    const reservationToRefuse = props.openInfo!.reservation;

    props.setOpenInfo({ open: false, reservation: props.openInfo!.reservation });

    props.safeSetViewLoadingState(LoadingState.ModalWithVisibleContent);

    reservationsAdapter
      .employeeRefuseOnCleaningJob(apiClient, props.propertyMgmtOrg.id, reservationToRefuse.id, reservationToRefuse.aggregateTimeStamp, { targetEmployeeUniqueUserId: propertyMgmtContext.uniqueUserId! }, true)
      .then((value) => {
        props.refreshSingleReservationInList(value);
        props.safeSetUserMessageContent(translate(ConfirmRefusalDialogDict.refusalMessage));
      })
      .catch((error: any) => {
        if (isApiConcurrencyError(error)) props.safeSetUserMessageContent(translate(ConfirmRefusalDialogDict.refusalConcurrencyError, [reservationToRefuse.rentalUnit.name]));
        else props.safeSetUserMessageContent(translate(ConfirmRefusalDialogDict.refusalInternalError, [reservationToRefuse.rentalUnit.name]));
      })
      .finally(() => props.safeSetViewLoadingState(LoadingState.None));
  };

  return (
    <Dialog fullWidth onClose={() => {}} open={!!props.openInfo && props.openInfo.open} keepMounted data-cy="ConfirmRefusalDialog">
      <DialogTitle>{translate(ConfirmRefusalDialogDict.confirmationRefusalDlgTitle)}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText data-cy="ConfirmRefusalDialogText">
          {translate(ConfirmRefusalDialogDict.confirmationRefusalDlgTextBeforeJobName)}
          <Box component="span" sx={{ fontWeight: 700 }}>
            {props.openInfo?.reservation.rentalUnit.name}
          </Box>
          {translate(ConfirmRefusalDialogDict.confirmationRefusalDlgTextAfterJobName, [
            props.openInfo ? getFormattedJobDate(props.openInfo.reservation.endDate) : '',
            props.openInfo ? getFormattedJobDate(props.openInfo.reservation.cleaningJob.mustBeCompletedBeforeDate) : '',
          ])}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancelConfirmRefusalDialog} data-cy="ConfirmRefusalDialogCancelAction">
          {translate(ConfirmRefusalDialogDict.confirmationRefusalDlgCancelActionLbl)}
        </Button>
        <Button onClick={refuseEmployeeOnCleaningJob} data-cy="ConfirmRefusalDialogOkAction">
          {translate(ConfirmRefusalDialogDict.confirmationRefusalDlgOkActionLbl)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRefusalDialog;
