import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import specialityAdapter, { ISpecialty } from './Adapters/specialityAdapter';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import employeeAdapter from './Adapters/employeeAdapter';
import ConfirmCancellationDialog from './components/ConfirmCancellationDialog';
import PhoneInputField from './components/PhoneInputField'
import useTranslator from '../../utilities/hooks/useTranslator';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';

const EmployeeCreateContentDict = {
  title: { en: 'Create Employee', fr: 'Créer un employé' },
  firstName: { en: 'First Name', fr: 'Prénom' },
  lastName: { en: 'Last Name', fr: 'Nom' },
  required: { en: '*Required', fr: '*Requis' },
  nickname: { en: 'Nickname', fr: 'Surnom' },
  email: { en: 'Email', fr: 'Courriel' },
  phone: { en: 'Phone', fr: 'Téléphone' },
  specialities: { en: 'Specialities', fr: 'Spécialités' },
  notes: { en: 'Notes', fr: 'Notes' },
  cancel: { en: 'CANCEL', fr: 'ANNULER' },
  create: { en: 'CREATE', fr: 'CRÉER' },
  invalidFormat: { en: 'Format not valid', fr: `Format non valide` },
  isManager: { en: 'Is Manager', fr: `Est superviseur` },
};

const EmployeeCreate = () => {

  const translate = useTranslator();

  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
  const [specialities, setSpecialities] = useState<ISpecialty[] | undefined>(undefined);

  const [confirmCancellationDlgOpenInfo, setConfirmCancellationDlgOpenInfo] = useState<{ open: boolean } | undefined>(undefined);

  const [isManager, setIsManager] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [nickname, setNickname] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const [validEmail, setValidEmail] = useState<string | undefined>(undefined);
  const [specialityIds, setSpecialityIds] = useState<string[]>([]);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [phones, setPhones] = useState<string[]>([]);

  const [emailError, setEmailError] = useState<boolean>(false);

  const openCancelDialogCbk = useCallback(() => setConfirmCancellationDlgOpenInfo({ open: true }), [setConfirmCancellationDlgOpenInfo]);

  const apiClient = useApiClient();
  const history = useSafeHistory();

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    specialityAdapter
      .getAllSpecialties(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
      .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
        setSpecialities(value);
        setViewLoadingState(LoadingState.None)
      }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

  const handleEmailValidation = (event: ChangeEvent<HTMLInputElement>) => {

    setEmail(event.target.value);

    const EMAIL_REGEX = new RegExp('^[A-Za-z0-9._%+-]{1,64}@(?:[A-Za-z0-9-]{1,63}\\.){1,125}[A-Za-z]{2,63}$');

    if (EMAIL_REGEX.test(event.target.value)) {
      setValidEmail(event.target.value);
      setEmailError(false);
    } else {
      setEmailError(true);
      setValidEmail('');
    }

  };
  const handleSpecialityChange = (event: ChangeEvent<HTMLInputElement>) => {

    const index = specialityIds.indexOf(event.target.value);

    if (index === -1) setSpecialityIds([...specialityIds, event.target.value]);
    else setSpecialityIds(specialityIds.filter((specialityIds) => specialityIds !== event.target.value));

  };
  const phoneFilteration = (phonesToFilter: string[]) => {
    const phones = [...phonesToFilter];
    phones.pop()
    setPhones([...phones]);
  }

  const unexpectedLoadingErrorReload = !getAllViewData ? undefined : () => getAllViewData(shouldSetStatesBeCancelledFn);

  const submitEmployeeForm = () => {

    if (shouldSetStatesBeCancelledFn()) return;

    setViewLoadingState(LoadingState.ModalWithVisibleContent);

    employeeAdapter
      .createEmployee(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, { email: validEmail!, firstName: firstName!, lastName: lastName!, nickname: nickname!, notes: notes!, phones: phones!, specialtyIds: specialityIds, isManager: isManager }, true)
      .then(() => history.goBack())
      .catch(() => setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  return (
    <ViewLayout contentBoxPadding={2} contentBoxPaddingBottom={8} viewTitle={translate(EmployeeCreateContentDict.title)} loadingState={viewLoadingState} unexpectedLoadingErrorReload={unexpectedLoadingErrorReload} childViewBackOverride={openCancelDialogCbk} isChildView={true}>
      <FormControlLabel
        label={translate(EmployeeCreateContentDict.isManager)}
        sx={{ mb: 1 }}
        control={<Checkbox color="secondary" checked={isManager} onChange={(event) => setIsManager(event.target.checked)} />}
      />
      <Box display="flex" mb={1}>
        <TextField fullWidth inputProps={{ maxLength: 50 }} value={firstName || ''} onChange={(event) => setFirstName(event.target.value)} sx={{ mr: 1 }} helperText={!firstName ? translate(EmployeeCreateContentDict.required) : ' '} label={translate(EmployeeCreateContentDict.firstName)} data-cy="FirstNameInput" />
        <TextField fullWidth inputProps={{ maxLength: 50 }} value={lastName || ''} onChange={(event) => setLastName(event.target.value)} helperText={!lastName ? translate(EmployeeCreateContentDict.required) : ' '} label={translate(EmployeeCreateContentDict.lastName)} data-cy="LastNameInput" />
      </Box>
      <TextField fullWidth inputProps={{ maxLength: 50 }} value={nickname || ''} onChange={(event) => setNickname(event.target.value)} helperText={' '} label={translate(EmployeeCreateContentDict.nickname)} sx={{ mb: 1 }} data-cy="NicknameInput" />
      <TextField fullWidth type='email' value={email || ''} error={(emailError && !!email)} onChange={handleEmailValidation} helperText={emailError && !!email ? translate(EmployeeCreateContentDict.invalidFormat) : !email ? translate(EmployeeCreateContentDict.required) : ' '} sx={{ mb: 1 }} label={translate(EmployeeCreateContentDict.email)} data-cy="EmailInput" />
      <PhoneInputField phoneFilter={phoneFilteration} />
      <FormGroup sx={{ mb: 2 }}>
        <Typography color="text.secondary">{translate(EmployeeCreateContentDict.specialities)}</Typography>
        {!specialities
          ? null
          : specialities.map((speciality, index) => (
            <FormControlLabel key={speciality.id} control={<Checkbox checked={specialityIds.includes(speciality.id)} onChange={handleSpecialityChange} size="small" color='secondary' data-cy={"SpecialtyCheckbox_" + index.toString()} />} label={speciality.name} value={speciality.id}
            />
          ))}
      </FormGroup>
      <TextField fullWidth inputProps={{ maxLength: 1000 }} value={notes || ''} onChange={(event) => setNotes(event.target.value)} helperText={' '} label={translate(EmployeeCreateContentDict.notes)} multiline rows={3} data-cy="NotesInput" />
      <Paper elevation={2} sx={{ backgroundColor: '#FAFAFA', marginLeft: -2, width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', p: 1, zIndex: '2' }}>
        <Box display="flex" justifyContent="flex-end">
          <Button sx={{ mr: 1 }} onClick={() => setConfirmCancellationDlgOpenInfo({ open: true })} size="medium" variant="contained" data-cy="CancelAction">
            {translate(EmployeeCreateContentDict.cancel)}
          </Button>
          <Button disabled={!firstName || !lastName || phones.length === 0 || !phones.every((phone) => phone.length === 13) || !validEmail || specialityIds.length === 0} size="medium" onClick={submitEmployeeForm} variant="contained" data-cy="CreateAction">
            {translate(EmployeeCreateContentDict.create)}
          </Button>
        </Box>
      </Paper>
      <ConfirmCancellationDialog openInfo={confirmCancellationDlgOpenInfo} setOpenInfo={setConfirmCancellationDlgOpenInfo} />
    </ViewLayout>
  );
};

export default EmployeeCreate;
