import { useContext, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';

import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import useApiClient from '../../utilities/hooks/useApiClient';
import useTranslator from '../../utilities/hooks/useTranslator';

import CancelDialog from './components/CancelDialog';
import specialtyAdapter from './adapters/specialtyAdapter';

const specialtyCreateDict = {
  title: { en: 'Create specialty', fr: 'Créer une spécialité' },
  name: { en: 'Name', fr: 'Nom' },
  description: { en: 'Description', fr: 'Description' },
  required: { en: '*Required', fr: '*Requis' },
  create: { en: 'CREATE', fr: 'CRÉER' },
  cancel: { en: 'CANCEL', fr: 'ANNULER' },
};

const SpecialtyCreate = () => {

  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const apiClient = useApiClient();
  const history = useSafeHistory()
  const translate = useTranslator();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.None);
  const [cancelDialogOpenInfo, setCancelDialogOpenInfo] = useState<{ open: boolean } | undefined>(undefined);
  const openCancelDialogCbk = useCallback(() => setCancelDialogOpenInfo({ open: true }), [setCancelDialogOpenInfo]); 

  const createSpecialty = () => {
    specialtyAdapter
      .createSpecialty(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, { name: name, description: description })
      .then(() => history.goBack())
      .catch(() => setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };

  return  (
    <ViewLayout viewTitle={translate(specialtyCreateDict.title)} loadingState={viewLoadingState} isChildView={true} childViewBackOverride={openCancelDialogCbk}>
      <Box display="flex" sx={{flexDirection: 'column', m:2}}>
        <TextField fullWidth sx={{mb:2}} value={name} onChange={(event) => setName(event.target.value)} helperText={ !name ? translate(specialtyCreateDict.required) : ' '} label={translate(specialtyCreateDict.name)} data-cy="NameInput" />
        <TextField fullWidth multiline rows={4} value={description} onChange={(event) => setDescription(event.target.value)} label={translate(specialtyCreateDict.description)} data-cy="DescriptionInput" />
      </Box>
      <Paper elevation={2} sx={{ backgroundColor: '#FAFAFA', width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', zIndex: '2' }}>
          <Box display="flex" justifyContent="flex-end" sx={{m:1}}>
            <Button sx={{mr:1}} onClick={openCancelDialogCbk} size="medium" variant="contained" data-cy="CancelAction">
              {translate(specialtyCreateDict.cancel)}
            </Button>
            <Button disabled={!name} size="medium" onClick={createSpecialty} variant="contained" data-cy="CreateAction">
              {translate(specialtyCreateDict.create)}
            </Button>
          </Box>
      </Paper>
        <CancelDialog openInfo={cancelDialogOpenInfo} setOpenInfo={setCancelDialogOpenInfo} />
    </ViewLayout>
  );
};

export default SpecialtyCreate;