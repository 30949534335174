import React, { ChangeEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Icon from '@mui/material/Icon';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { Box } from '@mui/material';

export interface PhoneInputFieldProps {
  phoneFilter: (phone: string[]) => void;
}

const PhoneInputFieldDict = {
  phone: { en: 'Phone', fr: 'Téléphone' },
  required: { en: '*Required', fr: '*Requis' },
  invalidFormat: { en: 'Format not valid', fr: `Format non valide` },
};

const PhoneInputField = (props: PhoneInputFieldProps) => {
  const translate = useTranslator();

  const [phoneList, setPhoneList] = useState<string[]>(['']);
  const [previousIndex, setPreviousIndex] = useState<number>(-1);

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    setPreviousIndex(index);
    const phone = formatPhoneNumber(event.target.value);
    const phones = [...phoneList];
    if (previousIndex < index) {
      phones.push('');
    }
    phones[index] = phone;
    setPhonelistandphoneFilter(phones);
  };
  
  const handlePhoneRemove = (index: number) => {
    setPreviousIndex(index - 1);
    const phones = [...phoneList];
    phones.splice(index, 1);
    setPhonelistandphoneFilter(phones);
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const setPhonelistandphoneFilter = (list: string[]) => {
    props.phoneFilter(list);
    setPhoneList(list);
  };

  return !phoneList ? null : (
    <React.Fragment>
      {phoneList.map((phoneNo, index) => (
        <Box mb={1} key={index} display="flex">
          <TextField fullWidth label={translate(PhoneInputFieldDict.phone)} sx={{ mr: 1, mt: 1 }} type="tel" value={phoneNo} error={phoneNo.length > 0 && phoneNo.length < 13} onChange={(event: ChangeEvent<HTMLInputElement>): void => handlePhoneChange(event, index)} helperText={ phoneNo.length > 0 && phoneNo.length < 13 ? translate(PhoneInputFieldDict.invalidFormat): (index === 0 && !phoneNo )? translate(PhoneInputFieldDict.required):  ' '} data-cy={"PhoneInput_"+ index.toString()} />
          <Icon onClick={() => handlePhoneRemove(index)} sx={{ mt: 2, visibility: phoneList[index].length > 0 ? 'visible' : 'hidden' }}>
            clear
          </Icon>
        </Box>
      ))}
    </React.Fragment>
  );
};

export default PhoneInputField;
