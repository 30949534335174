interface IApiBusinessLogicError {
    code: string,
    helperMessage?: string,
    isApiBusinessLogicError: true
}

export const isApiBusinessLogicError = (error: any) => error && error.isApiBusinessLogicError === true;

export const isApiBusinessLogicErrorWithCode = (error: any, code: string) => isApiBusinessLogicError(error) && (error as IApiBusinessLogicError).code === code;

export default IApiBusinessLogicError;