import IApiClient from '../../../utilities/IApiClient';

export interface ITasks {
  title: string;
  description: string;
}

export interface IPropertyMgmtOrg {
  id: string;
  cleaningTasks: ITasks[];
}

const propertyMgmtOrgsAdapter = {
  getPropertyMgmtOrg: (apiClient: IApiClient, id: string) => apiClient.get<IPropertyMgmtOrg>('/PropertyMgmtOrgs/' + id)
};

export default propertyMgmtOrgsAdapter;
