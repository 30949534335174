import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningManager {
    uniqueUserId: string;
    email: string;
    name: string;
    phones: string[];
}
export interface IFeature {
    name: string;
    value: string;
}

export interface IReservation {
    startDate: string;
    endDate: string
}

export interface IRentalUnitDTO {
    name: string;
    civicNumber?: string;
    street: string;
    city: string;
    province: string;
    manager?: ICleaningManager;
    features: IFeature[];
    notes: string;
    sourceName?: string;
    idInSource?: string;

}


const rentalUnitAdapter = {

    createRentalUnit: (apiClient: IApiClient, propertyMgmtId: string, createRentalUnitDto: IRentalUnitDTO, expectConcurrencyError: boolean, expectedLogicErrorCodes: string[] | undefined) => apiClient.post('/RentalUnits/' + propertyMgmtId, createRentalUnitDto, expectConcurrencyError, expectedLogicErrorCodes)

};

export default rentalUnitAdapter;
