import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import IUserMessage from '../IUserMessage';

interface IUserMessagePane {
    userMessage: IUserMessage | undefined,
    setUserMessage: (message: IUserMessage | undefined) => void,
    isInChildView: boolean,
    isInViewWithFab: boolean
}

const UserMessagePane = (props: IUserMessagePane) => {

    const theme = useTheme();

    const [isOpen, setIsOpen] = useState(props.userMessage ? true : false);

    const [currentMessage, setCurrentMessage] = useState(props.userMessage);

    useEffect(() => {
        if (!!props.userMessage && (currentMessage?.key !== props.userMessage.key || !isOpen)) {
            setCurrentMessage(props.userMessage);
            setIsOpen(true);
        }
    }, [props.userMessage, currentMessage, isOpen, setCurrentMessage, setIsOpen]);

    const handleClose = () => {
        if (currentMessage?.key === props.userMessage?.key)
            props.setUserMessage(undefined);

        setIsOpen(false);
    };

    return (
        <Snackbar message={currentMessage?.messageContent} open={isOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{
            bottom: props.isInChildView ? (props.isInViewWithFab ? theme.spacing(10) : undefined) : theme.spacing(8 + (props.isInViewWithFab ? 9 : 0)),
            [theme.breakpoints.up('sm')]: {
                left: 'auto',
                marginLeft: 1,
                bottom: props.isInChildView ? (props.isInViewWithFab ? theme.spacing(10) : theme.spacing(1)) : theme.spacing(8 + (props.isInViewWithFab ? 9 : 0)),
                width: 'Calc(' + theme.breakpoints.values.sm + 'px - ' + theme.spacing(2) + ')',
                '& .MuiSnackbarContent-root': { flexGrow: 1 }
            }
        }}
            onClose={handleClose}
            autoHideDuration={5000}
            key={currentMessage?.key}
            data-cy='UserMessage'></Snackbar>
    );
};

export default UserMessagePane;