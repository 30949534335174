import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';

import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import { parseApiDate, stringifyApiDate } from '../../utilities/apiDates';
import useLocaleFormater from '../../utilities/hooks/useLocaleFormater';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';
import rentalUnitAdapter, { IRentalUnit } from './Adapters/rentalUnitAdapter';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const rentalUnitDict = {
    rentalUnit: { en: 'Rental Unit', fr: 'Unité de location' },
    name: { en: 'Name:', fr: 'Nom:' },
    address: { en: 'Address:', fr: 'Adresse:' },
    status: { en: 'Status:', fr: 'État:' },
    managedSince: { en: 'Managed since:', fr: 'Gérée depuis:' },
    deactivatedSince: { en: 'Deactivated since:', fr: 'Désactivée depuis:' },
    currentReservation: { en: 'Current reservation:', fr: 'Réservation en cours:' },
    checkInOn: { en: 'Check-in on {0} {1}h{2}', fr: 'Check-in le {0} {1}h{2}' },
    checkOutOn: { en: 'Check-out on {0} {1}h{2}', fr: 'Check-out {0} le {1}h{2}' },
    nextReservation: { en: 'Next Reservation:', fr: 'Prochaine réservation:' },
    cleaningManager: { en: 'Cleaning Manager:', fr: 'Superviseur des ménages:' },
    email: { en: 'Email:', fr: 'Courriel:' },
    phone: { en: 'Phone {0}:', fr: 'Téléphone {0}:' },
    features: { en: 'Features:', fr: 'Caractéristiques' },
    notes: { en: 'Notes:', fr: 'Notes:' },
    source: { en: 'Source:', fr: 'Source' },
    idInSource: { en: 'Id in source:', fr: 'Id dans la source:' },
    dirty: { en: 'DIRTY', fr: 'SALE' },
    clean: { en: 'CLEAN', fr: 'PROPRE' },
    noCurrentReservation: { en: 'No current reservation', fr: 'Pas de réservation en cours' },
    noNextReservation: { en: 'No next reservation', fr: 'Pas de prochaine réservation' },
    reactivate: { en: 'Reactivate', fr: 'Réactiver' },

};

type RentalUnitsChildParams = { rentalunitid: string }

const RentalUnit = () => {
    const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

    const apiClient = useApiClient();
    const formater = useLocaleFormater();
    const translate = useTranslator();
    const history = useSafeHistory();

    const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
    const [rentalUnitDetails, setRentalUnitDetails] = useState<IRentalUnit | undefined>(undefined);

    const { rentalunitid } = useParams<RentalUnitsChildParams>();

    const rentalUnitEdit = () => history.push(`/rentalunitupdate/${rentalUnitDetails?.id}`);

    const currentDate = new Date();
    const date = stringifyApiDate(currentDate);

    const getFormattedDate = (dateToFormat: string) => formater.formatDate(parseApiDate(dateToFormat), { year: 'numeric', month: 'numeric', day: 'numeric' });

    const hoursFormat = (dateToFormat: string) => {
        const dateValue = parseApiDate(dateToFormat);
        const hours = dateValue.getHours();
        const minutes = dateValue.getMinutes();
        return [hours.toString(), minutes.toString()];
    }

    const handleReactivate = () => {
        rentalUnitAdapter
            .reactivateRentalUnit(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, rentalUnitDetails?.aggregateTimeStamp!, rentalUnitDetails?.id!, { reactivationDate: date }, true)
            .then(() => history.push(`/rentalunits`))
            .catch(() => setViewLoadingState(LoadingState.UnexpectedLoadingError));
    };

    const getAllViewData = !rentalunitid
        ? undefined
        : (shouldSetStatesBeCancelledFn: () => boolean) => {
            setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

            rentalUnitAdapter
                .getRentalUnit(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, rentalunitid)
                .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
                    setRentalUnitDetails(value)
                    setViewLoadingState(LoadingState.None);
                }))
                .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
        };

    const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

    const unexpectedLoadingErrorReload = !getAllViewData ? undefined : () => getAllViewData(shouldSetStatesBeCancelledFn);

    return (
        <ViewLayout
            isChildView={true}
            viewTitle={translate(rentalUnitDict.rentalUnit)}
            loadingState={viewLoadingState}
            unexpectedLoadingErrorReload={unexpectedLoadingErrorReload}
            contentBoxPaddingTop={1}
            contentBoxPaddingBottom={8}
            fab={rentalUnitDetails?.isActive ? { iconName: 'edit', fabAction: rentalUnitEdit } : undefined}>
            <Box p={1} sx={{ mx: 1 }} display="flex" alignItems="baseline">
                <Typography variant='body2' flexBasis='144px' flexShrink={0}>
                    {translate(rentalUnitDict.name)}
                </Typography>
                <Typography variant="h6">
                    {rentalUnitDetails?.name}
                </Typography>
            </Box>
            <Box p={1} sx={{ mx: 1 }} display="flex" alignItems="baseline">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                    {translate(rentalUnitDict.address)}
                </Typography>
                {rentalUnitDetails?.civicNumber ? (
                    <Typography variant='body1'>
                        {rentalUnitDetails?.civicNumber} {rentalUnitDetails?.street} {rentalUnitDetails?.city} {rentalUnitDetails?.province}
                    </Typography>
                ) :
                    <Typography>
                        {rentalUnitDetails?.street} {rentalUnitDetails?.city} {rentalUnitDetails?.province}
                    </Typography>
                }
            </Box>
            <Box p={1} sx={{ mx: 1 }} display="flex" alignItems="baseline">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                    {translate(rentalUnitDict.status)}
                </Typography>
                {!rentalUnitDetails?.isDirty ? (
                    <Typography variant='h6'>
                        {translate(rentalUnitDict.clean)}
                    </Typography>
                ) :
                    <Typography variant='h6'>
                        {translate(rentalUnitDict.dirty)}
                    </Typography>
                }
            </Box>
            {!rentalUnitDetails?.reactivationDate && !rentalUnitDetails?.deactivationDate ? null : (
                <Box p={1} sx={{ mx: 1 }} display="flex">
                    {!rentalUnitDetails?.isActive ? (
                        <>
                            <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0} color="primary.dark">
                                {translate(rentalUnitDict.deactivatedSince)}
                            </Typography>
                            <Typography variant='body1' color="primary.dark">
                                {getFormattedDate(rentalUnitDetails.deactivationDate!)}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                                {translate(rentalUnitDict.managedSince)}
                            </Typography>
                            <Typography variant='body1'>
                                {getFormattedDate(rentalUnitDetails.reactivationDate!)}
                            </Typography>
                        </>
                    )}
                </Box>
            )}
            <Box p={1} sx={{ mx: 1 }} display="flex" alignItems="baseline">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                    {translate(rentalUnitDict.currentReservation)}
                </Typography>
                {rentalUnitDetails?.currentReservation?.startDate ? (
                    <Box>
                        <Typography variant='body2' minWidth='200px'>
                            {translate(rentalUnitDict.checkInOn, [getFormattedDate(rentalUnitDetails.currentReservation.startDate!), ...hoursFormat(rentalUnitDetails.currentReservation.startDate!)])}
                        </Typography>
                        <Typography variant='body2' minWidth='200px'>
                            {translate(rentalUnitDict.checkOutOn, [getFormattedDate(rentalUnitDetails.currentReservation.endDate!), ...hoursFormat(rentalUnitDetails.currentReservation.endDate)])}
                        </Typography>
                    </Box>
                ) :
                    <Typography variant='body2'>
                        {translate(rentalUnitDict.noCurrentReservation)}
                    </Typography>
                }
            </Box>
            <Box p={1} sx={{ mx: 1, mb: 1 }} display="flex" alignItems="baseline">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                    {translate(rentalUnitDict.nextReservation)}
                </Typography>
                {rentalUnitDetails?.nextReservation?.startDate ? (
                    <Typography variant='body2' minWidth='200px'>
                        {translate(rentalUnitDict.checkInOn)} {rentalUnitDetails?.nextReservation ? getFormattedDate(rentalUnitDetails.nextReservation.startDate) : null}
                    </Typography>
                ) :
                    <Typography variant='body2'>
                        {translate(rentalUnitDict.noNextReservation)}
                    </Typography>
                }
            </Box>
            {rentalUnitDetails?.manager ? (
                <>
                    <Box p={1} minWidth={0} sx={{ mx: 1 }} display="flex" alignItems="baseline">
                        <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                            {translate(rentalUnitDict.cleaningManager)}
                        </Typography>
                        <Typography variant='body1'>{rentalUnitDetails?.manager.name}</Typography>
                    </Box>
                    <Box p={1} minWidth={0} sx={{ mx: 1 }} display="flex" alignItems="baseline">
                        <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                            {translate(rentalUnitDict.email)}
                        </Typography>
                        <Typography variant='body1'>{rentalUnitDetails?.manager.email}</Typography>
                    </Box>
                    {rentalUnitDetails?.manager.phones && rentalUnitDetails.manager.phones.map((phone, index) => (
                        <Box key={phone} p={1} sx={{ mx: 1 }} display="flex" alignItems={index === 0 ? 'baseline' : 'normal'}>
                            <Typography variant='body2' flexBasis={(theme) => theme.spacing(18)} flexShrink={0}>
                                {translate(rentalUnitDict.phone, [(index + 1).toString()])}
                            </Typography>
                            <Typography variant={index === 0 ? 'h6' : 'body1'}>{phone}</Typography>
                        </Box>
                    )
                    )}
                </>
            ) : null}
            <Box p={1} sx={{ m: 1 }} display="flex">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(10)} flexShrink={0}>
                    {translate(rentalUnitDict.features)}
                </Typography>
                <Box>{!rentalUnitDetails?.features ? null : rentalUnitDetails.features.map((feature, index) => <Typography variant='body2' key={index}>- {feature.value} {feature.name}</Typography>)}</Box>
            </Box>
            <Box p={1} sx={{ ml: 1, mr: 1 }} display="flex">
                <Typography variant='body2' flexBasis={(theme) => theme.spacing(7)} flexShrink={0}>
                    {translate(rentalUnitDict.notes)}
                </Typography>
                <Typography variant='body2' sx={{ maxWidth: (theme) => theme.spacing(35) }}>{rentalUnitDetails?.notes}</Typography>
            </Box>
            {rentalUnitDetails?.sourceName ? (
                <Box p={1} minWidth={0} sx={{ mx: 1 }} display="flex">
                    <Typography variant='body2' flexBasis={(theme) => theme.spacing(12)} flexShrink={0}>
                        {translate(rentalUnitDict.source)}
                    </Typography>
                    <Typography variant='body2'>{rentalUnitDetails?.sourceName}</Typography>
                </Box>
            ) : null}
            {rentalUnitDetails?.idInSource ? (
                <Box p={1} minWidth={0} sx={{ mx: 1 }} display="flex">
                    <Typography variant='body2' flexBasis={(theme) => theme.spacing(12)} flexShrink={0}>
                        {translate(rentalUnitDict.idInSource)}
                    </Typography>
                    <Typography variant='body2'>{rentalUnitDetails?.idInSource}</Typography>
                </Box>
            ) : null}
            <Paper elevation={2} sx={{ visibility: rentalUnitDetails?.isActive ? 'hidden' : 'visible', backgroundColor: '#FAFAFA', width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', p: 1, zIndex: '2' }}>
                <Box>
                    <Button onClick={handleReactivate} size="medium" variant="contained">
                        {translate(rentalUnitDict.reactivate)}
                    </Button>
                </Box>
            </Paper>
        </ViewLayout>
    );
};

export default RentalUnit;
