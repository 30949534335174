import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningManager {
  name: string;
  email: string;
  phones: string[];
}

export interface IRentalUnit {
  id: string;
  isActive: boolean;
  isDirty: boolean;
  manager: ICleaningManager;
  name: string;
  civicNumber: string;
  street: string;
  city: string;
  province: string;
}

const rentalUnitsAdapter = {
  getAllRentalUnits: (apiClient: IApiClient, propertyMgmtId: string) => apiClient.get<IRentalUnit[]>('/RentalUnits/' + propertyMgmtId)
};

export default rentalUnitsAdapter;
