import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { IReservation } from '../adapters/reservationsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';

export interface ICommonActionMenuProps {
    confirmActionHandler?: (reservation: IReservation) => void,
    startActionHandler?: (reservation: IReservation) => void,
    completeActionHandler?: (reservation: IReservation) => void,
    stopActionHandler?: (reservation: IReservation) => void,
    restartActionHandler?: (reservation: IReservation) => void,
    removeActionHandler?: (reservation: IReservation) => void,
    closeMenuActionHandler: () => void,
    open: boolean,
    menuAnchorEl: HTMLElement | undefined,
    reservation: IReservation | undefined
}

const CommonActionMenuDict = {
    confirmAction: { en: 'Confirm', fr: 'Confirmer' },
    startAction: { en: 'Start', fr: 'Démarrer' },
    stopAction: { en: 'Stop', fr: 'Arrêt' },
    completeAction: { en: 'Complete', fr: 'Terminer' },
    restartAction: { en: 'Start Over', fr: 'Recommencer' },
    removeAction: { en: 'Remove', fr: 'Retirer' }
};

const CommonActionMenu = (props: ICommonActionMenuProps) => {

    const translate = useTranslator();

    const hasConfirmActionEnabled = !!props.reservation && props.reservation.cleaningJob.employees.findIndex((value) => value.status === 'Assigned') !== -1;
    const hasStartActionEnabled = !!props.reservation && props.reservation.cleaningJob.employees.findIndex((value) => value.status === 'Confirmed') !== -1;
    const hasStopActionEnabled = !!props.reservation && props.reservation.cleaningJob.employees.findIndex((value) => value.status === 'Working') !== -1;
    const hasSetToDoneActionEnabled = !!props.reservation && props.reservation.cleaningJob.employees.findIndex((value) => value.status === 'Working') !== -1;
    const hasRestartActionEnabled = !!props.reservation && props.reservation.cleaningJob.employees.findIndex((value) => value.status === 'Done') !== -1;
    const hasRemoveActionEnabled = !!props.reservation && props.reservation.cleaningJob.employees.findIndex((value) => value.status !== 'Removed' && value.status !== 'Refused') !== -1;

    const closeAndExecute = (executeFn: (reservation: IReservation) => void) => {

        const currentReservation = props.reservation!;

        props.closeMenuActionHandler();
        executeFn(currentReservation);
    }

    return <Menu
        id="reservation-actions-menu"
        anchorEl={props.menuAnchorEl}
        open={!!props.menuAnchorEl && props.open}
        onClose={props.closeMenuActionHandler}
        MenuListProps={{
            'aria-label': 'Reservation actions menu',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer }} //Making sure the menu will be shown under dialog boxes
        data-cy='OverflowMenu'>
        {props.confirmActionHandler && <MenuItem onClick={() => closeAndExecute(props.confirmActionHandler!)} disabled={!hasConfirmActionEnabled} sx={{ minWidth: (theme) => theme.spacing(14) }} data-cy='MenuConfirmAction'>{translate(CommonActionMenuDict.confirmAction)}</MenuItem>}
        {props.startActionHandler && <MenuItem onClick={() => closeAndExecute(props.startActionHandler!)} disabled={!hasStartActionEnabled} sx={{ minWidth: (theme) => theme.spacing(14) }} data-cy='MenuStartAction'>{translate(CommonActionMenuDict.startAction)}</MenuItem>}
        {props.completeActionHandler && <MenuItem onClick={() => closeAndExecute(props.completeActionHandler!)} disabled={!hasSetToDoneActionEnabled} sx={{ minWidth: (theme) => theme.spacing(14) }} data-cy='MenuCompleteAction'>{translate(CommonActionMenuDict.completeAction)}</MenuItem>}
        {props.stopActionHandler && <MenuItem onClick={() => closeAndExecute(props.stopActionHandler!)} disabled={!hasStopActionEnabled} sx={{ minWidth: (theme) => theme.spacing(14) }} data-cy='MenuStopAction'>{translate(CommonActionMenuDict.stopAction)}</MenuItem>}
        {props.restartActionHandler && <MenuItem onClick={() => closeAndExecute(props.restartActionHandler!)} disabled={!hasRestartActionEnabled} sx={{ minWidth: (theme) => theme.spacing(14) }} data-cy='MenuRestartAction'>{translate(CommonActionMenuDict.restartAction)}</MenuItem>}
        {props.removeActionHandler && <MenuItem onClick={() => closeAndExecute(props.removeActionHandler!)} disabled={!hasRemoveActionEnabled} sx={{ minWidth: (theme) => theme.spacing(14) }} data-cy='MenuRemoveAction'>{translate(CommonActionMenuDict.removeAction)}</MenuItem>}
    </Menu >;
};

export default CommonActionMenu