import { useContext, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';

import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import useTranslator from '../../utilities/hooks/useTranslator';
import useQueryString from '../../utilities/hooks/useQueryString';
import LoadingState from '../../components/LoadingState';
import logManager from '../../utilities/logManager';
import { parseApiDate, stringifyApiDate } from '../../utilities/apiDates';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useLocaleFormater from '../../utilities/hooks/useLocaleFormater';
import { isApiConcurrencyError } from '../../utilities/IApiConcurrencyError';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';

import reservationsAdapter, { IReservation } from './adapters/reservationsAdapter';
import employeesAdapter, { IEmployee } from './adapters/employeesAdapter';
import specialtiesAdapter, { ISpecialty } from './adapters/specialtiesAdapter';

export interface ICardEmployee {
    id: string,
    name: string,
    uniqueUserId: string,
    phone: string,
    specialtiesNames: string[],
    otherJobCount: number,
    isAssignedToReservation: boolean
}

const getSpecialitiesNames = (employee: IEmployee, specialties: ISpecialty[]) => {

    let specialtiesNames: string[] = [];

    for (let i = 0; i < employee.specialtyIds.length; i++) {

        let speciality = specialties.find((value) => value.id === employee.specialtyIds[i]);

        if (speciality)
            specialtiesNames.push(speciality.name);
        else
            logManager.logWarning(`Speciality with id ${employee.specialtyIds[i]} cannot be found in the returned specialities.`);
    }

    return specialtiesNames;
};

const isEmployeeAssigned = (employeeId: string, reservation: IReservation) => reservation.cleaningJob.employees.findIndex((value) => value.id === employeeId && value.status !== 'Removed' && value.status !== 'Refused') !== -1;

const getOtherJobCount = (employee: IEmployee, reservationsWithScheduledCleaningJobs: IReservation[], currentReservation: IReservation) =>
    reservationsWithScheduledCleaningJobs.filter((value) => value.id !== currentReservation.id && isEmployeeAssigned(employee.id, value)).length;

const buildCardEmployeeList = (employees: IEmployee[], reservationsWithScheduledCleaningJobs: IReservation[], specialties: ISpecialty[], currentReservation: IReservation) => {

    let cardEmployeeList: ICardEmployee[] = [];

    for (let i = 0; i < employees.length; i++) {

        let employee = employees[i];

        cardEmployeeList.push({
            id: employee.id,
            name: (employee.firstName + ' ' + employee.lastName).trim(),
            uniqueUserId: employee.uniqueUserId,
            phone: employee.phones.length === 0 ? '' : employee.phones[0],
            specialtiesNames: getSpecialitiesNames(employee, specialties),
            otherJobCount: getOtherJobCount(employee, reservationsWithScheduledCleaningJobs, currentReservation),
            isAssignedToReservation: isEmployeeAssigned(employee.id, currentReservation)
        });
    }

    return cardEmployeeList;
};

const updateCardEmployeeAssignations = (cardEmployeeList: ICardEmployee[], currentReservation: IReservation) => {

    cardEmployeeList.forEach((value) => value.isAssignedToReservation = isEmployeeAssigned(value.id, currentReservation))

    return cardEmployeeList;
};

const getDayHourDiff = (dateValue1: Date, dateValue2: Date, floorRounding: boolean) => {

    const roundingMethod = floorRounding ? Math.floor : Math.ceil;

    const diffInMs = dateValue1.getTime() - dateValue2.getTime();

    const hours = roundingMethod(diffInMs / 1000 / 60 / 60);

    return { days: Math.floor(hours / 24), hours: hours - (Math.floor(hours / 24) * 24) }
}

const assignDict = {
    title: { en: 'Select to assign', fr: 'Sélectionner pour assigner' },
    assignButton: { en: 'Assign', fr: 'Assigner' },
    removeButton: { en: 'Remove', fr: 'Retirer' },
    specialtiesLbl: { en: 'Specialties : ', fr: 'Specialités : ' },
    telephoneLbl: { en: 'Telephone : ', fr: 'Téléphone : ' },
    numberOfJobsLbl: { en: '# of jobs', fr: '# de jobs' },
    plannedLbl: { en: 'planned', fr: 'prévues' },
    reservationAvailableLabel: { en: 'Free in {0}d {1}h ({2})', fr: 'Libre dans {0}j {1}h ({2})' },
    reservationAvailablePastLabel: { en: 'Guests left {0}d {1}h ago ({2})', fr: 'Invités partis depuis {0}j {1}h ({2})' },
    reservationNextArrivalLabel: { en: 'Next arrival in {0}d {1}h ({2})', fr: 'Pr. arrivée dans {0}j {1}h ({2})' },
    reservationNextArrivalPastLabel: { en: 'Next arr. was {0}d {1}h ago ({2})', fr: 'Pr. arrivée passée depuis {0}j {1}h ({2})' },
    reservationNoNextArrivalLabel: { en: 'No upcoming arrival scheduled ', fr: 'Aucune arrivée n\'est prévue prochainement' },
    notInAssignedStateMessage: { en: 'Reservation is not in the "To Be Assigned" state. Employees cannot be assigned.', fr: 'La réservation n\'est pas dans l\'état "À assigned". Des employés ne peuvent être assignés à celle-ci.' },
    concurrencyError: { en: 'Reservation has been modified by another user. Please return to cleaning jobs and come back if necessary.', fr: 'La réservation a été modifiée par un autre utilisateur. SVP retourner aux entreriens et revenir si nécessaire.' },
    assignInternalError: { en: 'Internal error while trying to assign the job to the employee. Please try again or return to previous view.', fr: 'Erreur interne lors de l\'assignation de la job à l\'employé. SVP réessayer ou retourner à la vue précédente.' },
    removeInternalError: { en: 'Internal error while trying to remove the job for the employee. Please try again or return to previous view.', fr: 'Erreur interne lors de la suppression de la job pour l\'employé. SVP réessayer ou retourner à la vue précédente.' },
    noEmployeeAvailableMsg: { en: 'No employee is available.', fr: 'Aucun employé n\'est disponible.' }
};

const Assign = () => {

    const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

    const translate = useTranslator();

    const formater = useLocaleFormater();

    const apiClient = useApiClient();

    const history = useSafeHistory();

    const queryStringParams = useQueryString();

    const reservationId = queryStringParams.get('reservationId');

    if (!reservationId)
        logManager.logInfo(`View Assign shown with invalid reservation query parameter value(${reservationId}).`)

    const renderNow = new Date();

    const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);

    const [viewLoadingState, setViewLoadingState] = useState(reservationId ? LoadingState.NotModalWithInvisibleContent : LoadingState.UnexpectedLoadingError);

    const [currentReservation, setCurrentReservation] = useState<IReservation | undefined>(undefined);
    const [cardEmployeeListContent, setCardEmployeeListContent] = useState<ICardEmployee[] | undefined>(undefined);

    const isDateInThePast = (dateString: string) => parseApiDate(dateString).getTime() < renderNow.getTime();

    const buildDateDiffLabelParamArray = (dateString: string, floorRounding: boolean) => {

        const dateValue = parseApiDate(dateString);

        const dayHourDiff = getDayHourDiff(dateValue, renderNow, floorRounding);

        return [Math.abs(dayHourDiff.days).toString(), Math.abs(dayHourDiff.hours).toString(), formater.formatDate(dateValue, { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })];
    }

    const getCardEmployeeListData = !reservationId ? undefined : (shouldSetStatesBeCancelledFn: () => boolean) => {

        setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

        let employees: IEmployee[] | undefined = undefined;
        let currentReservation: IReservation | undefined = undefined;
        let reservationsWithScheduledCleaningJobs: IReservation[] | undefined = undefined;
        let specialties: ISpecialty[] | undefined = undefined;

        //Concurrent get calls
        Promise.all([
            reservationsAdapter.getReservation(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, reservationId).then((value) => {

                let execNow = new Date();

                currentReservation = value;

                return reservationsAdapter.getReservationsWithScheduledCleaningJobs(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string, parseApiDate(currentReservation.endDate) > execNow ? currentReservation.endDate : stringifyApiDate(execNow))
                    .then((value) => reservationsWithScheduledCleaningJobs = value)
            }),
            employeesAdapter.getAllEmployees(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
                .then((value) => employees = value),
            specialtiesAdapter.getAllSpecialties(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
                .then((value) => specialties = value),
        ])
            .then(() => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
                //State updates are batched to minimize flickering(multiple rendering). In next version of React, those state updates will be batched automatically in promises events.
                setCurrentReservation(currentReservation);
                setCardEmployeeListContent(buildCardEmployeeList(employees!, reservationsWithScheduledCleaningJobs!, specialties!, currentReservation!));
                setViewLoadingState(LoadingState.None);

                if (currentReservation!.cleaningJob.status !== 'ToBeAssigned')
                    setUserMessage(createMessageWithContent(translate(assignDict.notInAssignedStateMessage)));
            }))
            .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));;
    }

    const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getCardEmployeeListData);

    const unexpectedLoadingErrorReload = !getCardEmployeeListData ? undefined : () => getCardEmployeeListData(shouldSetStatesBeCancelledFn);

    const assignEmployee = (employeeId: string) => {

        if (shouldSetStatesBeCancelledFn()) return;

        let updatedReservation: IReservation | undefined = undefined;

        setViewLoadingState(LoadingState.ModalWithVisibleContent);

        reservationsAdapter.assignEmployeeToCleaningJob(apiClient,
            propertyMgmtContext.activePropertyMgmtOrgId!,
            currentReservation!.id,
            currentReservation!.aggregateTimeStamp,
            { targetEmployeeId: employeeId },
            true)
            .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
                updatedReservation = value;
                setCurrentReservation(value);
                setCardEmployeeListContent(updateCardEmployeeAssignations(cardEmployeeListContent!, value));
            }))
            .catch((error) => {
                if (shouldSetStatesBeCancelledFn()) return;

                if (isApiConcurrencyError(error))
                    setUserMessage(createMessageWithContent(translate(assignDict.concurrencyError)));
                else
                    setUserMessage(createMessageWithContent(translate(assignDict.assignInternalError)));
            })
            .finally(() => {
                if (shouldSetStatesBeCancelledFn()) return;

                setViewLoadingState(LoadingState.None);

                if (updatedReservation && updatedReservation.cleaningJob.status !== 'ToBeAssigned')
                    history.goBack();
            });
    };

    const unAssignEmployee = (employeeId: string) => {

        if (shouldSetStatesBeCancelledFn()) return;

        setViewLoadingState(LoadingState.ModalWithVisibleContent);

        reservationsAdapter.removeEmployeeFromCleaningJob(apiClient,
            propertyMgmtContext.activePropertyMgmtOrgId!,
            currentReservation!.id,
            currentReservation!.aggregateTimeStamp,
            { targetEmployeeId: employeeId },
            true)
            .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
                setCurrentReservation(value);
                setCardEmployeeListContent(updateCardEmployeeAssignations(cardEmployeeListContent!, value));
            }))
            .catch((error) => {
                if (shouldSetStatesBeCancelledFn()) return;

                if (isApiConcurrencyError(error))
                    setUserMessage(createMessageWithContent(translate(assignDict.concurrencyError)));
                else
                    setUserMessage(createMessageWithContent(translate(assignDict.removeInternalError)));

            })
            .finally(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.None));
    };

    return (
        <ViewLayout viewTitle={translate(assignDict.title)}
            isChildView={true}
            contentBoxPadding={1}
            contentBoxPaddingBottom={10}
            loadingState={viewLoadingState}
            unexpectedLoadingErrorReload={unexpectedLoadingErrorReload}
            userMessageInfo={{ userMessage: userMessage, setUserMessage: setUserMessage }}>
            {cardEmployeeListContent && cardEmployeeListContent.sort((a, b) => a.otherJobCount - b.otherJobCount).map((cardEmployee, cardIndex) =>
                <Card key={cardEmployee.id} sx={{ mb: 1, position: 'relative' }} elevation={currentReservation?.cleaningJob.status !== 'ToBeAssigned' ? 0 : undefined} data-cy={'AssignEmployeeCard_' + cardIndex}>
                    <CardActionArea onClick={cardEmployee.isAssignedToReservation ? () => unAssignEmployee(cardEmployee.id) : () => assignEmployee(cardEmployee.id)}
                        sx={{
                            '& .MuiCardActionArea-focusHighlight': { backgroundColor: 'primary.main' },
                            '& .MuiTouchRipple-root .MuiTouchRipple-child': { backgroundColor: 'primary.main' }
                        }}
                        disabled={currentReservation?.cleaningJob.status !== 'ToBeAssigned' ? true : undefined}                        >
                        <CardContent>
                            <Box display='flex' flexDirection='row' alignItems='start'>
                                <Box flexGrow={1}>
                                    <Typography variant='h6' component="h6" mb={1} ml={cardEmployee.isAssignedToReservation ? (theme) => theme.typography.pxToRem(36) : undefined} data-cy='EmployeeName'>{cardEmployee.name}</Typography>
                                    <Typography variant="body2" component="p">{translate(assignDict.specialtiesLbl)}<Box component='span' data-cy='EmployeeSpecialities' >{cardEmployee.specialtiesNames.join(', ')}</Box></Typography>
                                    <Typography variant="body2" component="p" mt={(theme) => theme.typography.pxToRem(4)}>{translate(assignDict.telephoneLbl)}<Box component='span' data-cy='EmployeeTelephone' >{cardEmployee.phone}</Box></Typography>
                                </Box>
                                <Box flexGrow={0} color='primary.contrastText' flexShrink={0} borderRadius={(theme) => (Number(theme.shape.borderRadius) * 2).toString() + 'px'} pt={1} pb={1} pl={2} pr={2} sx={{ backgroundColor: cardEmployee.isAssignedToReservation ? 'primary.main' : 'primary.light' }}>
                                    <Typography variant="caption" component="p" lineHeight={1.2}>{translate(assignDict.numberOfJobsLbl)}</Typography>
                                    <Typography variant="caption" component="p" lineHeight={1.2}>{translate(assignDict.plannedLbl)}</Typography>
                                    <Typography variant="h4" component="p" textAlign={'center'} data-cy='EmployeeJobCount' >{cardEmployee.otherJobCount + (cardEmployee.isAssignedToReservation ? 1 : 0)}</Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                    {cardEmployee.isAssignedToReservation && <Box position='absolute' top={0} right={0} left={0} bottom={0} sx={{ backgroundColor: 'primary.main', opacity: 0.08, pointerEvents: 'none' }} data-cy='EmployeeCheckOverlay'>
                    </Box>}
                    {cardEmployee.isAssignedToReservation && <Box position='absolute' top={0} left={0} mt={1} ml={1} sx={{ lineHeight: 1, opacity: 1, pointerEvents: 'none' }}>
                        <Icon fontSize='large' sx={{ color: 'primary.main', mt: (theme) => theme.typography.pxToRem(4) }} data-cy='EmployeeCheckIndicator'>check_circle</Icon>
                    </Box>}
                </Card>
            )}
            {cardEmployeeListContent && cardEmployeeListContent.length === 0 && <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} ml={1} fontStyle={'italic'} data-cy='NoEmployeeMsg'>{translate(assignDict.noEmployeeAvailableMsg)}</Typography>}
            {/*marginLeft: -1 is used to compensate the container padding and keep the fixed element centered without using left, right and auto margins. This is useful because the fixed element doesn't move when the scrollbars are hidden by MUI.*/
                cardEmployeeListContent && currentReservation && <Paper square={true} elevation={2} sx={{ color: 'primary.contrastText', backgroundColor: 'primary.main', marginLeft: -1, width: '100%', position: 'fixed', bottom: '0', maxWidth: 'sm', borderRadius: '16px 16px 0 0', p: 2 }}>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Typography variant='h6' component="h6" flexGrow={1} data-cy='ReservationRentalUnitName'>{currentReservation.rentalUnit.name}</Typography>
                        <Typography variant='body2' component='p' flexGrow={0} flexShrink={0}><Icon fontSize='small' sx={{ color: 'primary.contrastText', mr: (theme) => theme.typography.pxToRem(4), verticalAlign: 'bottom' }}>group</Icon><Box component='span' data-cy='ReservationAssignedCount'>{cardEmployeeListContent.filter((value) => value.isAssignedToReservation).length}/{currentReservation.cleaningJob.numberOfRequiredCleaningEmployees}</Box></Typography>
                    </Box>
                    <Typography variant="body2" component="p" data-cy='ReservationAvailability'>{translate(isDateInThePast(currentReservation.endDate) ? assignDict.reservationAvailablePastLabel : assignDict.reservationAvailableLabel, buildDateDiffLabelParamArray(currentReservation.endDate, false))}</Typography>
                    <Typography variant="body2" component="p" data-cy='ReservationNextArrival'>{currentReservation.nextReservation ? translate(isDateInThePast(currentReservation.nextReservation.startDate) ? assignDict.reservationNextArrivalPastLabel : assignDict.reservationNextArrivalLabel, buildDateDiffLabelParamArray(currentReservation.nextReservation.startDate, true)) : translate(assignDict.reservationNoNextArrivalLabel)}</Typography>
                </Paper>
            }
        </ViewLayout >
    )
}

export default Assign 