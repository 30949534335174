import IApiClient from '../../../utilities/IApiClient';

export interface IEmployee {
    id: string,
    aggregateTimeStamp: string,
    firstName: string,
    lastName: string,
    uniqueUserId: string
}

const employeesAdapter = {
    getAllEmployees: (apiClient: IApiClient, propertyMgmtId: string) =>
        apiClient.get<IEmployee[]>('/Employees/' + propertyMgmtId)
};

export default employeesAdapter;