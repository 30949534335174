import { createContext } from 'react';
import IAppContext from './components/IAppContext';

export type IUserPropertyMgmtOrgRole = { propertyMgmtId: string, role: string };

export interface IPropertyMgmtMobileAppContext extends IAppContext {
    uniqueUserId?: string,
    activePropertyMgmtOrgId?: string,
    activePropertyMgmtOrgRole?: string,
    userPropertyMgmtOrgRoles?: IUserPropertyMgmtOrgRole[],
    changeActivePropertyMgmtId: (id: string) => void;
};

const PropertyMgmtMobileAppContext = createContext<IPropertyMgmtMobileAppContext>({ isValid: false, changeActivePropertyMgmtId: () => { } });

export default PropertyMgmtMobileAppContext;