import React from 'react';

import TextField from '@mui/material/TextField';
import Icon from '@mui/material/Icon';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import useTranslator from '../../../utilities/hooks/useTranslator';
import { IFeature } from '../RentalUnitCreate';

export interface FeatureInputFieldProps {
  features: string[];
  featureList: IFeature[];
  setFeatureList: (featuresList: IFeature[]) => void;
}

const FeatureInputFieldDict = {
  feature: { en: 'Feature', fr: 'Caractéristique' },
  required: { en: '*Required', fr: '*Requis' },
  invalidFormat: { en: 'Format not valid', fr: `Format non valide` },
  value: { en: 'Value', fr: 'Valeur' },
};
const FeatureInputField = (props: FeatureInputFieldProps) => {

  const translate = useTranslator();

  const handleFeatureChange = (event: any, index: number) => {

    const list = [...props.featureList];
    list[index][event.target.name as keyof IFeature] = event.target.value;

    if (list[index].name.length > 0 && index + 1 === props.featureList.length) {
      list.push({ name: '', value: '' })
    }

    props.setFeatureList(list);

  }
  const handleFeatureRemove = (index: number) => {

    const features = [...props.featureList];

    features.splice(index, 1);
    props.setFeatureList(features);
  };

  return !props.features ? null : (
    <React.Fragment>
      {props.featureList.map((feature, index) => (
        <Box mt={index === 0 ? 1 : 0} mb={1} key={index} display="flex">
          <FormControl fullWidth>
            <InputLabel id={'select-feature-label-' + index} >{translate(FeatureInputFieldDict.feature)}</InputLabel>
            <Select
              name='name'
              label={translate(FeatureInputFieldDict.feature)}
              labelId={'select-feature-label-' + index.toString}
              id={'select-feature' + index.toString}
              defaultValue='feature'
              value={feature.name as string}
              onChange={(event) => handleFeatureChange(event, index)}
              data-cy={"FeatureInput_" + index.toString}
            >
              {props.features?.map((featureName, i) => (<MenuItem value={featureName} disabled={props.featureList.some(feature => feature.name === featureName)} key={i + 1}>{featureName}</MenuItem>))}
            </Select>
            <FormHelperText>{!props.featureList[0].name && index === 0 ? translate(FeatureInputFieldDict.required) : ' '}</FormHelperText>
          </FormControl>
          <TextField value={feature.value as string} name='value' sx={{ ml: 1 }} onChange={(event) => handleFeatureChange(event, index)} helperText={feature.name && feature.value.length === 0 ? translate(FeatureInputFieldDict.required) : ' '} label={translate(FeatureInputFieldDict.value)} data-cy={"ValueInput_" + index.toString} />
          <Icon onClick={() => handleFeatureRemove(index)} sx={{ mt: 2, ml: 1, visibility: props.featureList[index].name ? 'visible' : 'hidden' }}>
            clear
        </Icon>
        </Box>
      ))}
    </React.Fragment>
  );
};

export default FeatureInputField;
