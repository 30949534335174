import { useContext, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import LoadingState from '../../components/LoadingState';
import ViewLayout from '../../components/ViewLayout/ViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import propertyMgmtOrgsAdapter, { IPropertyMgmtOrg } from './Adapters/propertyMgmtOrgsAdapter';
import ListContent from './Components/TaskList';

const Tasks = () => {
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);
  const [propertyMgmtOrg, setPropertyMgmtOrg] = useState<IPropertyMgmtOrg | undefined>(undefined);

  const apiClient = useApiClient();

  const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {
    setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

    propertyMgmtOrgsAdapter
      .getPropertyMgmtOrg(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
      .then((value) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {
        setPropertyMgmtOrg(value);
        setViewLoadingState(LoadingState.None);
      }))
      .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));
  };
  useSafeAsyncOperations(getAllViewData);
  return (
    <ViewLayout loadingState={viewLoadingState}>
      <ListContent allTasks={propertyMgmtOrg?.cleaningTasks!} />
    </ViewLayout>
  );
};

export default Tasks;
