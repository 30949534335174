import { createContext } from 'react';

export interface IAppLayoutContext {
    currentViewTitle?: string,
    currentViewIsChildView?: boolean,
    loading?: boolean,
    currentViewBackOverride?: () => void,
    tabs?: { scrollable: boolean, labels: string[], selectedTabIndex: number, changeSelectedTabHandler: (tabIndex: number) => void, hidden: boolean },
    invalidAppContext?: boolean,
    setCurrentViewInfo: (viewTitle?: string, currentViewIsChildView?: boolean, loading?: boolean, childViewBackOverride?: () => void, tabs?: { scrollable: boolean, labels: string[], selectedTabIndex: number, changeSelectedTabHandler: (tabIndex: number) => void, hidden: boolean }) => void
};

const AppLayoutContext = createContext<IAppLayoutContext>({ currentViewTitle: '', currentViewIsChildView: false, invalidAppContext: true, setCurrentViewInfo: () => { } });

export default AppLayoutContext;