import IApiClient from '../../../utilities/IApiClient';

export interface ICreateEmployeeDTO {
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  notes: string;
  phones: string[];
  specialtyIds: string[];
  isManager: boolean;
}

const employeeAdapter = {

  createEmployee: (apiClient: IApiClient, propertyMgmtId: string, createEmployee: ICreateEmployeeDTO, expectConcurrencyError: boolean) => apiClient.post('/Employees/' + propertyMgmtId, createEmployee, expectConcurrencyError),

};

export default employeeAdapter;
