import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import useTranslator from '../../../utilities/hooks/useTranslator';
import useSafeHistory from '../../../utilities/hooks/useSafeHistory';

export interface ConfirmCancellationDialogProps {
  openInfo: {open: boolean} | undefined;
  setOpenInfo: (openInfo: {open: boolean} | undefined) => void;
}

const ConfirmCancellationDialogDict = {
  confirmCancellationDlgTitle: { en: 'Cancel Employee Creation?', fr: `Annuler la création de l'employé ?` },
  confirmationCancellationDlgText: { en: 'You will loose the data you entered about the new employee. Do you agree?', fr: `Vous perdrez les données saisies à propos du nouvel employé. Êtes-vous d'accord?` },
  confirmationRefusalDlgYesActionLbl: { en: 'YES', fr: 'OUI' },
  confirmationRefusalDlgNoActionLbl: { en: 'NO', fr: 'NON' },
  
};

const ConfirmCancellationDialog = (props: ConfirmCancellationDialogProps) => {
  const translate = useTranslator();
  const history = useSafeHistory();

  const cancelConfirmCancellationDialog = () => {
    props.setOpenInfo({ open: false });
  };
  const confirmCancellationDialog = () => {
      history.goBack()
  }
  return (
    <Dialog fullWidth onClose={() => {}} open={!!props.openInfo && props.openInfo.open} keepMounted>
      <DialogTitle data-cy="CancelDialogTitle">{translate(ConfirmCancellationDialogDict.confirmCancellationDlgTitle)}</DialogTitle>
      <DialogContent >
        <DialogContentText data-cy="CancelDialogMessage">
          {translate(ConfirmCancellationDialogDict.confirmationCancellationDlgText)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancelConfirmCancellationDialog} data-cy="CancelDialogNoAction">
          {translate(ConfirmCancellationDialogDict.confirmationRefusalDlgNoActionLbl)}
        </Button>
        <Button onClick={confirmCancellationDialog} data-cy="CancelDialogYesAction">
          {translate(ConfirmCancellationDialogDict.confirmationRefusalDlgYesActionLbl)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancellationDialog;
