import { createContext } from 'react';
import UserLanguage from './UserLanguage';

export interface ILocalUserProfileContext {
    language: UserLanguage,
    changeUserProfile: (language: UserLanguage) => void
};

const LocalUserProfileContext = createContext<ILocalUserProfileContext>({ language: 'en', changeUserProfile: () => { } });

export default LocalUserProfileContext;