import { ReactNode, useState, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { IDToken } from '@okta/okta-auth-js';

import logManager from './utilities/logManager';

import PropertyMgmtMobileAppContext, { IPropertyMgmtMobileAppContext, IUserPropertyMgmtOrgRole } from './PropertyMgmtMobileAppContext';

const StorageActivePropertyMgmtOrgKey = 'ActivePropertyMgmtOrg';

const GetUniqueUserId: (isAuthenticated: boolean | undefined, idToken: IDToken | undefined) => (string | undefined) = (isAuthenticated, idToken) => {

  const uniqueUserId = idToken?.claims['uniqueUserId'] as (string | undefined);

  if (isAuthenticated && !uniqueUserId)
    logManager.logError('PropertyMgmtContext Error. The user token does not have the uniqueUserId attribute.');

  return uniqueUserId;
}

const GetAllUserRoles: (isAuthenticated: boolean | undefined, idToken: IDToken | undefined) => IUserPropertyMgmtOrgRole[] = (isAuthenticated, idToken) => {

  let userRoles: IUserPropertyMgmtOrgRole[] = [];

  const groupClaims: string[] | undefined = idToken?.claims['PropertyMgmtRole'];

  if (isAuthenticated && groupClaims)
    groupClaims.forEach((value) => {
      let splittedFirstGroup = value.split('_');

      if (splittedFirstGroup.length > 2)
        userRoles.push({ propertyMgmtId: splittedFirstGroup[splittedFirstGroup.length - 1], role: splittedFirstGroup[1] });
    })

  if (isAuthenticated && userRoles.length === 0)
    logManager.logError('PropertyMgmtContext Error. The user does not have any roles. He should not be able to access this app. App cannot be started.');

  return userRoles;
}

const GetActivePropertyMgmtOrgIdFromStore = () => {

  const lastUsedId = localStorage.getItem(StorageActivePropertyMgmtOrgKey);

  return lastUsedId ? lastUsedId : undefined;
}

const SaveActivePropertyMgmtOrgIdInStore = (activePropertyMgmtOrgId: string) => {

  localStorage.setItem(StorageActivePropertyMgmtOrgKey, activePropertyMgmtOrgId);
}

const DetermineActivePropertyMgmtOrgAndRole: (roles: IUserPropertyMgmtOrgRole[], activePropertyMgmtId: string | undefined) => IUserPropertyMgmtOrgRole | undefined = (roles, activePropertyMgmtId) => {

  let activePropertyMgmtOrg: IUserPropertyMgmtOrgRole | undefined = undefined;

  if (roles.length > 0) {

    if (activePropertyMgmtId)
      activePropertyMgmtOrg = roles.find((value) => value.propertyMgmtId === activePropertyMgmtId);

    if (!activePropertyMgmtOrg)
      activePropertyMgmtOrg = roles[0];
  }

  return activePropertyMgmtOrg;
}

const PropertyMgmtMobileAppContextProvider = (props: { children?: ReactNode }) => {

  const [activeUserPropertyMgmtOrgId, setActiveUserPropertyMgmtOrgId] = useState<string | undefined>(() => GetActivePropertyMgmtOrgIdFromStore());

  const { authState } = useOktaAuth();

  const memoContextValue = useMemo<IPropertyMgmtMobileAppContext>(() => {

    const uniqueUserId = GetUniqueUserId(authState?.isAuthenticated, authState?.idToken);

    const userRoles = GetAllUserRoles(authState?.isAuthenticated, authState?.idToken);

    const activePropertyMgmtOrgRole = DetermineActivePropertyMgmtOrgAndRole(userRoles, activeUserPropertyMgmtOrgId);

    if (activePropertyMgmtOrgRole)
      SaveActivePropertyMgmtOrgIdInStore(activePropertyMgmtOrgRole.propertyMgmtId);

    return {
      isValid: !!activePropertyMgmtOrgRole && !!uniqueUserId,
      uniqueUserId: uniqueUserId,
      activePropertyMgmtOrgId: activePropertyMgmtOrgRole?.propertyMgmtId,
      activePropertyMgmtOrgRole: activePropertyMgmtOrgRole?.role,
      userPropertyMgmtOrgRoles: userRoles,
      changeActivePropertyMgmtId: setActiveUserPropertyMgmtOrgId
    };

  }, [authState?.isAuthenticated, authState?.idToken, activeUserPropertyMgmtOrgId]);

  return (
    <PropertyMgmtMobileAppContext.Provider value={memoContextValue}>
      {props.children}
    </PropertyMgmtMobileAppContext.Provider>
  )
}

export default PropertyMgmtMobileAppContextProvider;