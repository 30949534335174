import { useContext } from 'react';
import { SecureRoute } from '@okta/okta-react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import AdvancedLoginCallback from './components/AdvancedLoginCallback/AdvancedLoginCallback';
import UnresolvedRoute from './components/UnresolvedRoute/UnresolvedRoute';
import useTranslator from './utilities/hooks/useTranslator';
import CleaningJobsForManager from './views/CleaningJobsForManager/CleaningJobsForManager'
import CleaningJobsForEmployee from './views/CleaningJobsForEmployee/CleaningJobsForEmployee'
import Organisations from './views/Organisations/Organisations';
import AppLayout from './components/AppLayout/AppLayout';
import PropertyMgmtMobileAppContext from './PropertyMgmtMobileAppContext';
import Interventions from './views/Interventions/Interventions';
import Tasks from './views/Tasks/Tasks';
import Bookings from './views/Bookings/Bookings';
import BookingsChild from './views/BookingsChild/BookingsChild';
import ForbiddenAccessView from './components/ForbiddenAccessView/ForbiddenAccessView';
import Assign from './views/Assign/Assign';
import Employees from './views/Employees/Employees';
import Employee from './views/Employee/Employee';
import EmployeeCreate from './views/EmployeeCreate/EmployeeCreate';
import EmployeeUpdate from './views/EmployeeUpdate/EmployeeUpdate';
import RentalUnits from './views/RentalUnits/RentalUnits';
import RentalUnit from './views/RentalUnit/RentalUnit';
import RentalUnitCreate from './views/RentalUnitCreate/RentalUnitCreate';
import RentalUnitUpdate from './views/RentalUnitUpdate/RentalUnitUpdate';
import Specialties from './views/Specialties/Specialties';
import Specialty from './views/Specialty/Specialty';
import SpecialtyCreate from './views/SpecialtyCreate/SpecialtyCreate';
import SpecialtyUpdate from './views/SpecialtyUpdate/SpecialtyUpdate';
import CleaningWorkload from './views/CleaningWorkload/CleaningWorkload';

interface IPropertyMgmtMobileAppProps {
  loginCallbackRoutePath: string
}

const PropertyMgmtMobileApp = (props: IPropertyMgmtMobileAppProps) => {

  const propertyMgmtAppCtx = useContext(PropertyMgmtMobileAppContext);

  const history = useHistory()

  const redirectToOrganisationsView = () => { history.push('/organisations') };

  const translate = useTranslator();

  const appLayoutLabelsDict = {
    appTitle: { en: 'SpringBuck', fr: 'SpringBuck' },
    organisationsUserAction: { en: 'Organisations', fr: 'Organisations' },
    cleaningJobsBottomNavLink: { en: 'Cleanings', fr: 'Entretiens' },
    tasksBottomNavLink: { en: 'Tasks', fr: 'Tâches' },
    bookBottomNavLink: { en: 'Bookings', fr: 'Réserv.' },
    interventionsBottomNavLink: { en: 'Interventions', fr: 'Interventions' },
    specialtiesDrawerNavLink: { en: 'Specialties', fr: 'Spécialités' },
    rentalUnitsDrawerNavLink: { en: 'Rental Units', fr: 'Unités de location' },
    employeesDrawerNavLink: { en: 'Employees', fr: 'Employés' },
    cleaningsWorkloadDrawerNavLink: { en: 'Cleanings Workload', fr: 'Charge de travail (ménages)' }
  };

  return (
    <AppLayout
      appContext={propertyMgmtAppCtx}
      appTitle={translate(appLayoutLabelsDict.appTitle)}
      additionalUserActions={[{ iconName: 'work', label: translate(appLayoutLabelsDict.organisationsUserAction), action: redirectToOrganisationsView }]}
      bottomNavLinks={[
        { label: translate(appLayoutLabelsDict.cleaningJobsBottomNavLink), iconName: 'cleaning_services', url: '/cleaningjobs' },
        { label: translate(appLayoutLabelsDict.tasksBottomNavLink), iconName: 'task', url: '/tasks' },
        { label: translate(appLayoutLabelsDict.bookBottomNavLink), iconName: 'book_online', url: '/bookings' },
        { label: translate(appLayoutLabelsDict.interventionsBottomNavLink), iconName: 'home_repair_service', url: '/interventions' }
      ]}
      drawerNavLinks={[
        { label: translate(appLayoutLabelsDict.specialtiesDrawerNavLink), iconName: 'construction', url: '/specialties' },
        { label: translate(appLayoutLabelsDict.rentalUnitsDrawerNavLink), iconName: 'apartment', url: '/rentalunits' },
        { label: translate(appLayoutLabelsDict.employeesDrawerNavLink), iconName: 'people', url: '/employees' },
        { label: translate(appLayoutLabelsDict.cleaningsWorkloadDrawerNavLink), iconName: 'bar_chart', url: '/cleaningWorkload' }
      ]}>
      <Switch>
        <SecureRoute exact path='/'><Redirect to='/cleaningjobs' /></SecureRoute>
        <SecureRoute exact path='/cleaningjobs' component={
          propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? CleaningJobsForManager :
            propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningEmployee' ? CleaningJobsForEmployee :
              ForbiddenAccessView
        } />
        <SecureRoute exact path='/tasks' component={Tasks} />
        <SecureRoute exact path='/bookings' component={Bookings} />
        <SecureRoute exact path='/bookingschild/:bookingid' component={BookingsChild} />
        <SecureRoute exact path='/interventions' component={Interventions} />
        <SecureRoute exact path='/organisations' component={Organisations} />
        <SecureRoute exact path='/assign' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? Assign : ForbiddenAccessView} />
        <SecureRoute exact path='/employees' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? Employees : ForbiddenAccessView} />
        <SecureRoute exact path='/employee/:employeeid' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? Employee : ForbiddenAccessView} />
        <SecureRoute exact path='/employeecreate' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? EmployeeCreate : ForbiddenAccessView} />
        <SecureRoute exact path='/employeeupdate/:employeeid' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? EmployeeUpdate : ForbiddenAccessView} />
        <SecureRoute exact path='/rentalunits' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? RentalUnits : ForbiddenAccessView} />
        <SecureRoute exact path='/rentalunit/:rentalunitid' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? RentalUnit : ForbiddenAccessView} />
        <SecureRoute exact path='/rentalunitcreate' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? RentalUnitCreate : ForbiddenAccessView} />
        <SecureRoute exact path='/rentalunitupdate/:rentalunitid' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? RentalUnitUpdate : ForbiddenAccessView} />
        <SecureRoute exact path='/rentalunitupdate' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? RentalUnitUpdate : ForbiddenAccessView} />
        <SecureRoute exact path='/specialties' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? Specialties : ForbiddenAccessView} />
        <SecureRoute exact path='/specialty/:specialtyid' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? Specialty : ForbiddenAccessView} />
        <SecureRoute exact path='/specialtycreate' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? SpecialtyCreate : ForbiddenAccessView} />
        <SecureRoute exact path='/specialtyupdate/:specialtyid' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? SpecialtyUpdate : ForbiddenAccessView} />
        <SecureRoute exact path='/cleaningWorkload' component={propertyMgmtAppCtx.activePropertyMgmtOrgRole === 'CleaningManager' ? CleaningWorkload : ForbiddenAccessView} />
        {/* Unsecured routes - Only these 2 routes should be unsecured */}
        <Route path={props.loginCallbackRoutePath} component={AdvancedLoginCallback} />
        <Route component={UnresolvedRoute} />
      </Switch>
    </AppLayout>);
}

export default PropertyMgmtMobileApp