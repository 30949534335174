import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { IEmployee } from '../adapters/employeesAdapter';
import { IPropertyMgmtOrg } from '../adapters/propertyMgmtOrgsAdapter';
import useTranslator from '../../../utilities/hooks/useTranslator';
import { parseApiDate } from '../../../utilities/apiDates';
import CommonCardContent from './CommonCardContent';
import LoadingState from '../../../components/LoadingState';
import useApiClient from '../../../utilities/hooks/useApiClient';
import PropertyMgmtMobileAppContext from '../../../PropertyMgmtMobileAppContext';
import reservationsAdapter, { IReservation } from '../adapters/reservationsAdapter';
import { isApiConcurrencyError } from '../../../utilities/IApiConcurrencyError';
import ConfirmRefusalDialog from './ConfirmRefusalDialog';

export interface IToDoTabContentProps {
  allReservations: IReservation[] | undefined;
  allEmployees: IEmployee[] | undefined;
  propertyMgmtOrg: IPropertyMgmtOrg | undefined;
  safeSetViewLoadingState: (loadingState: LoadingState) => void;
  refreshSingleReservationInList: (reservation: IReservation) => void;
  safeSetUserMessageContent: (messageContent: string) => void;
}

const ToDoTabContentDict = {
  waitingToBeStarted: {
    en: 'Waiting to be started',
    fr: 'En attente de démarrage',
  },
  startButton: { en: 'Start', fr: 'Démarrer' },
  removeButton: { en: 'Remove', fr: 'Retirer' },
  confirmationConcurrencyError: {
    en: 'Reservation for {0} has been modified by another user. Employee cannot be confirmed. Please refresh view and retry if needed.',
    fr: "La réservation pour {0} a été modifiée par un autre utilisateurs. L'employé ne peut être confirmer. SVP rafraîchir et réessayer si nécessaire.",
  },
  confirmationInternalError: {
    en: 'Internal error while trying to confirm for {0}. Please refresh and retry if needed.',
    fr: "Erreur interne lors de la confirmation de l'pour {0}. SVP rafraîchir et réessayer si nécessaire.",
  },
  ToDoNoReservationMsg: {
    en: 'There is no cleaning job to do.',
    fr: "Aucune job d'entretien à faire.",
  },
};

const ToDoTabContent = (props: IToDoTabContentProps) => {
  const translate = useTranslator();
  const apiClient = useApiClient();
  const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);
  const propertyMgmtOrgId = props.propertyMgmtOrg?.id!;

  const [confirmRefusalDlgOpenInfo, setConfirmRefusalDlgOpenInfo] = useState<{ open: boolean; reservation: IReservation } | undefined>(undefined);

  const renderNow = new Date();

  const toDoReservation = props.allReservations?.filter((value) => value.cleaningJob.status === 'ToDo');

  toDoReservation?.sort((a, b) => parseApiDate(a.startDate).getTime() - parseApiDate(b.startDate).getTime());

  const isInWarningState = (reservation: IReservation) =>
    !!reservation.nextReservation?.startDate && (parseApiDate(reservation.nextReservation.startDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.shouldHaveStartedTimeOut ?? 0);

  const isInUrgentState = (reservation: IReservation) =>
    !!reservation.nextReservation?.startDate && (parseApiDate(reservation.nextReservation.startDate).getTime() - renderNow.getTime()) / 1000 / 60 / 60 < (props.propertyMgmtOrg?.cleaningJobSettings.mustHaveStartedTimeOut ?? 0);

  const startEmployeeOnCleaningJob = (reservation: IReservation) => {
    reservationsAdapter
      .employeeStartsCleaningJob(apiClient, propertyMgmtOrgId, reservation.id, reservation.aggregateTimeStamp, { targetEmployeeUniqueUserId: propertyMgmtContext.uniqueUserId! }, true)
      .then((value) => {
        props.refreshSingleReservationInList(value);
      })
      .catch((error: any) => {
        if (isApiConcurrencyError(error)) props.safeSetUserMessageContent(translate(ToDoTabContentDict.confirmationConcurrencyError, [reservation.rentalUnit.name]));
        else props.safeSetUserMessageContent(translate(ToDoTabContentDict.confirmationInternalError, [reservation.rentalUnit.name]));
      })
      .finally(() => props.safeSetViewLoadingState(LoadingState.None));
  };

  return !(props.allReservations && props.allEmployees && props.propertyMgmtOrg) ? null : (
    <React.Fragment>
      {toDoReservation?.map((reservation, index) => (
        <Card key={reservation.id} sx={{ mb: 1 }} data-cy={'ToDoCard_' + index.toString()}>
          <CommonCardContent
            reservation={reservation}
            isInUrgentState={isInUrgentState(reservation)}
            isInWarningState={isInWarningState(reservation)}
            cardStateDescription={translate(ToDoTabContentDict.waitingToBeStarted)}
            allEmployees={props.allEmployees as IEmployee[]}
          />
          <CardActions disableSpacing={true}>
            <Button color="secondary" onClick={() => {startEmployeeOnCleaningJob(reservation);}} data-cy="CardStartAction">
              {translate(ToDoTabContentDict.startButton)}
            </Button>
            <Button color="secondary" onClick={() => setConfirmRefusalDlgOpenInfo({ open: true, reservation: reservation })} sx={{ ml: 1 }} data-cy="CardRefuseAction">
              {translate(ToDoTabContentDict.removeButton)}
            </Button>
          </CardActions>
        </Card>
      ))}
      {toDoReservation?.length === 0 && (
        <Typography variant="body1" paragraph={true} color={(theme) => theme.palette.text.disabled} m={1} fontStyle={'italic'} data-cy="ToDo_NoReservationMsg">
          {translate(ToDoTabContentDict.ToDoNoReservationMsg)}
        </Typography>
      )}
      <ConfirmRefusalDialog
        openInfo={confirmRefusalDlgOpenInfo}
        setOpenInfo={setConfirmRefusalDlgOpenInfo}
        propertyMgmtOrg={props.propertyMgmtOrg}
        safeSetViewLoadingState={props.safeSetViewLoadingState}
        refreshSingleReservationInList={props.refreshSingleReservationInList}
        safeSetUserMessageContent={props.safeSetUserMessageContent}
      />
    </React.Fragment>
  );
};

export default ToDoTabContent;
