import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import logManager from '../../utilities/logManager';
import useTranslator from '../../utilities/hooks/useTranslator';
import ViewLayout from '../ViewLayout/ViewLayout';

const forbiddenAccessViewDict = {
    forbiddenTitle: { fr: 'Accès interdit', en: 'Forbidden' },
    forbiddenMsg: { fr: 'Vous n\'avez pas accès à cette ressource.', en: 'You do not have access to this ressource.' }
};

const ForbiddenAccessView = () => {

    const oktaContext = useOktaAuth();

    const location = useLocation();

    const translate = useTranslator();

    if (!oktaContext.authState?.isAuthenticated)
        logManager.logError(`ForbiddenRoute shown to unauthenticated user. ForbiddenRoute should only be used with SecureRoute. Route ${location.pathname} might not be a SecureRoute.`);
    else
        logManager.logInfo(`Forbidden access to route ${location.pathname}.`);

    return <ViewLayout>
        <Box sx={{ marginTop: 2, marginLeft: 2, marginBottom: 1 }}>
            <Typography variant='h5' paragraph={true}><Icon fontSize='large' sx={{ marginRight: 1, verticalAlign: (theme) => '-' + theme.spacing(1) }}>do_not_disturb_on</Icon>{translate(forbiddenAccessViewDict.forbiddenTitle)}</Typography>
            <Typography variant='body1' paragraph={true}>{translate(forbiddenAccessViewDict.forbiddenMsg)}</Typography>
        </Box>
    </ViewLayout>;
}

export default ForbiddenAccessView