import React, { useState, useContext, useRef, useCallback, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import TabbedViewLayout from '../../components/TabbedViewLayout/TabbedViewLayout';
import PropertyMgmtMobileAppContext from '../../PropertyMgmtMobileAppContext';
import LoadingState from '../../components/LoadingState';
import IUserMessage, { createMessageWithContent } from '../../components/IUserMessage';
import useTranslator from '../../utilities/hooks/useTranslator';
import useApiClient from '../../utilities/hooks/useApiClient';
import useSafeAsyncOperations from '../../utilities/hooks/useSafeAsyncOperations';
import useViewNavUXStateStack from '../../utilities/hooks/useViewNavUXStateStack';
import useSafeHistory from '../../utilities/hooks/useSafeHistory';

import cleaningJobsForManagerDict from './cleaningJobsForManagerDict';
import reservationsAdapter, { IReservation } from './adapters/reservationsAdapter';
import employeesAdapter, { IEmployee } from './adapters/employeesAdapter';
import propertyMgmtOrgsAdapter, { IPropertyMgmtOrg } from './adapters/propertyMgmtOrgsAdapter';
import ToAssignTabContent from './components/ToAssignTabContent';
import ToConfirmTabContent from './components/ToConfirmTabContent';
import ToDoTabContent from './components/ToDoTabContent';
import InProgressTabContent from './components/InProgressTabContent';
import DoneTabContent from './components/DoneTabContent';

interface ICleaningJobsForManagerNavUXState {
    selectedTab: number,
    tabsScrollInfo: number[]
}

const CleaningJobsForManager = () => {

    const propertyMgmtContext = useContext(PropertyMgmtMobileAppContext);

    const history = useSafeHistory()

    const translate = useTranslator();

    const apiClient = useApiClient();

    const [cleaningJobsForManagerNavUXState, pushCleaningJobsForManagerNavUXState] = useViewNavUXStateStack<ICleaningJobsForManagerNavUXState>();

    const [viewLoadingState, setViewLoadingState] = useState(LoadingState.NotModalWithInvisibleContent);

    const [selectedTab, setSelectedTab] = useState(cleaningJobsForManagerNavUXState ? cleaningJobsForManagerNavUXState.selectedTab : 0);
    const [viewFullDataLoadTimeStamp, setViewFullDataLoadTimeStamp] = useState<number | undefined>(undefined);

    const tabsScrollInfo = useRef(cleaningJobsForManagerNavUXState ? cleaningJobsForManagerNavUXState.tabsScrollInfo : [0, 0, 0, 0]);

    const changeTab = useCallback((tabIndex) => {
        tabsScrollInfo.current[selectedTab] = window.scrollY;
        setSelectedTab(tabIndex);
    }, [selectedTab, setSelectedTab])

    useEffect(() => {
        window.scrollTo(0, tabsScrollInfo.current[selectedTab]);
    }, [selectedTab, viewFullDataLoadTimeStamp]);

    const [reservationsListContent, setReservationsListContent] = useState<IReservation[] | undefined>(undefined);
    const [employeesListContent, setEmployeesListContent] = useState<IEmployee[] | undefined>(undefined);
    const [propertyMgmtOrg, setPropertyMgmtOrg] = useState<IPropertyMgmtOrg | undefined>(undefined);

    const [userMessage, setUserMessage] = useState<IUserMessage | undefined>(undefined);

    const getAllViewData = (shouldSetStatesBeCancelledFn: () => boolean) => {

        setViewLoadingState(LoadingState.NotModalWithInvisibleContent);

        //Concurrent get calls
        Promise.all([
            reservationsAdapter.getReservationsThatNeedAttentionFromCleaningManager(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string),
            employeesAdapter.getAllEmployees(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string),
            propertyMgmtOrgsAdapter.getPropertyMgmtOrg(apiClient, propertyMgmtContext.activePropertyMgmtOrgId as string)
        ])
            .then((values) => !shouldSetStatesBeCancelledFn() && unstable_batchedUpdates(() => {

                setReservationsListContent(values[0]);
                setEmployeesListContent(values[1]);
                setPropertyMgmtOrg(values[2])

                //State updates are batched to minimize flickering(multiple rendering). In next version of React, those state updates will be batched automatically in promises events.
                setViewLoadingState(LoadingState.None);
                setViewFullDataLoadTimeStamp(Date.now);
            }))
            .catch(() => !shouldSetStatesBeCancelledFn() && setViewLoadingState(LoadingState.UnexpectedLoadingError));;
    }

    const shouldSetStatesBeCancelledFn = useSafeAsyncOperations(getAllViewData);

    const cleaningJobRefreshAction = () => {
        tabsScrollInfo.current[selectedTab] = window.scrollY;
        getAllViewData(shouldSetStatesBeCancelledFn)
    };

    const unexpectedLoadingErrorReload = () => {
        tabsScrollInfo.current = [0, 0, 0, 0];
        getAllViewData(shouldSetStatesBeCancelledFn)
    };

    const safeSetViewLoadingState = (loadingState: LoadingState) => !shouldSetStatesBeCancelledFn() && setViewLoadingState(loadingState);

    const safeSetUserMessageContent = (userMessageContent: string) => !shouldSetStatesBeCancelledFn() && setUserMessage(createMessageWithContent(userMessageContent));

    const refreshSingleReservationInList = (reservation: IReservation) => {

        if (!reservationsListContent)
            throw new Error('Function refreshReservation called in invalid state.');

        if (shouldSetStatesBeCancelledFn())
            return;

        for (let i = 0; i < reservationsListContent.length; i++)
            if (reservationsListContent[i].id === reservation.id)
                reservationsListContent[i] = reservation;

        setReservationsListContent([...reservationsListContent]); //Replacing state object
    };

    const gotoAssign = (reservationId: string) => {

        tabsScrollInfo.current[selectedTab] = window.scrollY;
        pushCleaningJobsForManagerNavUXState({ selectedTab: selectedTab, tabsScrollInfo: tabsScrollInfo.current });

        history.push(`/assign?reservationId=${reservationId}`);
    };

    return (
        <TabbedViewLayout
            loadingState={viewLoadingState}
            fab={{ iconName: 'refresh', fabAction: cleaningJobRefreshAction }}
            tabs={{
                scrollable: true, tabsDetails: [{
                    label: translate(cleaningJobsForManagerDict.toAssignTabLabel),
                    pane: <ToAssignTabContent allReservations={reservationsListContent}
                        allEmployees={employeesListContent}
                        propertyMgmtOrg={propertyMgmtOrg}
                        gotoAssign={gotoAssign}
                        safeSetViewLoadingState={safeSetViewLoadingState}
                        refreshSingleReservationInList={refreshSingleReservationInList}
                        safeSetUserMessageContent={safeSetUserMessageContent} />
                },
                {
                    label: translate(cleaningJobsForManagerDict.toConfirmTabLabel),
                    pane:
                        <ToConfirmTabContent
                            allReservations={reservationsListContent}
                            allEmployees={employeesListContent}
                            propertyMgmtOrg={propertyMgmtOrg}
                            safeSetViewLoadingState={safeSetViewLoadingState}
                            refreshSingleReservationInList={refreshSingleReservationInList}
                            safeSetUserMessageContent={safeSetUserMessageContent} />
                },
                {
                    label: translate(cleaningJobsForManagerDict.toDoTabLabel),
                    pane: <ToDoTabContent allReservations={reservationsListContent}
                        allEmployees={employeesListContent}
                        propertyMgmtOrg={propertyMgmtOrg}
                        safeSetViewLoadingState={safeSetViewLoadingState}
                        refreshSingleReservationInList={refreshSingleReservationInList}
                        safeSetUserMessageContent={safeSetUserMessageContent} />
                },
                {
                    label: translate(cleaningJobsForManagerDict.inProgressTabLabel),
                    pane: <InProgressTabContent allReservations={reservationsListContent}
                        allEmployees={employeesListContent}
                        propertyMgmtOrg={propertyMgmtOrg}
                        safeSetViewLoadingState={safeSetViewLoadingState}
                        refreshSingleReservationInList={refreshSingleReservationInList}
                        safeSetUserMessageContent={safeSetUserMessageContent} />
                },
                {
                    label: translate(cleaningJobsForManagerDict.doneTabLabel),
                    pane: <DoneTabContent allReservations={reservationsListContent}
                        allEmployees={employeesListContent}
                        propertyMgmtOrg={propertyMgmtOrg}
                        safeSetViewLoadingState={safeSetViewLoadingState}
                        refreshSingleReservationInList={refreshSingleReservationInList}
                        safeSetUserMessageContent={safeSetUserMessageContent} />
                }
                ]
            }}
            liftedSelectStateMode={{
                selectedTabIndex: selectedTab,
                onSelectedTabIndexChange: changeTab
            }}
            contentBoxPaddingTop={1}
            contentBoxPaddingRight={1}
            contentBoxPaddingLeft={1}
            contentBoxPaddingBottom={7}
            unexpectedLoadingErrorReload={unexpectedLoadingErrorReload}
            userMessageInfo={{ userMessage: userMessage, setUserMessage: setUserMessage }} />
    )
}

export default CleaningJobsForManager