import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useOktaAuth } from '@okta/okta-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';

import LocalUserProfileContext from '../../utilities/LocalUserProfileContext';
import { createTranslatorWithContext } from '../../utilities/hooks/useTranslator';

interface ICustomAppBarProps {
  title: string,
  backButton?: {
    backButtonAction: () => void,
    backButtonIconNameOverride?: string
  },
  additionalUserActions: { iconName: string, label: string, action: () => void }[],
  tabs?: { scrollable: boolean, labels: string[] },
  selectedTab?: number,
  setSelectedTabCbk?: (index: number) => void,
  tabsAreHidden?: boolean,
  loading?: boolean,
  tooBigViewport: boolean,
  menuButtonCbk?: () => void
}

const GetInitials = (name?: string, userName?: string) => {

  if (!name || name.trim().length === 0)
    return userName && userName.length > 0 ? userName.substring(0, 1).toUpperCase() : '';

  let names = name.trim().split(' ');

  return names[0].substring(0, 1).toUpperCase()
    + (names.length > 1 ? names[names.length - 1].substring(0, 1).toUpperCase() : '');
}

const CustomAppBar = (props: ICustomAppBarProps) => {

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAdditionalUserAction = (action: () => void) => {
    handleUserMenuClose();
    action();
  };

  const { authState, oktaAuth } = useOktaAuth();

  const handleUserSignOut = () => {
    handleUserMenuClose();
    oktaAuth.signOut();
  };

  const userInitials = GetInitials(authState?.idToken?.claims.name, authState?.idToken?.claims.preferred_username);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => props.setSelectedTabCbk && props.setSelectedTabCbk(newValue);

  const customAppBarDict = {
    logoutLabel: { fr: 'Déconnexion', en: 'Log out' },
    switchLanguageLabel: { fr: 'English', en: 'Français' }
  };

  const localUserProfileContext = useContext(LocalUserProfileContext);

  const translate = createTranslatorWithContext(localUserProfileContext);

  const handleUserLanguageSwitch = () => {
    handleUserMenuClose();
    localUserProfileContext.changeUserProfile(localUserProfileContext.language === 'en' ? 'fr' : 'en');
  };

  return (
    <React.Fragment>
      <AppBar position={props.tooBigViewport ? 'absolute' : 'fixed'} sx={{ width: '100%', maxWidth: 'sm', right: 'auto', paddingRight: 0, ...(props.tooBigViewport && { position: 'fixed' }) }}>
        <Toolbar sx={{ [theme.breakpoints.up('sm')]: { paddingLeft: (theme) => theme.spacing(2), paddingRight: (theme) => theme.spacing(2) } }}>
          {!props.backButton && props.menuButtonCbk && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props.menuButtonCbk}
          >
            <Icon>menu</Icon>
          </IconButton>}
          {props.backButton && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props.backButton.backButtonAction}
          >
            <Icon>{props.backButton.backButtonIconNameOverride ?? 'arrow_back'}</Icon>
          </IconButton>}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} data-cy='AppBarTitle'>
            {props.title}
          </Typography>
          {!props.backButton && <Avatar sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText', width: 32, height: 32, fontSize: '1rem' }} component={ButtonBase} onClick={handleAvatarClick}>{userInitials}</Avatar>}
          {!props.backButton && <Menu
            id="user-menu"
            aria-labelledby="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleUserMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText' }}>{userInitials}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={authState?.idToken?.claims.name} secondary={authState?.idToken?.claims.preferred_username} />
            </ListItem>
            {props.additionalUserActions.map((value, index) => <MenuItem key={index} onClick={
              () => handleAdditionalUserAction(value.action)}>
              <ListItemIcon>
                <Icon fontSize='small'>{value.iconName}</Icon>
              </ListItemIcon>{value.label}</MenuItem>)
            }
            <MenuItem onClick={handleUserLanguageSwitch}>
              <ListItemIcon>
                <Icon fontSize='small'>language</Icon>
              </ListItemIcon>{translate(customAppBarDict.switchLanguageLabel)}
            </MenuItem>
            <MenuItem onClick={handleUserSignOut}>
              <ListItemIcon>
                <Icon fontSize='small'>logout</Icon>
              </ListItemIcon>{translate(customAppBarDict.logoutLabel)}
            </MenuItem>
          </Menu>}
        </Toolbar>
        {(props.tabs && !props.tabsAreHidden) && <Tabs
          value={props.selectedTab}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='inherit'
          variant={props.tabs.scrollable ? 'scrollable' : 'fullWidth'}
          aria-label="view tabs"
        >
          {props.tabs.labels.map((value, index) => <Tab label={value} key={value} data-cy={'TabButton_' + index.toString()} />)}
        </Tabs>}
      </AppBar>
      <Toolbar />
      {
        (props.loading) &&
        <Box top={0} position='fixed' width='100%' maxWidth='sm' zIndex={(theme) => theme.zIndex.appBar - 1}>
          <Toolbar sx={{ mb: props.tabs && !props.tabsAreHidden ? 6 : undefined }}></Toolbar>
          <LinearProgress color='secondary'></LinearProgress>
        </Box>
      }
    </React.Fragment >
  )
}

export default CustomAppBar;