import IApiClient from '../../../utilities/IApiClient';

export interface IPropertyMgmtOrg {
  id: string;
  cleaningJobSettings: {
    shouldHaveBeenAssignedTimeOut: number;
    mustHaveBeenAssignedTimeOut: number;
    shouldHaveBeenConfirmedTimeOut: number;
    mustHaveBeenConfirmedTimeOut: number;
    shouldHaveStartedTimeOut: number;
    mustHaveStartedTimeOut: number;
    shouldHaveBeenCompletedTimeOut: number;
    mustHaveBeenCompletedTimeOut: number;
    rearViewTimeSpan: number;
  };
}

const propertyMgmtOrgsAdapter = {
  getPropertyMgmtOrg: (apiClient: IApiClient, id: string) => apiClient.get<IPropertyMgmtOrg>('/PropertyMgmtOrgs/' + id)
};

export default propertyMgmtOrgsAdapter;
