import IApiClient from '../../../utilities/IApiClient';

export interface ICleaningManager {
    name: string;
    email: string;
    phones: string[];
}
export interface IFeature {
    name: string;
    value: string;
}

export interface IReservation {
    startDate: string;
    endDate: string
}

export interface IRentalUnit {
    aggregateTimeStamp: string,
    id: string;
    isActive: boolean;
    name: string;
    civicNumber?: string;
    street: string;
    city: string;
    province: string;
    isDirty: boolean;
    currentReservation?: IReservation;
    nextReservation?: IReservation;
    reactivationDate?: string;
    deactivationDate?: string;
    manager?: ICleaningManager;
    features: IFeature[];
    notes: string;
    sourceName: string;
    idInSource: string;


}

export interface IReactivateRentalUnitDTO {
    reactivationDate: string;

}

const rentalUnitAdapter = {
    getRentalUnit: (apiClient: IApiClient, propertyMgmtId: string, rentalUnitId: string) => apiClient.get<IRentalUnit>('/RentalUnits/' + propertyMgmtId + '/' + rentalUnitId),

    reactivateRentalUnit: (apiClient: IApiClient, propertyMgmtId: string, aggregateTimeStamp: string, rentalUnitId: string, updateRenatalUnit: IReactivateRentalUnitDTO, expectConcurrencyError: boolean) => apiClient.post('/RentalUnits/' + propertyMgmtId + '/' + rentalUnitId + '/reactivate?aggregateTimeStamp=' + aggregateTimeStamp, updateRenatalUnit, expectConcurrencyError)

};

export default rentalUnitAdapter;
